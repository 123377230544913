#APP5Challenge{
    height: auto;
    width: 70%;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media/Pattern.png");   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10vh auto;
    border-radius: 1vh;
    color: var(--txt-white-color);
    text-align: center;
    padding: 5vh 0vh;
}

#APP5Challenge>div:nth-child(1){
    height: auto;
    width: 90%;
    /* background-color: red; */

    h1{
        margin: 2vh auto 0vh auto;
        font-size: 4.5vh;
        font-weight: 800;
    }
    p{
        margin: 1vh auto 0vh auto;
        font-size: 2vh;
    }

    button{
        margin: 7vh auto 0vh auto;
        background-color: transparent;
        color: var(--txt-white-color);
        border-radius: 1vh;
        padding: 1vh 2vh;
        cursor: pointer;
        font-size: 1.8vh;
        letter-spacing: 0.1vh;
        transition: all 0.3s;
        border:1px solid;
    }
    button:hover{
        transform: scale(1.1);
    }
}


@media screen and (max-width:1441px) {
    #APP5Challenge{
        margin: 50px auto;
        border-radius: 5px;
        padding: 40px 0px;
    }
        
    #APP5Challenge>div:nth-child(1){
        height: auto;
        width: 90%;
        /* background-color: red; */

        h1{
            margin: 15px auto 0px auto;
            font-size: 35px;
        }
        p{
            margin: 10px auto 0px auto;
            font-size: 16px;
        }
        
        button{
            margin: 50px auto 0px auto;
            border-radius: 7px;
            padding: 8px 15px;
            font-size: 15px;
            letter-spacing: 1px;
            border:1px solid;
        }
    }
}
@media screen and (max-width:930px) {

    #APP5Challenge{
        margin: 30px auto;
        padding: 30px 0px;
        width: 80%;
    }
        
    #APP5Challenge>div:nth-child(1){

        h1{
            font-size: 30px;
        }
        p{
            font-size: 14px;
        }
        
        button{
            margin: 40px auto 0px auto;
        }
    }
}