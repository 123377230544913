#BPP4StayConnected{
    height: auto;
    width: 70%;
    padding: 10vh 0vh 15vh 0vh;
    margin: auto;
    /* background-color: red; */
}
#BPP4StayConnectedTop{
    height: auto;
    width: 100%;
    /* background-color: pink; */
    justify-content: space-between;
}
#BPP4StayConnectedTop>div:nth-child(1){
    height: auto;
    width: 50%;
    
}
#BPP4StayConnectedTop>div:nth-child(2){
    height: auto;
    width: 30%;
    /* background-color: green; */
    justify-content: flex-end;
    gap: 3vh;
}
#BPP4StayConnectedTop>div:nth-child(2)>button:nth-child(2){
    background-color: white;
    border: 1px solid gray;
    border-radius: 0.5vh;
    padding: 1vh 3vh;
    cursor: pointer;
    transition: all 0.3s;
    letter-spacing: 0.2vh;
}
#BPP4StayConnectedTop>div:nth-child(2)>button:nth-child(2):hover{
    transform: scale(1.07);
}





/* -------------- BPP4StayConnectedBottom ------------------ */

#BPP4StayConnectedBottom{
    height: auto;
    width: 100%;
    justify-content: space-between;

}

#BPP4StayConnectedBottomLeft{
    height: auto;
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5vh;
}
.contactCardsList{
    /* background-color: red; */
    padding: 3vh 3vh 3vh 0vh;
    text-align: start;


    h2{
        font-size: 3vh;
        margin: 2vh 0vh 1.5vh 0vh;
        color: var(--heading-color);
        grid-area: title;
    }
    p{
        font-size: 2vh;
        margin: 0vh;
        color: var(--heading-description-color);
        grid-area: contentP;
    }
}
.socialMediaIcon{
    width: 5vh;
    height: 5vh;
    background-color: var(--icon-bg-color);
    color: white;
    border-radius: 100%;
    font-size: 2.5vh;
    grid-area: socialIcon;
}
#socialSec{
    /* background-color: red; */
    text-align: start;
    font-size: 2vh;
    justify-content: flex-start;
    gap: 3vh;
    margin-top: 2vh;
}
.socialLink{
    cursor: pointer;
    color: var(--heading-description-color);
    text-decoration: none;
}
.socialLink:hover{
    transform: scale(1.1);
    transition: all 0.3s;
    color:var(--icon-bg-color);
}
#BPP4StayConnectedBottomRight{
    height: auto;
    width: 40%;
    background-color: whitesmoke;
    padding: 5vh;
    text-align: start;
    border-radius:1.5vh;
    
    div{
        height: auto;
        width: 100%;
        h4{
            font-size: 1.8vh;
            color:var(--heading-color);
            margin: 0px 0px 0.5vh 0px;
        }
    }

    button{
        width: 98%;
    }
}
#BPP4StayConnectedBottomRight>div:nth-child(1){
    input{
        width: 90%;
        padding: 0px 2vh;
        height: 4vh;
        border-radius: 0.8vh;
        border:0px;
        outline: 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
}
#BPP4StayConnectedBottomRight>div:nth-child(2){
    margin: 3vh 0vh;
    textarea{
        height: 20vh;
        width: 90%;
        resize: none;
        padding: 2vh;
        border-radius: 0.8vh;
        border:0px;
        outline: 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
}




@media screen and (max-width:1420px) {
    #BPP4StayConnectedTop>div:nth-child(2)>button:nth-child(2){
        padding: 10px 20px;
    }
}


@media screen and (max-width:1024px) {
    #BPP4StayConnected{
        padding: 50px 0vh 100px 0vh;
    }
    #BPP4StayConnectedTop>div:nth-child(1){
        height: auto;
        width: 40%;
    }
    #BPP4StayConnectedTop>div:nth-child(2){
        width: 50%;
        gap: 20px;
        
        button{
            font-size: 12px;
        }
    }
    #BPP4StayConnectedTop>div:nth-child(2)>button:nth-child(2){

        border-radius:5px;
        padding:8px 20px;
        letter-spacing: 1px;
    }
    
    #BPP4StayConnectedBottom{
        height: auto;
        width: 100%;
        justify-content: space-between;
        
    }
    
    #BPP4StayConnectedBottomLeft{
        gap: 10px;
    }
    .contactCardsList{
        padding: 20px 20px 20px 0vh;
        
        
        h2{
            font-size: 20px;
            margin: 12px 0px 10px 0px;
        }
        p{
            font-size: 12px;
        }
    }
    .socialMediaIcon{
        width: 40px;
        height: 40px;
        font-size:20px;
    }
    #socialSec{
        font-size: 13px;
        gap: 20px;
        margin-top: 10px;
    }
    
    #BPP4StayConnectedBottomRight{
        
        padding: 30px;
        border-radius:8px;
        
        div{
            h4{
                font-size: 12px;
                margin: 0px 0px 5px 0px;
            }
        }
    }
    #BPP4StayConnectedBottomRight>div:nth-child(1){
        input{
            width: 90%;
            padding: 0px 12px;
            height: 30px;
            border-radius: 5px;
        }
    }
    #BPP4StayConnectedBottomRight>div:nth-child(2){
        margin: 20px 0px;
        textarea{
            height: 150px;
            padding: 12px;
            border-radius: 5px;
        }
    }
}
@media screen and (max-width:930px) {
    #BPP4StayConnected{
        width: 80%;
    }
}

@media screen and (max-width:770px) {
    #BPP4StayConnected{
        padding: 30px 0px;
    }
    #BPP4StayConnectedTop>div:nth-child(1){
        width:50%;
        p{
            font-size: 12px;
        }
    }
    #BPP4StayConnectedTop>div:nth-child(2){
        width: 50%;
    }
    #BPP4StayConnectedBottom{
        align-items: flex-start;
    }
    .contactCardsList{
        display: grid;
        grid-template-areas: 
        "socialIcon title title title title title"
        "socialIcon title title title title title"
        "socialIcon contentP contentP contentP contentP contentP"
        "socialIcon contentP contentP contentP contentP contentP"
        ;
        padding: 10px 10px 10px 0px;
        
        h2{
            font-size: 16px;
            margin: 0px;
        }
        p{
            font-size: 12px;
            margin: 0px;
        }
    }    
    #BPP4StayConnectedBottomLeft{
        grid-template-columns: repeat(1,1fr);
        gap: 20px 10px;
        padding: 20px 0px;
    }
}

@media screen and (max-width:600px) {
    #BPP4StayConnectedTop{
        display: grid;
    }
    #BPP4StayConnectedTop>div:nth-child(1){
        width: 100%;
    }
    #BPP4StayConnectedTop>div:nth-child(2){
        width: 100%;
        justify-content: flex-start;

    }
    #BPP4StayConnectedBottom{
        padding: 30px 0px;
        flex-direction: column;
        width: 100%;
    }
    #BPP4StayConnectedBottomLeft{
        grid-template-columns:repeat(2,1fr);
        width: 100%;
        /* background-color: red; */
    }
    .contactCardsList{
        padding: 0px;
        display: grid;
        grid-template-areas: 
        "socialIcon socialIcon socialIcon socialIcon"
        "title title title title"
        "contentP contentP contentP contentP"
        ;
        padding: 10px 10px 10px 0px;
        h2{
            margin: 15px 0px 5px 0px;
        }
        p{
            margin: 0px 0px 0px 0px;
        }
    }
    #BPP4StayConnectedBottomRight{
        width:80%;
        margin: 20px auto;
    }
}

@media screen and (max-width:400px) {
    #BPP4StayConnectedBottomRight{
        width:70%;
        margin: 20px auto;
    }
    #BPP4StayConnectedBottomLeft{
        grid-template-columns:repeat(1,1fr);
    }
    .contactCardsList{
        display: grid;
        grid-template-areas: 
        "socialIcon title title title title title"
        "socialIcon title title title title title"
        "socialIcon contentP contentP contentP contentP contentP"
        "socialIcon contentP contentP contentP contentP contentP"
        ;
        padding: 10px 10px 10px 0px;      
        h2{
            font-size: 16px;
            margin: 0px;
        }
        p{
            font-size: 12px;
            margin: 0px;
        }
    }    
}