#JOP1Top{
    height: auto;
    width: 70%;
    margin:10vh auto 0px auto;
    padding: 10vh 0px 5vh 0px;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    border-bottom: 1px solid rgb(227, 227, 227);
    /* background-color: red; */
}

#Left{
    width: 60%;
    height: auto;

    h1{
        margin: 0;
        font-size: 5vh;
        /* color: var(--heading-color); */
    }
    h3{
        font-size: 3vh;
        margin: 0;
    }
    ul{
        padding: 0.2vh 0px 0px 2.5vh;

        li{
            font-size: 2vh;
            margin: 1vh 0px;
        }
    }

    
}
#Left>div{
    height: auto;
    width: 100%;
}

.paddingApplied{
    padding: 7vh 0px;
}

#LookingFor{
    padding: 10vh 0px 7vh 0px;
}

#Education{
    height: auto;
    width: 100%;

    p{
        font-size: 2vh;
        width: 82%;
        line-height: 2.8vh;
    }
}












#Right{
    width: 30%;
    height: auto;
}

#Right>div:nth-child(1){
    text-align: center;
    background-color: var(--alt-bg-color);
    height: auto;
    width: 80%;
    padding: 5vh;

    h4{
        font-size: 3vh;
        margin: 0;
    }
}

#keyFeatures{
    height: auto;
    width: 100%;
    padding: 6vh 0px;
    text-align: start;
    justify-content: flex-start;
}
#keyFeatures>div:nth-child(2){
    /* padding: 5vh 0px; */
    height: auto;
    width: 100%;
}
#keyFeatures>div:nth-child(2)>div{
    margin: 7vh 0px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5vh;
}
.jobIcon{
    width: 15%;
    /* background-color:khaki; */
    font-size: 4vh;
    justify-content: flex-start;
    align-items: flex-start;
}
.jobDesc{
    width: 85%;

    h5{
        margin: 0;
        font-size: 2vh;
        color: var(--heading-description-color);
    }
    p{
        margin: 0.5vh 0px 0px 0px;
        font-size: 1.8vh;
    }
}
#linkStyle{
    font-size: 2vh;
    color: var(--heading-color);
    text-align: start;
}
#share{
    height: auto;
    width: 100%;
    padding: 5vh 0px;
    p{
        font-size: 2vh;
        margin: 0;
    }
}
#share>div{
    justify-content: flex-start;
    margin-top: 2vh;
    gap: 2vh;

    div{
        border-radius: 100vh;
        color: var(--txt-white-color);
        height: 5vh;
        width: 5vh;
        font-size: 2.5vh;
    }
}




@media screen and (max-width:980px) {
    #JOP1Top{
        margin:70px auto 0px auto;
        padding: 60px 0px 30px 0px;
    }
    
    #Left{
        width: 55%;
        h1{
            font-size: 30px;
        }
        h3{
            font-size:20px;
        }
        ul{
            padding: 1px 0px 0px 15px;
    
            li{
                font-size: 12px;
                margin: 7px 0px;
            }
        }
    
        
    }
    
    .paddingApplied{
        padding: 40px 0px;
    }
    
    #LookingFor{
        padding: 60px 0px 40px 0px;
    }
    
    #Education{
    
        p{
            font-size: 12px;
            width:90%;
            line-height: 18px;
        }
    }  
    #Right{
        width: 35%;
    }
    
    #Right>div:nth-child(1){
        width: 80%;
        padding: 30px;
    
        h4{
            font-size: 20px;
        }
    }
    
    #keyFeatures{
        padding: 35px 0px;
    }
    #keyFeatures>div:nth-child(2)>div{
        margin: 45px 0px;
        gap: 10px;
    }
    .jobIcon{
        font-size: 25px;
    }
    .jobDesc{
    
        h5{
            font-size: 14px;
        }
        p{
            margin: 3px 0px 0px 0px;
            font-size: 12px;
        }
    }
    #linkStyle{
        font-size: 12px;
    }
    #share{
        padding: 30px 0px;
        p{
            font-size: 12px;
        }
    }
    #share>div{
        margin-top: 12px;
        gap: 15px;
    
        div{
            border-radius: 100px;
            height: 35px;
            width: 35px;
            font-size: 18px;
        }
    }
}


@media screen and (max-width:930px) {
    #JOP1Top{
        width: 80%;
    }
}


@media screen and (max-width:750px) {
    #JOP1Top{
        flex-direction: column;
    }
    #Left{
        width: 100%;
        margin:30px 0px;
    }
    #Right{
        width: 100%;

        button{
            display: none;
        }
    }
    #Right>div:nth-child(1){
        width: 85%;
        padding:10px 7.5% 30px 7.5%;
    }
    #keyFeatures>div:nth-child(2){
        display: flex;
        flex-wrap: wrap;
        gap: 30px 20px;
        padding: 40px 0px;
    }
    #keyFeatures>div:nth-child(2)>div{
        margin: 0;
        width: 47%;
    }
}

@media screen and (max-width:505px) {
    #JOP1Top{
        margin-top: 20px;
    }
}
@media screen and (max-width:490px) {
    #keyFeatures>div:nth-child(2)>div{
        margin: 0;
        width: 100%;
    }
    .jobIcon{
        width: 10%;
    }
    .jobDesc{
        width: 90%;
    }
}
@media screen and (max-width:350px) {
    .jobIcon{
        width: 15%;
    }
    .jobDesc{
        width: 80%;
    }
    #Left{
    
        h1{
            font-size: 25px;
        }
        h3{
            font-size: 18px;
        }
    }
}
