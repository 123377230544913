#CAP8Contact{
    height: auto;
    width: 100%;
    margin-top: 20vh;
    position: relative;
    transition: all 0.3s;
}
#CAP8Contact>div:nth-child(1){
    height: auto;
    transition: all 0.3s;
    width: 65%;
    padding: 8vh 3vh;
    background-color: white;
    /* margin:0px auto -15vh auto; */
    border-radius: 2vh;
    gap: 5vh;
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 2vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#divider{
    height: 8vh;
    width: 0.2vh;
    background-color: gray;
}

#CAP8Contact>div:nth-child(2){
    padding: 10vh 0px 15vh 0px;
    background-image: url("../../Media//careersContactSection8Wave.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.content{
    width: auto;
    height: auto;
    gap: 2vh;

    h4{
        font-size: 2.5vh;
        margin: 0;
        color: var(--heading-description-color);
    }
}
.Icon{
    height: 7vh;
    width: 7vh;
    background-color: var(--icon-bg-color);
    color: white;
    font-size: 3vh;
    border-radius: 10vh;
}





@media screen and (max-width:980px) {
    #CAP8Contact{
        margin-top: 120px;
    }
    #CAP8Contact>div:nth-child(1){
        padding: 40px 18px;
        border-radius: 10px;
        gap: 40px;
        bottom: 20px;
    }
    #divider{
        height: 40px;
        width: 2px;
    }
    
    #CAP8Contact>div:nth-child(2){
        padding: 65px 0px 90px 0px;
    }
    .content{
        gap: 12px;
        
        h4{
            font-size: 16px;
        }
    }
    .Icon{
        height: 40px;
        width: 40px;
        font-size: 19px;
        border-radius: 100px;
    } 
}
@media screen and (max-width:930px) {
    #CAP8Contact>div:nth-child(1){
        width: 75%;
        left: 12.5%;
        right: 12.5%;
    }
}
@media screen and (max-width:670px) {
    #CAP8Contact{
        margin-top: 100px;
    }
    #CAP8Contact>div:nth-child(1){
        padding: 30px 14px;
        gap: 30px;
        bottom: 25px;
    }
    #divider{
        height: 30px;
    }
    
    #CAP8Contact>div:nth-child(2){
        padding: 55px 0px 80px 0px;
    }
    .content{
        gap: 10px;
        
        h4{
            font-size: 14px;
        }
    }
    .Icon{
        height: 30px;
        width: 30px;
        font-size: 14px;
    }
}
@media screen and (max-width:570px) {
    #CAP8Contact>div:nth-child(1){
        padding: 30px 14px;
        gap: 30px;
        bottom: 5px;
    }
    .content{
        flex-direction: column;
        h4{
            margin-top: 10px;
            
        }
    }
    .Icon{
        height: 50px;
        width: 50px;
        font-size: 20px;
    }
    #divider{
        height: 90px;
        width: 1px;
    }
}
@media screen and (max-width:450px) {
    #CAP8Contact>div:nth-child(1){
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .content{
        flex-direction: row;
        h4{
            margin-top: 10px;
            
        }
    }
    #divider{
        height: 1px;
        width: 100%;
    }
    
    #CAP8Contact>div:nth-child(2){
        padding: 100px 0px 100px 0px;
    }
    #CAP8Contact>div:nth-child(1){
        left: 10%;
        right: 10%;
    }
}

@media screen and (max-width:350px) {
    #CAP8Contact>div:nth-child(1){
        left: 8%;
        right: 8%;
    }

}