#HWP2Work{
    height: 85vh;
    width: 100%;
    margin: 10vh auto 15vh auto;
    position: relative;
}
#main{
    height: auto;
    width: 70%;
    position: absolute;
    top:0;
    bottom:0;
    right: 15%;
    left: 15%;
    /* background-color: rgba(255, 0, 0, 0.14); */
    z-index: 2;
    gap: 10px;
    justify-content: space-between;
}
#main>div:nth-child(1){
    height: auto;
    width: 29%;
    padding: 0px 5vh;
    text-align: start;
    /* background-color: red; */
    h1{
        color: var(--heading-color);
        margin: 0;
        font-size: 5vh;
        text-transform: uppercase;
    }
    p{
        color: var(--heading-description-color);
        font-size: 2vh;
        line-height: 2.8vh;
    }
}




#GridList{
    /* background-color: red; */
    height: 100%;
    width:50%;
    display: grid;
    grid-template-areas: 
    "div1 div1 div4 div4"
    "div2 div2 div4 div4"
    "div2 div2 div4 div4"
    "div2 div2 div4 div4"
    "div2 div2 div4 div4"
    "div2 div2 div4 div4"
    "div2 div2 div5 div5"
    "div3 div3 div5 div5"
    "div3 div3 div5 div5"
    "div3 div3 div5 div5"
    "div3 div3 div5 div5"
    "div3 div3 div5 div5"
    "div3 div3 div6 div6"
    ;
    gap: 3vh 3vh;
}

#GridList>div{
    border-radius: 4vh;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#GridList>div:nth-child(1){
    display: none;
}
#GridList>div:nth-child(2){
    grid-area: div2;
}
#GridList>div:nth-child(3){
    grid-area: div3;
}
#GridList>div:nth-child(4){
    grid-area: div4;
}
#GridList>div:nth-child(5){
    grid-area: div5;
}
#GridList>div:nth-child(6){
    display: none;
}


.ListCard{
    flex-direction: column;
    width: auto;
    height: auto;

    h4{
        color: var(--heading-color);
        font-size: 3vh;
        margin:3vh 0px 2vh 0px;
        width: 20vh;
        /* background-color: red; */
    }
    p{
        color: var(--heading-description-color);
        margin: 0px;
        font-size: 2vh;
        width: 20vh;
        /* background-color: red; */
    }
}
.cardIcon{
    height: 9vh;
    width: 9vh;
    border-radius: 3vh;
    img{
        height: 70%;
        width: 70%;
    }
}


#LeftSVG{
    position: absolute;
    top: 37%;
    left: 15%;
    background-color: rgba(255, 245, 219, 1);
    width: 20vh;
    height: 20vh;
    border-top-left-radius: 10vh;
}

#RightSVG{
    position: absolute;
    top: 12.5%;
    bottom: 12.5%;
    right: 0;
    width: 68%;
    background-color: rgba(244, 249, 255, 1);
    height: 75%;
    border-top-left-radius: 15vh;
    z-index: 1;
}
#DotsPattern{
    width: 30vh;
    position: absolute;
    left: 30%;
    bottom: 5%;
}
#PinkRing{
    border: 0.6vh solid rgba(255, 0, 122, 1);
    height: 10vh;
    width: 10vh;
    border-radius: 100vh;
    position: absolute;
    bottom: 4vh;
    right: 26vh;

}

@media screen and (max-width:1246px) {
    
    
    #main>div:nth-child(1){
        width: 35%;
    }
    #GridList{
        width:55%;
    }
    
}
@media screen and (max-width:1100px) {
    #main>div:nth-child(1){
        width: 25%;

        h1{
            font-size: 3.5vh;
        }
        p{
            color: var(--heading-description-color);
            font-size: 2vh;
            line-height: 2vh;
        }
    }
    #GridList{
        width:60%;
    }
}
@media screen and (max-width:980px) {
    #HWP2Work{
        height: 500px;
        margin: 65px auto;
    }
    #main{
        gap: 10px;
    }
    #main>div:nth-child(1){
        width: 25%;
        padding: 0px 30px;
        h1{
            font-size: 20px;
            margin: 30px 0px 0px 0px;
        }
        p{
            font-size: 12px;
            line-height: 16px;
        }
    }
    
    
    
    
    #GridList{
        width:60%;
        gap: 20px;
    }
    
    #GridList>div{
        border-radius: 20px;
    }
    
    .ListCard{
    
        h4{
            font-size: 18px;
            margin:18px 0px 11px 0px;
            width: 120px;
        }
        p{
            font-size: 11px;
            width: 120px;
        }
    }
    .cardIcon{
        height: 60px;
        width: 60px;
        border-radius: 20px;
    }
    
    
    #LeftSVG{
        width: 120px;
        height: 120px;
        border-top-left-radius: 60px;
    }
    
    #RightSVG{
        border-top-left-radius: 90px;
    }
    #DotsPattern{
        width: 180px;
    }
    #PinkRing{
        border: 4px solid rgba(255, 0, 122, 1);
        height: 70px;
        width: 70px;
        border-radius: 100px;
        bottom: 25px;
        right: 100px;
        
    }
}
@media screen and (max-width:930px) {
    #main{
        width: 80%;
        right: 10%;
        left: 10%;
    }
    #LeftSVG{
        left: 10%;
    }
    #PinkRing{
        right: 6%;
    }
}
@media screen and (max-width:750px) {
    #HWP2Work{
        height: auto;
        margin: 0px auto 65px auto;
        /* background-color: red; */
    }
    #main{
        display: block;
        position: relative;
    }
    #main>div:nth-child(1){
        width: 100%;
        padding: 50px 0px;
    }
    #GridList{
        width:100%;
        gap: 30px;
    }
    .ListCard{
        
        padding:60px 50px;
        h4{
            font-size: 18px;
            margin:18px 0px 11px 0px;
            width: 150px;
        }
        p{
            font-size: 11px;
            width: 150px;
            line-height: 20px;
        }
    }
    .cardIcon{
        height: 60px;
        width: 60px;
        border-radius: 20px;
    }
    #LeftSVG{
        display: none;
    }
    #RightSVG{
        display: none;
    }
    #DotsPattern{
        display: none;
    }
    #PinkRing{
        display: none;
    }
}
@media screen and (max-width:650px) {

    
    .ListCard{
        
        padding:40px 20px;
        h4{
            font-size: 15px;
            margin:18px 0px 11px 0px;
            width: 100px;
        }
        p{
            font-size: 9px;
            width: 100px;
            line-height: 12px;
        }
    }
}
@media screen and (max-width:500px) {
    #GridList{
        display: flex;
        flex-direction: column;
   }
   .ListCard{
        
    padding:40px 20px;
    h4{
        font-size: 15px;
        margin:18px 0px 11px 0px;
        width: 100%;
    }
    p{
        font-size: 9px;
        width: 100px;
        width: 100%;
    }
}
}