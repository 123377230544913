#Error404Page{
    height: 100vh;
    margin: auto;
    width: 100%;
    justify-content: flex-start;
    gap: 1vh;
    position: relative;
}
#Error404Page>img:nth-child(2){
    width: 55vw;
    margin-top: 15vh;
}
#Error404Page>img:nth-child(4){
    position: absolute;
    top: 15vh;
    left: 15vw;
}
#Error404Page>img:nth-child(1){
    position: absolute;
    bottom: 2vh;
    right: 10vw;
}
#RightSection{
    margin-top: 15vh;
    height:auto;
    width: 30vw;
    /* background-color: red; */
    color: var(--heading-description-color);

    h1{
        font-size: 20vh;
        margin: 0px;
    }
    h2{
        font-size: 6vh;
        margin: -2vh auto 4vh auto;
    }
    p{
        font-size: 2vh;
        margin: auto;
    }

    button{
        margin-top: 5vh;
    }
}






@media screen and (max-width:1024px) {
    #Error404Page>img:nth-child(4){
        left: 10vw;
    }
    #Error404Page>img:nth-child(1){
        right: 7vw;
    }
    #RightSection{
        h1{
            font-size: 18vh;
        }
        h2{
            font-size: 5vh;
        }
        p{
            font-size: 1.8vh;
        }
    }    
}
@media screen and (max-width:980px) {
    #Error404Page{
        height: 75vh;
        gap: 7px;
    }
    #Error404Page>img:nth-child(2){
        width: 55%;
        margin-top: 90px;
    }
    #Error404Page>img:nth-child(4){
        top: 100px;
        left: 10%;
        width: 60px;
    }
    #Error404Page>img:nth-child(1){
        bottom: 12px;
        right: 5%;
        width: 60px;
    }
    #RightSection{
        margin-top: 90px;
        height:auto;
        width: 30%;
        
        h1{
            font-size:100px;
        }
        h2{
            font-size: 30px;
            margin: -15px auto 20px auto;
        }
        p{
            font-size: 11px;
        }
        button{
            margin-top: 35px;
        }
    }
}
@media screen and (max-width:930px) {
    #Error404Page>img:nth-child(2){
        width: 60%;
        margin-top: 60px;
    }
    #RightSection{
        margin-top: 60px;
        height:auto;
        width: 30%;
    }
}
@media screen and (max-width:770px) {
    #Error404Page{
        justify-content: center;
    }
    #Error404Page>img:nth-child(2){
        display: none;
    }
    #RightSection{
        margin-top: 70px;
        width: 80%;
        
        h1{
            font-size:130px;
        }
        h2{
            font-size: 30px;
            margin: -13px auto 20px auto;
        }
        p{
            width: 80%;
            margin: auto;
        }
        button{
            margin-top: 35px;
        }
    }
}
@media screen and (max-width:500px) {
    #RightSection{
        margin-top: 50px;
        p{
            width: 100%;
        }
    }
}
@media screen and (max-width:350px) {
    #RightSection{
        
        h1{
            font-size:100px;
        }
        h2{
            font-size: 25px;
            margin: -13px auto 20px auto;
        }
        p{
            width: 80%;
            font-size: 9px;
            margin: auto;
            line-height: 12px;
        }
    }
}