
/*  ---------- Home Page Part 5    Industries     ---------- */


#HPP5Industries{
    height: auto;
    width: 100%;
    background-image:linear-gradient(90deg, rgba(16,54,129,1) 0%, rgba(8,141,210,1) 100%);
    padding: 5vh 0px;
    position: relative;
    
}
#HPP5Industries>div:nth-child(1){
    height: 100%;
    width: 70vw;
    /* background-color: red; */
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    
}
#industriesTopLeft{
    height: 58vh;
    width: 60%;
    /* background-color: yellowgreen; */
    text-align: start;
    
    p{
        margin: -2vh 0px 0px 0px;
        color: var(--txt-white-color);
        font-size: 2vh;
        width: 80%;
        line-height: 2.5vh;
    }
}
#industriesTopRight{
    height: 58vh;
    width:30%;
    /* background-color: hotpink; */
    text-align: start;
    padding: 2vh 2.5vh;
    align-items: flex-start;
    justify-content: flex-start;
}


.timeLineIcon{
    /* padding: 5px; */
    border: 0px;
    border-radius: 100%;
    border: 0.3vh solid white;
    background-image: url("../../Media/iconBG.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--timeline-icon-bg-color);
    height: 4.5vh;
    width: 4.5vh;
    margin-top:2vh;
    color: var(--txt-white-color);
    font-size: 2.5vh;

    img{
        width: 1.7vh;
    }
}

.timelineChildren{
    height: 8vh;
    /* width: 30vw; */
    margin-left: 2vh;
    color: var(--txt-white-color);
    /* background-color: red; */

    h2{
        margin: 0px;
        font-size: 2.5vh;
    }

    p{
        margin: 0px;
        font-size: 1.9vh;
    }


}

.dotsPattern1, .dotsPattern2, .dotsPattern3, .dotsPattern4{
    position: absolute;
}
.dotsPattern1{
    top: 20vh;
    left: -4vw;
    width: 15vh;
}
.dotsPattern2{
    bottom: 0px;
    right: 30vh;
    position: absolute;
    width: 10vh;
    /* display: none; */
}
.dotsPattern3{
    bottom: 300px;
    right: -70px;
}
.dotsPattern4{
    top: 200px;
    /* right: -90px; */
}



@media screen and (max-width:2561px) {

    .timeLineIcon{
        margin-top:3vh;
    }
    
    .timelineChildren{
        margin-left: 3vh;
    }
}
@media screen and (max-width:1440px) {

    .timeLineIcon{
        margin-top:1vh;
    }
    
    .timelineChildren{
        margin-left: 2vh;
    }
}
@media screen and (max-width:1100px) {
    #industriesTopRight{
        p{
            line-height: 2.5vh;
        }
    }
    #industriesBottom{
        p{
            line-height: 2.5vh;
        }
    }
    .timeLineIcon{
        margin-top:1vh;
    }
}





/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
@media screen and (max-width:980px) {
    
    #HPP5Industries{
        padding: 40px 0px;
        
    }
    #HPP5Industries>div:nth-child(1){
        width: 70%;
    }
    #industriesTopLeft{
        height: auto;
        width:60%;

        h1{
            color: white;
        }
        p{
            margin: -15px 0px 0px 0px;
            font-size: 14px;
            width: 90%;
            line-height: 20px;
        }
    }
    #industriesTopRight{
        padding: 15px 20px;
        height: auto;
    }
    
    
    .timeLineIcon{
        border: 3px solid white;
        height:35px;
        width: 35px;
        margin-top:20px;
        font-size: 18px;
    
        img{
            width: 100%;
        }
    }
    
    .timelineChildren{
        height: 70px;
        margin-left: 20px;
        /* background-color: red; */
    
        h2{
            font-size: 20px;
        }
    
        p{
            font-size: 15px;
        }
    
    
    }
    
    .dotsPattern1, .dotsPattern2, .dotsPattern3, .dotsPattern4{
        position: absolute;
    }
    .dotsPattern1{
        top: 100px;
        left: -40px;
        width: 100px;
    }
    .dotsPattern2{
        right: 100px;
        width: 70px;
        
    }
    .dotsPattern3{
        bottom: 300px;
        right: -70px;
    }
    .dotsPattern4{
        top: 200px;
        /* right: -90px; */
    }
    
    

}
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */





@media screen and (max-width:935px) {
    #HPP5Industries>div:nth-child(1){
        width: 80%;
    }
}
@media screen and (max-width:800px) {
    #HPP5Industries{
        padding: 20px 0px 50px 0px;

    }
    #HPP5Industries>div:nth-child(1){
        display: flex;
        flex-direction: column;
    }
    #industriesTopLeft{
        height: auto;
        width: 100%;
        p{
            width: 100%;
        }
    }
    #industriesTopRight{
        width: 100%;
        margin: 0;
        padding: 70px 20px 0vh 20px;
        /* position: relative; */
        height: auto;
        /* background-color: yellow; */
        justify-content: center;
    }
    #industriesBottom{
        width: auto;
        padding: 70px 0vh;
        /* position: relative; */
    }
    #industriesBottom>div:nth-child(1){
        width:94%;
        
        /* background-color: rgb(255, 221, 221); */
    }
    .dotsPattern1, .dotsPattern2, .dotsPattern3, .dotsPattern4{
        display: none;
    }
    
    .timeLineIcon{
        margin-top:15px;    
    }

    .timelineChildren{
        /* height: 20vh;
        width: 30vw; */
        margin-left: 10px;
    }


}

@media screen and (max-width:600px) {
    #industriesTopRight{
        width: 100%;
        margin: 0;
        padding: 10vh 3vh 0vh 3vh;
        /* position: relative; */
        height: auto;
    }
}


@media screen and (max-width:500px) {
    #industriesTopRight{
        width: 100%;
        margin: 0;
        padding: 10vh 3vh 0vh 3vh;
        /* position: relative; */
        height: auto;
    }
    #industriesBottom{
        width: auto;
        height: auto;
        padding: 5vh 0vh;
        /* position: relative; */
        display: none;
    }
    .timelineChildren{
        /* height: 20vh;
        width: 30vw; */
        margin-left: 20px;

    }
}