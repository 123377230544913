#HPP1_Hero{
    height:100vh;
    width: 100%;
    /* background-image: url("../../Media/HeroBG.png"); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(21, 21, 21);
    color: var(--txt-white-color);
    transition: all 0.3s;
    scroll-behavior: smooth;
}

#HPP1_Hero>div:nth-child(1){
    /* background-color: rgba(0, 0, 0, 0.52); */
    width: 100%;
    height: auto;
    position: absolute;
    transition: all 0.3s;

}

#HPP1_Hero>div:nth-child(1)>div:nth-child(1){
    height: auto;
    width: 60vw;
    /* background-color: darkslategray; */
    margin:20vh auto 0px auto;
    position: relative;
    text-align: center;
    z-index: 2;
    transition: all 0.3s;


    h1{
        margin: 0px;
        font-size: 6vh;
        font-weight: 700;
        line-height: 7vh;
    }

    p{
        margin:3vh auto 0px auto;
        color: var(--hero-p-color);
        font-weight: 500;
        font-size: 2vh;
        width: 40vw;
        line-height: 3vh;
    }
}


#HPP1_Hero>div:nth-child(1)>div:nth-child(3){
    height: 135vh;
    width: 65vw;
    background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 40%, rgba(4,116,188,0) 100%);
    /* border: 2px solid; */
    /* background: rgb(4,116,188); */
    padding: 9px;
    box-sizing: border-box;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    margin:13vh auto 0px auto;
    position: relative;
    bottom: 0;
    box-shadow:  rgba(4,116,188,0.1) 0px -20px 30px 0px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;
        
    p{
        margin: 30px auto 70px auto; 
    }
}

.divP1{
    height: 134.5vh;
    width: 100%;
    background-color: rgb(21, 21, 21);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
.divP2{
    height: 85%;
    width: 85%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow:  rgba(4,116,188,0.1) 0px -20px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;
    background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 60%, rgba(4,116,188,0) 100%);   
}
.divP3{
    height: 99.1%;
    width:100%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: rgb(21, 21, 21);
}
.divP4{
    height: 80%;
    width:80%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow:  rgba(4,116,188,0.1) 0px -20px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;
    background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 60%, rgba(4,116,188,0) 100%);   
}

.divP5{
    height: 98.6%;
    width:100%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: rgb(21, 21, 21);
    box-shadow:  rgba(4,116,188,0.1) 0px -20px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;

}
.divP6{
    height: 75%;
    width:75%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow:  rgba(4,116,188,0.1) 0px -50px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;
    background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 60%, rgba(4,116,188,0) 100%);   
}
.divP7{
    height: 96%;
    width:100%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: rgb(21, 21, 21);
    box-shadow:  rgba(4,116,188,0.1) 0px -20px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;

}
.divP8{
    height: 75%;
    width:75%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow:  rgba(4,116,188,0.1) 0px -150px 100px 50px, rgba(4,116,188,0.25) 0px -20px 100px -20px, rgba(4,116,188) 0px 0px 20px -30px;
    background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 60%, rgba(4,116,188,0) 100%);  
}


#PhonePart{
    height: 60vh;
    width: 25vw;
    /* background-color: red; */
    margin: -140vh auto 0px auto;
    position: relative;
    background-image: url("../../Media/IphoneFull.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    align-items: flex-end;
    transition: all 0.3s;
}

#PhonePart>div:nth-child(1){
    height: 93%;
    width: 82%;
    border-radius: 40px 40px 0px 0px;
    /* background-color: white; */
}
#phoneNav{
    height: 6vh;
    width: 98%;
    padding: 0px 5px 5px 5px;
    /* background-color: red; */
    justify-content: space-between;
    border-bottom: 1px solid rgba(71, 62, 85, 0.7);

    img{
        width: 8vw;
    }

    div{
        gap: 10px;
        font-size: 3.5vh;
        color: var(--phone-icon-color);
    }
}

#phoneMiddle{
    color: var(--hero-p-color);
    margin: 8vh auto 0px auto;
    height: 40vh;
    width:15.5vw;
    /* background-color: red; */

    p{
        font-size: 2vh;
        margin-bottom: 10vh;
    }

    img{
        width: 19vw;
        /* background-color: red; */
        position: absolute;
        top: 9.5vh;
        left: -8vw;
        /* margin-bottom: 50px; */

    }

}
.DownArrow{
    position: absolute;
    bottom:11vh;
    font-size: 4vh;
    /* background-color: red; */
    margin-left: -9vh;
    cursor: pointer;
    background-color: rgba(4, 116, 188, 0.4);
    border-radius: 100%;
    box-shadow: rgba(4, 116, 188, 0.9) 0px 0px 29px 5px;
}

#LetsTalkButn{
    display: none;
}


#HeroVectorPhoneVeiw{
    margin: auto;
    display: none;
}



@media screen and (max-width:1246px) {
    
    #phoneMiddle{
        width: 80%;
        img{
            width: 250px;
            top: 55px;
            left: -115px;
        }
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(3){
        height: 700px;
        width: 700px;
        margin: 100px auto 0px auto;
    }
    .divP1{
        height: 699px;
    }
    #PhonePart{
        margin-top: -750px;
        height: 400px;
        width: 300px;
    }
    
}

@media screen and (max-width:1050px) {
    
    #HPP1_Hero>div:nth-child(1)>div:nth-child(1){
        width: 80%;
        
        
        p{
            width: 75%;
        }
    }
}
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
@media screen and (max-width: 980px) {
    #HPP1_Hero{
        height:700px;
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(1){
        margin:150px auto 0px auto;
        h1{
            margin: 0px;
            font-size: 40px;
            font-weight: 700;
            line-height: 20px;
        }
        p{
            margin:30px auto 0px auto;
            font-weight: 500;
            font-size: 14px;
            width: 80%;
            line-height: 20px;
        }
    }
        
    #HPP1_Hero>div:nth-child(1)>div:nth-child(3){
        height:600px;
        width: 65%;
        background: linear-gradient(180deg, rgba(4,116,188,1) 0%, rgba(4,116,188,0) 40%, rgba(4,116,188,0) 100%);
        /* border: 2px solid; */
        /* background: rgb(4,116,188); */
        padding: 9px;
        margin:100px auto 0px auto;
        
            
        p{
            margin: 30px auto 70px auto; 
        }
    }
    .divP1{
        height:599.5px;
    }

        
    #PhonePart{
        height: 400px;
        width: 300px;
        /* background-color: red; */
        margin: -650px auto 0px auto;
    }
    #phoneNav{
        height: 40px;

        img{
            width: 70px;
        }

        div{
            gap: 10px;
            font-size: 25px;
        }
    }

    #phoneMiddle{
        margin: 70px auto 0px auto;
        height: auto;
        width:80%;
        /* background-color: red; */

        p{
            font-size: 14px;
            margin-bottom: 50px;
        }

        img{
            width: 250px;
            top: 75px;
            left: -110px;

        }

    }
    .DownArrow{
        bottom:70px;
        font-size: 35px;
        margin-left: -55px;
    }

    #LetsTalkButn{
        display: none;
    }

}
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */



@media screen and (max-width:800px) {
    #HPP1_Hero>div:nth-child(1)>div:nth-child(1){
        width: 80%;
        /* background-color: teal; */
        margin-top: 150px;


        h1{
            font-size: 30px;
        }
        p{
            font-size: 12px;
        }
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(3){
        height: 600px;
        width: 600px;
    }
    .divP1{
        height: 599px;
    }
    #PhonePart{
        margin-top: -620px;
        height: 350px;
        width: 300px;
    }
    .DownArrow{
        bottom:50px;
        margin: 0px 0px 0px -55px;
    }
}

@media screen and (max-width:700px) {
    #HPP1_Hero{
        height:550px;
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(1){
        margin-top: 140px;
        h1{
            font-size: 25px;
        }
        p{
            font-size: 10px;
            line-height: 15px;
            /* width: 80%; */
            margin: 5px auto 0px auto;
        }
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(3){
        height: 500px;
        width: 500px;
        margin-top: 70px;
    }
    .divP1{
        height: 499px;
    }
    #PhonePart{
        margin-top: -530px;
        height: 300px;
        width: 250px;
    }
    #phoneMiddle{
        img{
            width: 200px;
            left: -90px;
            top: 50px;
        }
        p{
            margin: -20px 0px 15px 0px;
        }
    }
    .DownArrow{
        display: none;
    }
}


@media screen and (max-width:500px){
    
    #HPP1_Hero{
        height:100vh;
        width: 100%;
    }
    #LetsTalkButn{
        display: none;
        justify-content: center;
        align-items: center;
        margin:15px auto;
        padding: 10px 20px;
        font-size: 12px;

    }

    #HPP1_Hero>div:nth-child(1)>div:nth-child(1){
        margin-top: 150px;
        text-align: start;
        
        h1{
            font-size: 30px;
            line-height: 30px;
        }
        p{
            font-size: 12px;
            line-height: 17px;
            width: 100%;
            margin: 40px auto 0px auto;
        }
    }
    #HPP1_Hero>div:nth-child(1)>div:nth-child(3){
        display: none;
        
    }
    #PhonePart{
        display: none;
        /* bottom: -600px; */
    }
    

    #HeroVectorPhoneVeiw{
        position: absolute;
        width: 200px;
        /* margin:20px auto 30px auto; */
        left: -20px;
        top: 315px;
        display: flex;
    }

    #LetsTalkButn{
        display: flex;
        margin-top: 100px;
    }

}



