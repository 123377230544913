#APP3Values{
    height: auto;
    width: 100%;
    margin: 5vh 0vh 0vh 0vh;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media/Pattern.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10vh 0vh 5vh 0vh;

}

#APP3Values>div:nth-child(1){
    width: 70%;
    height: auto;
    /* background-color: red; */
    text-align: center;
    color:white;
}

#headingBadge{
    padding: 0.5vh 0.5vw;
    background-color: var(--heading-badge-bg-color);
    width: fit-content;
    border-radius: 2vh;
    text-transform: uppercase;
    font-size: 1.2vh;
    font-weight: 600;
    color: var(--heading-color);
    margin: auto;
}
#heading{
    margin: 1.5vh auto 1vh auto;
    font-size: 4vh;
}
#headingDescription{
    font-size: 1.7vh;
    width: 60%;
    margin:0vh auto;
    line-height: 2.5vh;
}

#valuesCardList{
    margin: 10vh auto 0vh auto;
    height: 55vh;
    /* background-color: teal; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1vh;
}

.valueCard{
    /* background-color: greenyellow; */
    padding:1vh 3vh;

    img{
        width: 7vh;
        margin: 0vh auto;
    }

    h4{
        margin: 2vh auto 1vh auto;
        font-size: 3vh;
    }

    p{
        margin: 0vh auto;
        font-size: 1.8vh;
        line-height: 2.7vh;
    }
}


@media screen and (max-width:1441px) {
    #APP3Values{
        margin: 40px 0px 0px 0px;
        padding: 70px 0px 40px 0px;
    }
    #headingBadge{
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 9px;
    }
    #heading{
        margin: 15px auto 10px auto;
        font-size: 35px;
    }
    #headingDescription{
        font-size: 12px;
        line-height: 18px;
    }
    
    #valuesCardList{
        margin: 60px auto 0px auto;
        height: 450px;
        gap: 10px 15px;
    }
    
    .valueCard{
        padding:10px 25px;
    
        img{
            width: 50px;
        }
    
        h4{
            margin: 20px auto 10px auto;
            font-size: 24px;
        }
    
        p{
            margin: 0vh auto;
            font-size: 14px;
            line-height: 20px;
        }
    }
}



@media screen and (max-width:1025px) {
    
    #headingDescription{
        width: 80%;
    }
    
    #valuesCardList{
        margin: 50px auto 0px auto;
        height: auto;
        gap: 10px;
        /* background-color: red; */
    }
    
    .valueCard{
        padding:10px 15px;
        /* background-color: green; */
    
        img{
            width: 40px;
        }
    
        h4{
            margin: 18px auto 10px auto;
            font-size: 20px;
        }
    
        p{
            font-size: 12px;
            line-height: 15px;
        }
    }
}

@media screen and (max-width:930px) {
    #APP3Values>div:nth-child(1){
        width: 80%;
    }
    
    #headingDescription{
        width: 80%;
    }
}
@media screen and (max-width:600px) {
    #APP3Values{
        padding: 50px 0px;
    }
    #valuesCardList{
        height: auto;
        grid-template-columns: repeat(2,1fr);
    }
    
}
@media screen and (max-width:400px) {

    #valuesCardList{
        height: auto;
        grid-template-columns: repeat(1,1fr);
        text-align: center;
        gap: 30px;
    }
}