
/*  ---------- Home Page Part 6   Clients     ---------- */

#HPP6Clients{
    height: auto;
    width: 100%;
    margin: 10vh auto;
    z-index: 0;
}
#HPP6Clients>div:nth-child(1){
    height: 50%;
    width: 70vw;
    margin: auto;
    /* background-color: honeydew; */
    justify-content: flex-start;
}
#HPP6ClientsHeading{
    width: 30%;
    height: 100%;
    /* background-color: yellow; */

    p{
        font-size: 2vh;
    }
}
#HPP6ClientsAutoCarousel{
    height: auto;
    width: 50%;
    /* background-color: red; */
    position: relative;
}

.IMG{
    height: auto;
    width: 10vw;
    /* background-color: red; */
}

#HPP6ClientsAutoCarousel::after{
    right: 0;
    top:0;
    margin-top: 5vh;
    margin-right: -3.2vw;
    transform: rotate(90deg);
    width:11vw;
    height:10vh;
    position: absolute;
    z-index: 1;
    content: "";
    /* border: 1px solid red; */
    background-image: linear-gradient(180deg, white, rgba(255,255,255,0));
}
#HPP6ClientsAutoCarousel::before{
    left: 0;
    top:0;
    margin-top: 5vh;
    margin-left: -3.2vw;
    transform: rotate(90deg);
    /* border: 1px solid red; */
    width: 11vw;
    height:10vh;
    position: absolute;
    z-index: 1;
    content: "";
    background-image: linear-gradient(0deg, white, rgba(255,255,255,0));
}

.perPage3{
    display: none;
}
.perPage2{
    display: none;
}
.perPage1{
    display: none;
}


@media screen and (max-width:1245px){
    #HPP6ClientsAutoCarousel::after{
 
        margin-top: 5vh;
        margin-right: -5.2vw;
        width:15vw;
        height:10vh;
    }
    #HPP6ClientsAutoCarousel::before{
        margin-top: 5vh;
        margin-left: -5.2vw;
        width: 15vw;
        height:10vh;
    }
}
@media screen and (max-width:1100px) {
    .perPage4{
        display: none;
    }
    .perPage3{
        display: block;
    }
}



/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {

    #HPP6Clients{
        margin: 50px auto;
    }
    #HPP6Clients>div:nth-child(1){
        height: auto;
        justify-content:space-between;
        width: 70%;
    }
    #HPP6ClientsHeading{
        p{
            font-size: 13px;
        }
    }

    .IMG{
        width: 80px;
    }
    
    #HPP6ClientsAutoCarousel::after{

        margin-top: -20px;
        margin-right: -10px;
        width:100px;
        height:80px;
    }
    #HPP6ClientsAutoCarousel::before{

        margin-top: -20px;
        margin-left: -10px;
        width: 100px;
        height:80px;
       
    }    

}
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */



@media screen and (max-width:935px) {
    #HPP6Clients>div:nth-child(1){
        width: 80%;
    }
}
@media screen and (max-width:800px) {
    #HPP6Clients>div:nth-child(1){
        display: block;
    }
    #HPP6ClientsAutoCarousel{
        width: 100%;
    }
    .perPage4{
        display: block;
    }
    .perPage3{
        display: none;
    }
    #HPP6ClientsAutoCarousel::after{
 
        margin-top: -40px;
        margin-right:-10px;
        width:120px;
        height:100px;
        /* border: 1px solid red; */
    }
    #HPP6ClientsAutoCarousel::before{
        margin-top: -40px;
        margin-left: -10px;
        width: 120px;
        height:110px;
        /* border: 1px solid red; */
    }
    .IMG{
        height: auto;
        width: 150px;
        /* background-color: red; */
    }
}
@media screen and (max-width:700px) {
    .perPage4{
        display: none;
    }
    .perPage3{
        display: block;
    }
    #HPP6ClientsAutoCarousel::after{
 
        margin-top: 5vh;
        margin-right: -7.2vw;
        width:25vw;
        height:10vh;
    }
    #HPP6ClientsAutoCarousel::before{
        margin-top: 5vh;
        margin-left: -7.2vw;
        width: 25vw;
        height:10vh;
    }
}
@media screen and (max-width:500px) {
    #HPP6Clients>div:nth-child(1){
        width: 80%;
    }
    .perPage4{
        display: none;
    }
    .perPage3{
        display: none;
    }
    .perPage2{
        display: block;
    }
    .IMG{
        height: auto;
        width: 100px;
    }
    #HPP6ClientsAutoCarousel::after{
 
        margin-top:0px;
        margin-right: -34px;
        width:120px;
        height:60px;
        /* border: 1px solid red; */
    }
    #HPP6ClientsAutoCarousel::before{
        margin-top: 0px;
        margin-left: -34px;
        width: 120px;
        height:60px;
        /* border: 1px solid red; */
    }
    #HPP6ClientsHeading{
        width: 100%;
        height: 100%;
        /* background-color: yellow; */
    
        p{
            font-size: 2vh;
            width: 100%;
        }
    }
}