#SPP5ChooseUS{
    height: auto;
    width: 100%;
    padding: 7vh 0px;
    background-color: var(--alt-bg-blue-color);


    h1{
        font-size: 5vh;
    }
}
#SPP5ChooseUS>div:nth-child(1){
    width: 80%;
    margin: auto;
    color: var(--txt-white-color);
    text-align: center;

    h2{
        font-size: 3.5vh;
    }

    
    p{
        width: 60%;
        margin: auto;
        font-size: 2vh;
    }
}


@media screen and (max-width:980px) {
    #SPP5ChooseUS{
        padding: 40px 0px;
    
    
        h1{
            font-size: 33px;
        }
    }
    #SPP5ChooseUS>div:nth-child(1){
    
        h2{
            font-size: 20px;
        }
    
        
        p{
            font-size: 11px;
        }
    }
}
@media screen and (max-width:930px) {
    #SPP5ChooseUS>div:nth-child(1){
        width: 88%;
        p{
            width: 80%;
        }
    }
}
@media screen and (max-width:770px) {

}