#CAP1Hero{
    height: auto;
    width: 100%;
    background-image: url("../../Media/careerHero.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--txt-white-color);
    text-align: start;
}
#CAP1Hero>div:nth-child(1){
    height:100vh;
    width: 100%;
    /* padding: 10vh; */
    margin: auto;
    background-color: #161c2dd8;
}
#content{
    width: 25%;
    margin: auto;
    height: auto;

    h1{
        margin: 0px;
        font-size: 5vh;
    }
    
    p{
        margin: 2vh auto 1.5vh auto;
        line-height: 2.8vh;
        font-size: 2vh;
    }
    
    span{
        margin: 0px;
        color: var(--icon-bg-color);
        font-size: 1.5vh;
        letter-spacing: 0.1vh;
        font-weight: 600;
    }

    button{
        border-radius: 10vh;
        background-color: var(--icon-bg-color);
        margin-top: 4vh;
    }
}










/* ---------------------  Media Query -------------------- */


@media screen and (max-width:1024px) {
    #content{
        width: 30%;
    }
}


@media screen and (max-width:980px) {

    #CAP1Hero>div:nth-child(1){
        height:600px;
    }
    #content{
        width:40%;
        padding-top: 30px;
    
        h1{
            font-size: 40px;
        }
        
        p{
            margin: 12px auto 8px auto;
            line-height: 20px;
            font-size: 13px;
        }
        
        span{
            font-size: 10px;
            letter-spacing: 1px;
        }
    
        button{
            border-radius:50px;
            margin-top: 30px;
        }
    }
    
}


@media screen and (max-width:800px){
    #content{
        width:55%;
        padding-top: 50px;
    }
}


@media screen and (max-width:600px){
    #content{
        width:60%;
    }
}
@media screen and (max-width:500px){
    #CAP1Hero>div:nth-child(1){
        height:auto;
        padding: 100px 0px;
    }
    #content{
        width:80%;
    }
}
@media screen and (max-width:350px){
    #CAP1Hero>div:nth-child(1){
        padding: 70px 0px;
    }
    #content{
        h1{
            font-size: 30px;
        }
        
        p{
            margin: 12px auto 8px auto;
            line-height:18px;
            font-size: 12px;
        }
        
        span{
            font-size: 8px;
            letter-spacing: 1px;
            line-height: 10px;
        }
    }
}