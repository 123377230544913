#BPP2BlogsList{
    height: auto;
    padding: 10vh 0vh;
    /* background-color:lightskyblue; */
    width: 70%;
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.3s;
}
#SearchBoxTop{
    display: none;
}



/* ---------------- BlogsList --------------- */

#BlogsList{
    width: 55%;
    /* background-color: red; */
    height: auto;
    display: grid;
    gap: 3vh;
    padding-bottom: 2vh;
}
#pagination{
    height: auto;
    width: 100%;
    padding: 4vh 0vh 2vh 0vh;
    /* background-color: red; */
    border-top: 2px solid gray;
    font-size: 2vh;
}
.blogsListPagination{
    font-size: 2vh;
/*     
    li{
        font-size: 2vh;
        background-color: red;
        min-width: 2vh;
        height: 30px;

        button{
            height: 30px;
        }
    } */
}









/* ---------------- RecentBlogs --------------- */

#RecentBlogs{
    width: 38%;
    /* background-color: red; */
    height: auto;
    text-align: start;
    
}
#RecentBlogs>h2{
    font-size: 2.5vh;
    margin: 0px;
}
#SearchBox{
    height: 5vh;
    width: 90%;
    background-color: white;
    border-radius: 0.5vh;
    border: 1px solid gray;
    justify-content: flex-start;
    gap: 1.5vh;
    font-size: 2.5vh;
    color: gray;
    padding: 0px 2vh;

    input{
        height: 70%;
        width: 85%;
        color: gray;
        outline: 0px;
        border: 0px;
        background-color: transparent;
        font-size: 2vh;
    }
}
#RecentBlogsList{
    height: auto;
    width: 100%;
    /* background-color: lightseagreen; */
    padding: 5vh 0px ;
    margin-top: 3vh;
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    display: grid;
    gap: 3vh;
    grid-area: RBL;
}



/* ---------------- blogsCategory --------------- */

#blogsCategory{
    height: auto;
    width: 100%;
    padding: 3vh 0px;
    /* background-color: red; */
    grid-area: BC;
    h2{
        margin: 2vh 0vh;
    }

}
.collapsePanel{
    margin-bottom: 0;
    background: transparent;
    border:0px;
    font-size: 1.7vh;
    font-weight: 500;
}



/* ---------------- blogsTags --------------- */

#blogsTags{
    height: auto;
    width: 100%;
    padding: 3vh 0px;
    /* background-color: red; */
    grid-area: BT;
    
    h2{
        margin: 2vh 0vh;
        text-transform: uppercase;

    }
}
#blogTagsList{
    height: auto;
    padding: 1vh 0px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2vh  3vh;

    p{
        margin: 0px;
        height: auto;
        cursor: pointer;
        color: var(--heading-description-color);
        font-size: 2vh;
        width: fit-content;

    }
}









/* ----------------------------- 1024px ------------------------------------------- */

@media screen and (max-width:1024px) {
    #BPP2BlogsList{
        padding: 70px 0px;
    }
    #BlogsList{
        gap: 20px;
        padding-bottom: 13px;
    }
    #pagination{
        padding: 30px 0vh 12px 0px;
        font-size: 15px;
    }
    .blogsListPagination{
        font-size: 14px;
    }
    #RecentBlogs>h2{
        font-size: 17px;
    }
    #SearchBox{
        height: 35px;
        border-radius:5px;
        gap: 10px;
        font-size: 16px;
        padding: 0px 12px;
        
        input{
            font-size: 12px;
        }
    }
    #RecentBlogsList{
        padding: 25px 0px ;
        margin-top: 30px;
        gap: 18px;
    }
    #blogsCategory{
        padding: 18px 0px;
        
        h2{
            margin: 12px 0px;
        }
    }
    .collapsePanel{
        font-size: 12px;
    }
    #blogsTags{
        padding: 19px 0px;
        
        h2{
            margin: 12px 0vh;
        }
    }
    #blogTagsList{
        padding: 7px 0px;
        gap: 12px  19px;

        p{
            font-size: 13px;
            
        }
    }
    
}





/* ------------------------ 930px ----------------------- */

@media screen and (max-width:930px) {
    #BPP2BlogsList{
        width: 80%;
    }
}







/* ------------------------ 800px ----------------------- */
@media screen and (max-width:800px) {
    #BPP2BlogsList{
        padding: 40px 0px;
    }
    #BlogsList{
        padding-bottom: 10px;
    }
    #pagination{
        padding: 20px 0vh 8px 0px;
        font-size: 15px;
    }
    .blogsListPagination{
        font-size: 12px;
    }
    #RecentBlogs>h2{
        font-size: 15px;
        margin: 0px;
    }
    #SearchBox{
        height: 30px;
        font-size: 14px;
        gap: 5px;

        input{
            font-size: 10px;
        }
    }
    #RecentBlogsList{
        padding: 15px 0px ;
        margin-top: 20px;

        h2{
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
    
    #blogsCategory{
        
        h2{
            font-size: 20px;
        }
    }
    .collapsePanel{
        font-size: 10px;
    }
    #blogsTags{
        padding: 10px 0px;
        
        h2{
            font-size: 20px;
        }
    }
    #blogTagsList{
        padding: 7px 0px;
        gap: 8px  14px;

        p{
            font-size: 10px;
            
        }
    }
}




/* ------------------------ 600px ----------------------- */
@media screen and (max-width:600px) {
    #BPP2BlogsList{
        padding: 50px 0vh;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
    


    #BlogsList{
        width: 100%;
        gap: 20px;
        padding-bottom: 10px;
    }
    #pagination{
        height: auto;
        width: 100%;
        padding: 20px 0vh 10px 0vh;
        font-size: 10px;
    }

    
    #RecentBlogs{
        width:100%;
        display: grid;
        gap: 20px;
        grid-template-areas: 
        "RBL RBL"
        "RBL RBL"
        "BC BT"
        "BC BT"
        ;
    }
    #RecentBlogsList{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        border: 0px;
        margin-top: 50px;
        border-bottom: 2px solid gray;
        h2{
            margin: 0px;
        }
    }
    #SearchBox{
        display: none;
    }
    #blogsTags{
        h2{
            margin: 20px 0px;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
    #SearchBoxTop{
        width: 94%;
        background-color: white;
        border: 1px solid gray;
        justify-content: flex-start;
        color: gray;
        height: 35px;
        border-radius:5px;
        gap: 10px;
        font-size: 16px;
        padding: 0px 12px;
        margin:0px 0px 40px 0px ;
        display: flex;
    
        input{
            height: 70%;
            width: 85%;
            color: gray;
            outline: 0px;
            border: 0px;
            background-color: transparent;
            font-size: 12px;
        }
    }


}


/* ------------------------ 400px ----------------------- */
@media screen and (max-width:400px) {

    #blogsCategory, #blogsTags, #RecentBlogsList{
        h2{
            font-size: 14px;
        }
    }
    #RecentBlogsList{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    #RecentBlogs{
        width:100%;
        display: grid;
        gap: 20px;
        grid-template-areas: 
        "RBL RBL"
        "RBL RBL"
        "BC BC"
        "BT BT"
        ;
    }
}