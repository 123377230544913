#CPP1Hero{
    height: auto;
    width: 100%;
    margin-top: 12vh;
    padding: 15vh 0px;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media/Pattern.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
#CPP1Hero>div{
    height: auto;
    width: 100%;
    margin: auto;
    color: var(--txt-white-color);

    p{
        margin: 0px auto 3vh auto;
        width: 60%;
        font-size: 2vh;
        color: var(--heading-description-color);
        line-height: 2.2vh;
        letter-spacing: 0.2vh;
        text-transform: uppercase;
    }
    h1{
        margin:auto;
        font-size: 5vh;
        width:30%;
        line-height: 6.5vh;
    }
}






@media screen and (max-width:1440px) {
    #CPP1Hero>div{

        h1{
            width:50%;
        }
    } 
}
@media screen and (max-width:980px) {
    #CPP1Hero{
        margin-top: 130px;
        padding: 120px 0px;
    }
    #CPP1Hero>div{
        
        p{
            margin: 0px auto 15px auto;
            width: 60%;
            font-size: 13px;
            line-height: auto;
            letter-spacing: 2px;
        }
        h1{
            margin: 0px auto;
            font-size: 30px;
            width:80%;
            line-height: 25px;
        }
    }
    
    
    
}
@media screen and (max-width:600px) {
    #CPP1Hero{
        margin-top: 80px;
        padding: 120px 0px 100px 0px;
    }
    #CPP1Hero>div{
        
        p{
            margin: 0px auto 10px auto;
            width: 60%;
            font-size: 13px;
            line-height: auto;
            letter-spacing: 2px;
        }
        h1{
            margin: 0px auto;
            font-size: 25px;
            width:80%;
            line-height: 35px;
        }
    }
}