#CPP3Appointment{
    height: auto;
    width: 100%;
    padding: 10vh 0vh;
    margin: auto;
    background-color: var(--alt-bg-lightGreen-color);
}

#CPP3Appointment>div:nth-child(1){
    height: auto;
    width: 50%;
    margin: auto;
}

#heading{
    height: auto;
    width: 100%;
    margin: auto;
}
#heading>p:nth-child(1){
    font-size: 1.5vh;
    letter-spacing: 0.1vh;
    text-transform: uppercase;
    color: var(--heading-description-color);
    font-weight: 600;
    margin:0px auto;
}
#heading>h1:nth-child(2){
    font-size: 4vh;
    font-weight: 600;
    color: var(--heading-color);
    margin: 1vh auto 1.5vh auto;
}
#heading>p:nth-child(3){
    font-size: 2vh;
    color: var(--heading-description-color);
    width: 60%;
    margin:0px auto;
    line-height: 3vh;
}

#formBox{
    height: auto;
    width: 100%;
    background-color: white;
    padding: 10vh 0px;
    margin:5vh auto 0px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 2vh;
}
#formBox>form{
    height: auto;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2vh;

    
}
#formBox>form>div{
    height: 3vh;
    width: 95%;
    font-size: 3vh;
    padding: 1vh 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--heading-description-color);
    gap: 2vh;
    border-radius: 1vh;
    /* background-color: red; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
#formBox>form>div>input{
    width: 95%;
    height: 80%;
    border: 0px;
    outline: 0px;
    color: var(--heading-description-color);
    font-size: 1.8vh;
}
#formBox>form>div:nth-child(5){
    height: auto;
    padding: 1vh 2vh;
    align-items: flex-start;
    textarea{
        width: 95%;
        height: 20vh;
        border: 0px;
        outline: 0px;
        color: var(--heading-description-color);
        font-size: 1.8vh;
        resize: none;
        font-family: "poppins";
    }
}
#formBox>form>input:last-child{
    padding: 1.2vh 0px;
    margin:0.5vh auto 0px auto;
    width: 100%;
    text-transform: uppercase;
    border:0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    letter-spacing: 0.2vh;
    cursor: pointer;
    transition: letter-spacing 0.3s, all 0.3s;
}
#formBox>form>input:last-child:hover{
    letter-spacing: 0.4vh;
}






@media screen and (max-width:1024px) {
    #heading>p:nth-child(3){
        width: 90%;
    }
    #formBox{
        padding: 8vh 0px;
    }
}


@media screen and (max-width:980px) {
    #CPP3Appointment{
        padding: 60px 0vh;
    }
    #heading>p:nth-child(1){
        font-size: 8px;
        letter-spacing: 1px;
    }
    #heading>h1:nth-child(2){
        font-size: 25px;
        margin: 10px auto;
    }
    #heading>p:nth-child(3){
        font-size: 13px;
        line-height: 18px;
    }
    
    #formBox{
        padding: 50px 0px;
        margin:30px auto 0px auto;
        border-radius: 15px;
    }
    #formBox>form{
        gap: 13px;
    
        
    }
    #formBox>form>div{
        height: 22px;
        font-size: 20px;
        padding: 7px 12px;
        gap: 12px;
        border-radius: 6px;
        input{
            font-size: 14px;
        }
        
    }
    #formBox>form>div:nth-child(5){
        padding: 7px 12px;
        textarea{
            height: 120px;
            font-size: 14px;
        }
    }
    #formBox>form>input:last-child{
        padding: 10px 0px;
        margin:5px auto 0px auto;
        border-radius: 7px;
        letter-spacing: 1px;
    }
    #formBox>form>input:last-child:hover{
        letter-spacing: 2px;
    }
}
@media screen and (max-width:930px) {

    #CPP3Appointment>div:nth-child(1){
        width: 70%;
    }
}
@media screen and (max-width:600px) {
    #CPP3Appointment>div:nth-child(1){
        width: 80%;
    }
}
@media screen and (max-width:400px) {
    #CPP3Appointment>div:nth-child(1){
        width: 80%;
    }
    #formBox>form{
        gap: 13px;
    }
    #formBox>form>div{
        padding: 7px;
        font-size: 18px;
        input{
            font-size: 12px;
        }
    }
    #formBox>form>div:nth-child(5){
        padding: 7px;
        textarea{
            font-size: 12px;
        }
    }
    #formBox>form>input:last-child{
        font-size: 10px;
    }
}