/*  ---------- Home Page Part 3    Technologies     ---------- */


#HPP3Technologies{
    padding: 10vh 0px;
    height: auto;
    margin:0vh auto 0px auto;
    width: 100%;
    background-color: var(--alt-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}
#HPP3Technologies>div:nth-child(1){
    padding: 4vh 5vw;
    background-color: var(--alt-bg-blue-color);
    width: 60.3vw;
    height: 45vh;
    border-radius: 1vh;
    background-image: url("../../Media/Pattern.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
    transition: all 0.3s;
}
#techLeft{
    height: 100%;
    width: 45%;
    /* background-color: red; */
    text-align: start;

    p{
        margin: -1.5vh 0px 0px 0px;
        color: var(--txt-white-color);
        font-size: 1.5vh;
        line-height: 2vh;
    }
}
#techRight{
    height: 100%;
    width: 45%;
    /* background-color: red; */
    position: relative;
    overflow: hidden;
    flex-direction: column;

    img{
        width: 90%;
        margin: auto;
        -webkit-animation: scrollUp 30s linear infinite;
        animation: scrollUp 30s linear infinite 
    }
}

@-webkit-keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-76%);
              transform: translateY(-76%);
    }
  }
  
  @keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-76%);
              transform: translateY(-76%);
    }
  }


#techRight::before{
    top: -1px;
    left: 0;
    width: 100%;
    height: 30px;
    position: absolute;
    z-index: 1;
    content: "";
    background-image: linear-gradient(180deg, var(--scrollar-shade-color), rgba(0, 0, 0, 0));
}
#techRight::after{
    bottom: -1px;
    transform: rotate(180deg);
    left: 0;
    width: 100%;
    height: 30px;
    position: absolute;
    z-index: 1;
    content: "";
    background-image: linear-gradient(180deg, var(--scrollar-shade-color), rgba(255,255,255,0));
}




@media screen and (max-width:1100px) {
    #techLeft{
        width: 55%;
    }
    #techRight{
        width: 40%;
    }
    
}
@media screen and (max-width:1000px) {
    #techLeft{
        width: 55%;
    }
    #techRight{
        width: 40%;
    }
}


/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
@media screen and (max-width: 980px) {
    
    
    #HPP3Technologies{
        padding: 70px 0px;
    }
    #HPP3Technologies>div:nth-child(1){
        padding: 30px 40px;
        background-color: var(--alt-bg-blue-color);
        width: 62%;
        height:500px;
        border-radius: 10px;
    }
    #techLeft{
        height: 100%;
        width: 50%;
        gap: 0x;

        div{
            margin-top: -10px;
        }

        h1{
            font-size: 20px;
            margin: 20px auto 0px auto;
            color: white;
        }

        p{
            margin: -20px 0px 0px 0px;
            color: var(--txt-white-color);
            font-size: 13px;
            line-height: 15px;
        }

    }
    #techRight{
        height: 80%;
        width: 45%;
    }
}
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */


@media screen and (max-width:930px) {
    #HPP3Technologies>div:nth-child(1){
        width: 70%;
    }   
    
}

@media screen and (max-width:800px) {

    #HPP3Technologies>div:nth-child(1){
        display: block;
        width: 70%;
        height:auto;
    }
    #techLeft{
        height: auto;
        width: 100%;
    }
    #techRight{
        height: 300px;
        width: 80%;
        margin:50px auto 0px auto;
    }
    #techRight::before{
        background-image: linear-gradient(180deg, #073169, rgba(0, 0, 0, 0));
    }
    
}

@media screen and (max-width:600px) {
    #HPP3Technologies{
        padding: 0px 0px 20px 0px;
    }

    
    #techRight::before{
        background-image: linear-gradient(180deg, #073169, rgba(0, 0, 0, 0));
    }
}

@media screen and (max-width:400px) {
    #techRight{
        height: 200px;
        width: 85%;
        margin: auto;
    }
    #techLeft{
        height: auto;
        width: 100%;
        padding-bottom: 30px;
    }
    #techRight{
        height: 200px;
        width: 90%;
        margin: auto;
    }
    
}
