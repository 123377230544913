#SPP2Services{
    height: auto;
    width: 70vw;
    margin:10vh auto;
}

#ServicesList{
    margin: 5vh auto 0px auto;
    display: grid;
    gap: 10vh;
}



@media screen and (max-width:980px){
    #SPP2Services{
        width: 70%;
        margin:70px auto;


    }    
    #ServicesList{
        margin: 0px auto 0px auto;
        gap: 65px;
    }
    
}


@media screen and (max-width:930px){
    #SPP2Services{
        height: auto;
        width: 80%;
        margin:10vh auto;
    }       
}

@media screen and (max-width:600px){
    #ServicesList{
        margin:0px auto;
        gap:15px;
    }
}

