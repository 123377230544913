
/*  ---------- Home Page Part 2    Services  ---------- */


#HPP2_Services{
    height: auto;
    width: 70%;
    margin:10vh auto;
    transition: all 0.3s;
}

#serviceCardList{
    height: auto;
    margin:5vh auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* background-color: red; */
    gap: 3vh;
    transition: all 0.3s;
}



@media screen and (max-width:1246px) {
    #serviceCardList{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width:1100px) {
    #serviceCardList{
        grid-template-columns: repeat(2,1fr);
    }
}


/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
@media screen and (max-width: 980px) {
    #HPP2_Services{
        margin:60px auto;
    }
    
    #serviceCardList{
        margin:10px auto;
        gap: 20px;
    }
    
}
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */


@media screen and (max-width:930px) {
    #HPP2_Services{
        width: 80%;
    }
}

@media screen and (max-width:630px) {
    #serviceCardList{
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width:500px) {
    #serviceCardList{
        grid-template-columns: repeat(1,1fr);
        width: 100%;
        margin: 0px;
        gap: 10;
    }
}