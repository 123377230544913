#CAP6Process{
    height: auto;
    width: 100%;
    margin: auto;
    padding: 25vh 0px 10vh 0px;
    background-image: url("../../Media/careerSection6Wave.svg");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s;
}

#heading{
    height: auto;
    width: 70%;
    margin: auto;
    transition: all 0.3s;
}

#heading>h2{
    font-size:4vh;
    /* color: var(--heading-color); */
}
#heading>p{
    font-size: 2vh;
    color: var(--heading-description-color);
    margin: auto;
    width: 60%;
}
#Timeline{
    height: auto;
    width: 70%;
    padding: 10vh 0px;
    margin: auto;
    transition: all 0.3s;

}

.timelineDot{
    height: 5vh;
    width: 5vh;
    border-radius: 10vh;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: black;
    font-size: 2vh;
    font-weight: 500;
    margin: 0px;
    padding: 0;
}
.timelineDot:hover{
    border: 3px solid #0040D8;
    cursor: pointer;
}
.activeDot{
    border: 3px solid #0040D8;
}
.timelineChildren{
    padding: 0px;
    margin: 0px 3vh 0px 2vh;
    h3{
        margin: 0;
        font-size: 2.5vh;
    }
    p{
        margin: 0;
        color: var(--heading-description-color);
        font-size: 1.8vh;
    }
}

#BottomPart{
    height: auto;
    width: 70%;
    margin:6vh auto 0px auto;
    img{
        margin: auto;
        width: 75%;
    }
}



@media screen and (max-width:980px) {
    #CAP6Process{
        padding: 150px 0px 65px 0px;
    }
    
    #heading>h2{
        font-size:25px;
    }
    #heading>p{
        font-size: 14px;
    }
    #Timeline{
        padding: 65px 0px;
    }
    
    .timelineDot{
        height: 35px;
        width: 35px;
        border-radius: 100px;
        font-size: 14px;
    }
    .timelineChildren{
        margin: 0px 20px 0px 12px;
        h3{
            font-size: 18px;
        }
        p{
            font-size: 12px;
        }
    }
    
    #BottomPart{
        margin:40px auto 0px auto;
    }
}
@media screen and (max-width:930px){
    #Timeline{
        width: 80%;
    }
    #heading{
        width: 80%;
    }
    #BottomPart{
        width: 80%;
    }
}
@media screen and (max-width:768px){
    #CAP6Process{
        padding: 100px 0px 65px 0px;
    }
    #BottomPart{
        height: auto;
        width: 70%;
        margin:6vh auto 0px auto;
        img{
            margin: auto;
            width: 80%;
        }
    }
    #Timeline{
        padding: 80px 0px 65px 0px;
    }
    
    
}
@media screen and (max-width:600px){
    .timelineDot{
        height: 35px;
        width: 35px;
        border-radius: 100px;
        font-size: 14px;
    }
    .timelineChildren{
        margin: 0px 20px 0px 12px;
        h3{
            font-size: 14px;
            margin-bottom:5px;
        }
        p{
            font-size: 10px;
        }
    }
}