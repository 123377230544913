
/*  ---------- Home Page Part 9   customer Reviews     ---------- */

#HPP9Reviews{
    height: 40vh;
    width: 100%;
    justify-content: space-between;
    margin:10vh auto ;
    /* background-color: var(--alt-bg-color);                               ------------------------------- need suggestion */
}
#HPP9ReviewsLeft{
    height: 100%;
    width: 40vw;
    background-image: linear-gradient(90deg, rgba(16,54,129,1) 0%, rgba(8,141,210,1) 100%);
    justify-content: end;
}
#HPP9ReviewsLeft>div:nth-child(1){
    height: auto;
    width: 64%;
    text-align: start;
    /* background-color: red; */
    margin-top: -30px;
    color: var(--txt-white-color);
    
    h1{
        margin: 0px;
        font-size: 4vh;
    }
    
    
}
#HPP9ReviewsLeft>div:nth-child(1)>div{
    width: 5vw;
    height: 10vh;
    background-color:transparent;
    border-radius: 10vh;
    margin: 0px 0px -3vh -3vw;
    img{
        width: 3vh;
    }
}
#HPP9ReviewsRight{
    height: 95%;
    width: 59%;
    /* background-color: var(--alt-bg-color); */
    overflow: hidden;
    justify-content: start;
    align-items: start;
}

.carouselItem{
    height:70%;
    width: 90%;
    /* background-color: teal; */
    text-align: start;


    p{
        width: 30vw;
        font-size: 2vh;
        height: 85%;
        /* background-color: yellow; */
        margin: 0px;
    }
    h4{
        margin: 0px 0px 1vh 0px;
        height: 10%;
        /* background-color: pink; */
        color: var(--txt-blue-color);
        font-size: 3vh;
    }

    span{
        margin:0px 0px 0px 0px;
        height: 10%;
        /* background-color: pink; */
        color: var(--icon-color);
        font-size: 1.5vh;
    }
}


@media screen and (max-width:1100px) {
    #HPP9Reviews{
        height: 300px;
    }
    .carouselItem{
        h4{
            margin: 0px 0px 0vh 0px;
        }
    }
}





/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {
    
    #HPP9Reviews{
        height: 300px;
        margin:100px auto ;
    }
    #HPP9ReviewsLeft{
        width: 40%;
    }
    #HPP9ReviewsLeft>div:nth-child(1){
        h1{
            font-size: 25px;
        }
    }
    #HPP9ReviewsLeft>div:nth-child(1)>div{
        width: 50px;
        height: 80px;
        border-radius: 100px;
        margin: 0px 0px -25px -30px;
        img{
            width: 20px;
        }
    }
    
    .carouselItem{
    
    
        p{
            width: 90%;
            font-size: 14px;
        }
        h4{
            margin: 0px 0px 3px 0px;
            font-size: 20px;
        }
    
        span{
            font-size: 12px;
        }
    }


}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */







@media screen and (max-width:800px) {
    #HPP9ReviewsLeft>div:nth-child(1){
        h1{
            font-size: 20px;
        }
    }
}
@media screen and (max-width:650px) {
    #HPP9ReviewsLeft>div:nth-child(1){
        margin: 0px;
    }
    #HPP9Reviews{
        height: 200px;
    }
}
@media screen and (max-width:426px) {
    #HPP9Reviews{
        flex-direction: column;
        height: auto;
        margin: 20px 0px;
    }
    #HPP9ReviewsLeft{
        height: 150px;
        width: 100%;
        padding-bottom: 20px;
    }
    #HPP9ReviewsRight{
        height: 250px;
        width: 100%;
        /* background-color: red; */
        margin: 10px 0px;
    }
    
    
#HPP9ReviewsLeft>div:nth-child(1){
    height: auto;
    width: 80%;
    text-align: start;
    /* background-color: red; */
    color: var(--txt-white-color);
    
    h1{
        margin: 0px 0px 0px -20px;
        font-size: 4vh;
    }
    
    
}
#HPP9ReviewsLeft>div:nth-child(1)>div{
    width: 5vw;
    height: 10vh;
    background-color:transparent;
    border-radius: 10vh;
    margin: 0px 0px -15px -40px;
    img{
        width: 3vh;
    }
}
.carouselItem{
    height:70%;
    width: 85%;
    /* background-color: teal; */
    text-align: start;
    margin-left: 25px;


    p{
        width: 100%;
        font-size: 11px;
        height: 80%;
        /* background-color: yellow; */
        margin: 0px;
        line-height:14px;
    }
    h4{
        margin: 0px;
        height: 10%;
        /* background-color: pink; */
        color: var(--txt-blue-color);
        font-size: 3vh;
    }

    span{
        margin: 0px;
        height: 10%;
        /* background-color: pink; */
        color: var(--icon-color);
        font-size: 1.5vh;
    }
}
}
@media screen and (max-width:321px) {
    .carouselItem{
        height:70%;
        width: 80%;
        /* background-color: teal; */
        text-align: start;
        margin-left: 15px;
    
    
        p{
            width: 100%;
            font-size:10px;
            height: 80%;
            /* background-color: yellow; */
            margin: 0px;
        }
    }
}