#CAP3{
    height: auto;
    width: 100%;
    margin:35vh auto 20vh auto;
    /* background-color: red; */
    color:var(--txt-white-color);
    position: relative;
    transition: all 0.3s;
    div{
        border-radius: 100%;
        margin: auto;
        transition: all 0.3s;
    }

    img{
        width: 8vh;
        position: absolute;
        transition: all 0.3s;
    }
}

#CAP3>img:nth-child(2){
    top: 15vh;
    left: 40vh;
}
#CAP3>img:nth-child(3){
    bottom: 5vh;
    right: 50vh;
}
#CAP3>img:nth-child(4){
    bottom: 2.5vh;
    right: 46vh;
    width: 6vh;
}




#layer1{
    position: relative;
    width: 90vh;
    height: 90vh;
    /* border: 1px solid rgba(51, 51, 51, 0.44); */
    border: 1px solid rgba(51, 51, 51, 0.44);
}
#layer2{
    width: 65vh;
    height: 65vh;
    border: 1px solid rgba(51, 51, 51, 0.44);
}
#layer3{
    width: 37vh;
    height: 37vh;
    padding: 5vh;
    border: 1px solid rgba(51, 51, 51, 0.44);
    background-color: var(--icon-bg-color);

    h2{
        color:var(--txt-white-color);
        font-size: 3vh;
    }
}
.bubble{
    padding: 2vh;
    height: 11vh;
    width: 11vh;
    background-color: red;
    position: absolute;
    p{
        font-size: 1vh;
        line-height: 1.5vh;
    }
}
.oddBubble{
    background-color: var(--spp-servicecard-color);
    color: black;
}
.evenBubble{
    background-color: var(--alt-bg-blue-color);
}



#layer1>div:nth-child(1){
    top: 15vh;
    left: 0;
}
#layer1>div:nth-child(2){
    top: 45vh;
    left: -5vh;
}
#layer1>div:nth-child(3){
    bottom: 10vh;
    left: 20vh;
}
#layer1>div:nth-child(4){
    bottom:-5vh;
    right: 25vh;
}
#layer1>div:nth-child(5){
    bottom:20vh;
    right: -4vh;
}
#layer1>div:nth-child(6){
    top:20vh;
    right: 8vh;
}



@media screen and (max-width:2561px) {
    #CAP3>img:nth-child(2){
        left: 30vh;
    }
    
    #CAP3>img:nth-child(3){
        bottom: 3vh;
        right: 40vh;
    }
    #CAP3>img:nth-child(4){
        bottom: 0.5vh;
        right: 36vh;
    }
}
@media screen and (max-width:1440px) {
    #CAP3>img:nth-child(2){
        left: 35vh;
    }   
}
@media screen and (max-width:1120px) {
    #CAP3{
        margin:45vh auto 20vh auto;
    }
}
@media screen and (max-width:1024px) {
    #CAP3{
        margin:35vh auto 20vh auto;
    }
    #CAP3>img:nth-child(2){
        left: 15vh;
    }   
    #CAP3>img:nth-child(3){
        bottom: 2vh;
        right: 30vh;
    }
    #CAP3>img:nth-child(4){
        bottom: 0vh;
        right: 26vh;
    }
}




@media screen and (max-width:980px) {
    #CAP3{
        margin:230px auto 130px auto;
        img{
            width:50px;
        }
    }
    
    #CAP3>img:nth-child(2){
        top: 120px;
        left: 50px;
    }
    #CAP3>img:nth-child(3){
        bottom: 0;
        right: 80px;
    }
    #CAP3>img:nth-child(4){
        bottom: -15px;
        right: 60px;
        width: 40px;
    }
    
    
    
    
    #layer1{
        width: 600px;
        height: 600px;
    }
    #layer2{
        width: 430px;
        height: 430px;
    }
    #layer3{
        width:230px;
        height:230px;
        padding: 30px;
        
        h2{
            font-size: 20px;
        }
    }
    .bubble{
        padding: 12px;
        height: 80px;
        width: 80px;
        p{
            font-size: 8px;
            line-height: 10px;
        }
    }
    
    
    #layer1>div:nth-child(1){
        top: 100px;
        left: 0;
    }
    #layer1>div:nth-child(2){
        top: 300px;
        left: -35px;
    }
    #layer1>div:nth-child(3){
        bottom: 65px;
        left: 130px;
    }
    #layer1>div:nth-child(4){
        bottom:-30px;
        right: 150px;
    }
    #layer1>div:nth-child(5){
        bottom:125px;
        right: -25px;
    }
    #layer1>div:nth-child(6){
        top:130px;
        right: 55px;
    }
    
    
}



@media screen and (max-width:850px) {
    #CAP3{
        margin:230px auto 100px auto;
    }

    #layer1{
        width: 500px;
        height: 500px;
    }
    #layer2{
        width: 350px;
        height: 350px;
    }
    #layer3{
        width:180px;
        height:180px;
        padding: 20px;
        
        h2{
            font-size: 16px;
        }
    }
    
    
    .bubble{
        padding: 12px;
        height: 65px;
        width: 65px;
        p{
            font-size: 6px;
        }
    }
    
    

    #layer1>div:nth-child(2){
        top: 270px;
        left: -20px;
    }
    #layer1>div:nth-child(3){
        bottom: 55px;
        left: 130px;
    }
    #layer1>div:nth-child(4){
        bottom:-20px;
        right: 100px;
    }
    
    #layer1>div:nth-child(6){
        top:110px;
        right: 45px;
    }
}
@media screen and (max-width:770px) {
    #CAP3{
        img{
            display: none;
        }
    }
}
@media screen and (max-width:600px) {
    #CAP3{
        margin:190px auto 100px auto;
    }
    
    #layer1{
        width: 400px;
        height: 400px;
    }
    #layer2{
        width: 280px;
        height: 280px;
    }
    #layer3{
        width:160px;
        height:160px;
        padding: 10px;
        
        h2{
            font-size: 14px;
        }
    }
    
    
    .bubble{
        padding: 10px;
        height: 55px;
        width: 55px;
        p{
            font-size: 5px;
            line-height: 7px;
        }
    }
    
    
    
    #layer1>div:nth-child(1){
        top: 70px;
        left: -10px;
    }
    #layer1>div:nth-child(2){
        top: 220px;
        left: -15px;
    }
    #layer1>div:nth-child(3){
        bottom: 35px;
        left: 110px;
    }
    #layer1>div:nth-child(4){
        bottom:-15px;
        right: 50px;
    }
    #layer1>div:nth-child(5){
        top:170px;
        right: -30px;
    }
    
    #layer1>div:nth-child(6){
        top:70px;
        right: 55px;
    }
}
@media screen and (max-width:560px) {
    #CAP3{
        margin:230px auto 100px auto;
    }
}
@media screen and (max-width:500px) {
    #CAP3{
        margin:230px auto 100px auto;
    }
    #layer1{
        width: 300px;
        height: 300px;
    }
    #layer2{
        width: 220px;
        height: 220px;
    }
    #layer3{
        width:120px;
        height:120px;
        h2{
            font-size: 11px;
        }
    }


    .bubble{
        padding: 6px;
        height: 45px;
        width: 45px;
        p{
            font-size: 4px;
            line-height: 7px;
        }
    }
    
    
    
    #layer1>div:nth-child(1){
        top:60px;
        left: -10px;
    }
    #layer1>div:nth-child(2){
        top: 180px;
        left: -15px;
    }
    #layer1>div:nth-child(3){
        bottom: 20px;
        left: 80px;
    }
    #layer1>div:nth-child(4){
        bottom:-10px;
        right: 40px;
    }
    #layer1>div:nth-child(5){
        top:170px;
        right: -25px;
    }
    
    #layer1>div:nth-child(6){
        top:55px;
        right: 35px;
    }
}
@media screen and (max-width:400px) {
    #CAP3{
        margin:150px auto 100px auto;
    }
    #layer1{
        width: 250px;
        height: 250px;
    }
    #layer2{
        width: 180px;
        height: 180px;
    }
    #layer3{
        width:100px;
        height:100px;
        h2{
            font-size: 9px;
        }
    }
    .bubble{
        padding: 6px;
        height: 30px;
        width: 30px;
        p{
            font-size: 4px;
            line-height: 7px;
            display: none;
        }
    }

    #layer1>div:nth-child(2){
        top: 175px;
        left: 7px;
    }
    #layer1>div:nth-child(3){
        bottom: 15px;
        left: 85px;
    }
    #layer1>div:nth-child(4){
        bottom:-8px;
        right: 40px;
    }
    #layer1>div:nth-child(5){
        top:155px;
        right:-15px;
    }
    
    #layer1>div:nth-child(6){
        top:50px;
        right: 30px;
    }
}