#JOP2ApplicationForm{
    height: auto;
    width: 70%;
    margin: auto;
    padding: 10vh 0px;
    text-align: start;
    transition: all 0.3s;
    
    h1{
        margin: 0;
        font-size: 4vh;
        color: var(--heading-color);
    }
}


#JOP2ApplicationForm>div:nth-child(2){
    padding: 3.5vh 0px;
    margin: 5vh 0px;
    height: auto;
    width: 100%;
    border-radius: 1vh;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 0.1vh 0.3vh 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 0.1vh;
}

#ApplicationFom{
    width: 95%;
    margin: auto;
    height: auto;
    text-align: center;
}

.formItem{
    height: auto;
    width: 48.8%;
    padding: 1vh 0px;
    text-align: start;
    /* background-color: lightblue; */

    p{
        margin: 0;
        font-size: 1.8vh;

        span{
            color:red;
        }
    }

}
.formItem>input{
    margin-top: 1vh;
    width: 94%;
    padding: 1.2vh 3%;
    border: 0px;
    border-radius: 0.8vh;
    outline: 0px;
    font-size:1.8vh;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 0.1vh 0.3vh 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 0.1vh;
}

#top{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5vh 3vh;
}


.uploadFile{
    width: 30%;
    padding: 4vh 0px;

    label{
        display: table;
        cursor: pointer;

        div{
            border: 0.2vh dashed var(--icon-color);
            color: var(--icon-color);
            border-radius: 0.8vh;
            font-size: 1.8vh;
            margin-top: 1vh;
            padding: 1vh 2vh;
        }
        input[type="file"] {
            display: none;
            cursor: pointer;
        }
    }
}

#middle{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5vh 3vh;
}

#bottom{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5vh 3vh;
    margin: 3vh 0px;
}
#bottom>div:nth-child(3){
    width: 100%;
    textarea{
        margin-top: 1vh;
        width: 95.4%;
        height: 20vh;
        padding: 2vh 2.2%;
        border: 0px;
        border-radius: 1vh;
        outline: 0px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 0.1vh 0.3vh 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 0.1vh;
        color: var(--heading-description-color);
        font-size: 1.8vh;
        resize: none;
        font-family: "poppins";
    }
}

#SubmitInput{
    padding: 1.2vh 10vh;
    margin:4vh auto 0px auto;
    width: auto;
    text-transform: uppercase;
    border:0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    letter-spacing: 0.2vh;
    cursor: pointer;
    font-size: 2vh;
    transition: letter-spacing 0.1s, all 0.3s;
}
#SubmitInput:hover{
    transform: scale(1.1);
    letter-spacing: 0.6vh;
}





@media screen and (max-width:1230px) {
    .formItem{
        width: 48%;
    }
}


@media screen and (max-width:980px) {
    #JOP2ApplicationForm{
        padding: 65px 0px;        
        h1{
            font-size: 30px;
        }
    }
    
    
    #JOP2ApplicationForm>div:nth-child(2){
        padding: 20px 0px;
        margin: 30px 0px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    
    .formItem{
        padding: 6.5px 0px;    
        p{
            font-size: 12px;
        }
    
    }
    .formItem>input{
        margin-top: 6.5px;
        padding: 9px 3%;
        border-radius: 5px;
        font-size:12px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    
    #top{
        gap: 15px 23px;
    }
    
    
    .uploadFile{
        width: 50%;
        padding: 30px 0px;
    
        label{
    
            div{
                border: 1px dashed var(--icon-color);
                border-radius: 5px;
                font-size: 12px;
                margin-top: 7px;
                padding: 7px 14px;
            }
        }
    }
    
    #middle{
        gap: 15px 23px;
    }
    
    #bottom{
        gap: 15px 23px;
        margin: 18px 0px;
    }
    #bottom>div:nth-child(3){
        textarea{
            margin-top: 7px;
            height: 140px;
            padding: 13px 2.2%;
            border-radius: 7px;
            outline: 0px;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            font-size: 12px;
        }
    }
    
    #SubmitInput{
        padding:12px 70px;
        margin:25px auto 0px auto;
        border-radius: 6px;
        letter-spacing: 1px;
        font-size: 12px;
    }
    #SubmitInput:hover{
        letter-spacing: 2px;
    }
    
}

@media screen and (max-width:930px) {
    #JOP2ApplicationForm{
        height: auto;
        width: 80%;
    }
    #top, #middle, #bottom{
        gap: 15px 20px;
    }
}
@media screen and (max-width:660px) {
    .formItem{
        height: auto;
        width: 47%;
    }
    #top, #middle, #bottom{
        gap: 15px 24px;
    }
}
@media screen and (max-width:525px) {
    .formItem{
        height: auto;
        width: 100%;
    }
    .uploadFile{
        width: 100%;
    }
    #JOP2ApplicationForm>div:nth-child(2){
        box-shadow: none;
    }
}
@media screen and (max-width:350px) {
    #JOP2ApplicationForm{
        
        h1{
            font-size: 25px;
        }
    }
}