#CAP4{
    height: auto;
    margin: 5vh auto;
    width: 100%;
}

#CAP4heading{
    height: auto;
    width: 100%;
    margin-top: 4vh;
    background-color: var(--alt-bg-blue-color);
    color: var(--txt-white-color);
    padding: 1.5vh 0px;
    margin-bottom: 5vh;

    h1{
        font-size: 4vh;
    }
}

#CAP4Bottom{
    height: 50vh;
    width: 70%;
    margin: auto;
    padding: 10vh 0px;
    position: relative;

    img{
        position: absolute;
        width: 35vh;
    }

    h1{
        font-size: 5.5vh;
        width: 30%;
        margin:0px auto;
        color: var(--heading-color);
    }
}
#contentBar{
    width:40%;
    margin:6vh auto 0px auto;
    text-align: start;
    padding: 2vh 3vh;
    border-radius: 10vh;
    color: var(--heading-description-color);
    font-size: 1.5vh;
    position: absolute;
    z-index: 1;
    left: 30%;
    right: 30%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

#curlyV1{
    top: 26vh;
    left: 20vh;
}
#curlyV2{
    bottom: 11vh;
    right: 33vh;
}
.bulletdot{
    width: 3vh;
    height: 3vh;
    border-radius: 10vh;
    background-color: var(--spp-servicecard-color);
    position: absolute;
    
    div{
        height: 1.5vh;
        width: 1.5vh;
        border-radius: 100%;
        background-color: var(--icon-bg-color);
    }
}

.Bullet1{
    top: 23.2vh;
    left: 36.8vh;
}
.Bullet2{
    bottom: 13.5vh;
    left: 53.1vh;
}
.Bullet3{
    bottom: 10.9vh;
    right: 67.7vh;
}
.Bullet4{
    bottom: 29.5vh;
    right: 31.5vh;
}

.circleRing{
    position: absolute;
    border: 1px solid var(--icon-bg-color);
    border-radius: 10vh;
    font-size: 1.1vh;
}
.ring1{
    top: 9.5vh;
    left: 30vh;
    height: 9vh;
    width: 9vh;
    padding: 2vh;
}
.ring2{
    bottom: 3vh;
    left: 55.8vh;
    height: 12vh;
    width: 12vh;
    padding: 2vh;
}
.ring3{
    top: 17.5vh;
    right: 23vh;
    height: 13vh;
    width: 13vh;
    padding: 3vh;
}

#careersImage{
    display: none;
}

#careersImage{
    display: none;
}







@media screen and (max-width:2560px) {
    #curlyV1{
        left: 15vh;
    }
    #curlyV2{
        right: 18vh;
    }
    
    .ring1{
        left: 25vh;
    }
    .ring2{
        left: 50.8vh;
    }
    .ring3{
        right: 10vh;
    }
    
    .Bullet1{
        left: 31.8vh;
    }
    .Bullet2{
        left: 48.1vh;
    }
    .Bullet3{
        right: 52.5vh;
    }
    .Bullet4{
        right: 16.5vh;
    }
    #CAP4Bottom{
        h1{
            width: 35%;
        }
    }
}
@media screen and (max-width:1536px) {
    #curlyV1{
        left: 20vh;
    }
    #curlyV2{
        right: 33vh;
    }
    
    .ring1{
        left: 30vh;
    }
    .ring2{
        left: 55.8vh;
    }
    .ring3{
        right: 22vh;
    }
    
    .Bullet1{
        left: 36.8vh;
    }
    .Bullet2{
        left: 53.1vh;
    }
    .Bullet3{
        right: 67.5vh;
    }
    .Bullet4{
        right: 31.5vh;
    }
    #CAP4Bottom{
        h1{
            width: 35%;
        }
    }
}
@media screen and (max-width:1440px) {
    #curlyV2{
        right: 25vh;
    }
    .Bullet3{
        right: 60vh;
    }
    .Bullet4{
        right: 23.5vh;
    }
    .ring3{
        right: 15vh;
    }
}
@media screen and (max-width:1024px) {
    #curlyV1{
        left: 7vh;
        top: 20vh;
    }
    #curlyV2{
        right: 13vh;
        bottom:15vh;
    }
    .Bullet1{
        left: 23.8vh;
        top: 17.4vh;
    }
    .Bullet2{
        left: 40.3vh;
        bottom: 19.6vh;
    }
    .Bullet3{
        right: 47.8vh;
        bottom: 15vh;
    }
    .Bullet4{
        right: 11.5vh;
        top: 34vh;
    }
    .ring1{
        left: 15vh;
        top: 4vh;
    }
    .ring2{
        left: 42vh;
        bottom: 7vh;
    }
    .ring3{
        right: 4vh;
        top: 14vh;
    }
    #CAP4Bottom{
        h1{
            font-size: 3.5vh;
            margin-top: 7vh;
        }
    }
    #contentBar{
        margin: 8vh auto 0px auto;
    }
}


@media screen and (max-width:980px) {
    #CAP4{
        margin: 40px auto;
        height: auto;
        position: relative;
        padding: 0px;
    }
    #careersImage{
        display: flex;
        width: 700px;
        margin: auto;
        padding: 30px 0px;
    }
    
    #CAP4heading{
        margin-top: 25px;
        padding: 8px 0px;
        margin-bottom: 35px;
    
        h1{
            font-size: 26px;
        }
    }
    
    #CAP4Bottom{
        height: 0px;
        /* padding:2% 0px; */
        position: absolute;
        top: 0;
        left: auto;
        right: auto;
        margin: auto;
        width: 100%;
        display: none;
    
        img{
            position: absolute;
            width: 220px;
        }
    
        h1{
            font-size: 23px;
            margin-left: 37%;
        }
    }
    #contentBar{
        width:45%;
        margin:80px auto 0px auto;
        padding: 12px 20px;
        border-radius: 100px;
        font-size: 11px;
    }
    
    #curlyV1{
        top: 130px;
        left: 50px;
    }
    #curlyV2{
        bottom:110px;
        right:70px;
    }
    .bulletdot{
        width: 20px;
        height: 20px;
        border-radius: 100px;
        
        div{
            height: 8px;
            width: 8px;
        }
    }
    
    .Bullet1{
        top: 110px;
        left: 155px;
    }
    .Bullet2{
        bottom: 150px;
        left: 259px;
    }
    .Bullet3{
        bottom: 106px;
        right: 280px;
    }
    .Bullet4{
        bottom: 100px;
        right: 61px;
    }
    
    .circleRing{
        border-radius: 100px;
        font-size:8px;
    }
    .ring1{
        top: 25px;
        left: 100px;
        height: 60px;
        width: 60px;
        padding: 12px;
    }
    .ring2{
        bottom: 68px;
        left: 273px;
        height: 80px;
        width: 80px;
        padding: 12px;
    }
    .ring3{
        top: 95px;
        right: 10px;
        height: 90px;
        width: 90px;
        padding: 15px;
    }    
}



@media screen and (max-width:800px) {
    #careersImage{
        width: 600px;
        padding: 20px 0px;
    }
}
@media screen and (max-width:650px) {
    #careersImage{
        width: 450px;
    }
}
@media screen and (max-width:500px) {
    #careersImage{
        width: 350px;
        padding: 0px;
    }
}
@media screen and (max-width:350px) {
    #careersImage{
        width: 250px;
        padding: 0px;
    }
}