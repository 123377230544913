#SPP4Commitment{
    padding: 3vh 0px;
    width: 100%;
    height: auto;
    margin: 5vh 0px 10vh 0vh;
}
#SPP4Commitment>div:nth-child(1){
    width: 70vw;
    height: auto;
    margin: auto;
    /* background-color: red; */
}
#SPP4Commitment>div:nth-child(1)>div:nth-child(2){
    gap: 7vh;
    justify-content: space-between;
    align-items: start;
}


.commitmentCard{
    height: auto;
    width: auto;
    text-align: start;

    img{
        width: 12vh;
    }

    h2{
        color: var(--txt-blue-color);
        font-size: 3.3vh;
    }

    p{
        color: rgba(85, 105, 135, 1);
        font-size: 2vh;
    }
}


@media screen and (max-width:980px) {
    #SPP4Commitment{
        padding: 18px 0px;
        margin: 35px 0px 65px 0vh;
    }
    #SPP4Commitment>div:nth-child(1){
        width: 70%;
    }
    #SPP4Commitment>div:nth-child(1)>div:nth-child(2){
        gap: 100px;
    }
    
    
    .commitmentCard{
    
        img{
            width: 80px;
        }
    
        h2{
            font-size: 25px;
        }
    
        p{
            font-size: 14px;
        }
    }
}


@media screen and (max-width:930px) {
    #SPP4Commitment>div:nth-child(1){
        width:80%;
        
    }
    #SPP4Commitment>div:nth-child(1)>div:nth-child(2){
        gap:50px;
        justify-content: space-between;
        align-items: start;
    }

    

    .commitmentCard{
        height: auto;
        width: auto;
        text-align: start;

        img{
            width: 60px;
        }

        h2{
            font-size: 18px;
        }

        p{
            font-size: 13px;
        }
    }


}
@media screen and (max-width:770px) {
    #SPP4Commitment>div:nth-child(1)>div:nth-child(2){
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:426px) {

    #SPP4Commitment>div:nth-child(1)>div:nth-child(2){
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    
}