#SPP1Hero{
    height: 50vh;
    width: 100%;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media//Pattern.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 13vh;
}

#SPP1Hero>div:nth-child(1){
    height: 90%;
    width: 55vw;
    color: var(--txt-white-color);

    h1{
        margin: 14vh auto 0px auto;
        font-size: 5vh;
    }
    p{
        text-transform: uppercase;
        font-size: 1.3vh;
        margin: 2vh auto;
        letter-spacing: 0.2vh;
        color: var(--hero-p-color)
    }
}



@media screen and (max-width:980px) {
    #SPP1Hero{
        height: 350px;
        margin-top: 90px;
    }
    
    #SPP1Hero>div:nth-child(1){
        width: 70%;
        
        h1{
            margin: 100px auto 0px auto;
            font-size: 35px;
        }
        p{
            font-size: 9px;
            margin: 13px auto;
            letter-spacing: 1px;
        }
    }
    
}
@media screen and (max-width:930px) {
    #SPP1Hero>div:nth-child(1){
        width: 80%;
    }
}



@media screen and (max-width:770px) {
    
    #SPP1Hero>div:nth-child(1){
        height: 90%;
        width: 80%;
        color: var(--txt-white-color);
        
        h1{
            margin:120px auto 0px auto;
            font-size: 22px;
        }
        p{
            text-transform: uppercase;
            font-size: 8px;
            margin: 2vh auto;
            letter-spacing: 0.2vh;
            color: var(--hero-p-color);
            line-height: 13px;
        }
    }
}

@media screen and (max-width:500px) {
    #SPP1Hero{
        height: 300px;
    }
    #SPP1Hero>div:nth-child(1){
        
        h1{
            margin:80px auto 0px auto;
        }
    }
}
@media screen and (max-width:320px) {
    #SPP1Hero{
        height: 300px;
    }
    #SPP1Hero>div:nth-child(1){
        
        h1{
            margin:80px auto 0px auto;
            font-size: 20px;
        }
    }
}



