#PrivacyPolicyPage{
    height: auto;
    width: 70%;
    margin: 15vh 0px 0px 0px;
    padding: 20vh 0px 10vh 0px;
    text-align: start;
    margin: auto;
    /* background-color: red; */

    h1{
        color: var(--heading-color);
        text-align: center;
        font-size: 5vh;
    }
}
#PrivacyPolicyPage>p{
    text-align: center;
    color: var(--heading-description-color);
    font-size: 2vh;
    width: 45%;
    margin: auto;
    line-height: 3vh;
}
#PrivacyPolicyPage>p:nth-child(3){
    text-align: start;
    margin: 15vh 0vh 10vh 0vh;
    width: 100%;
}





/* ------------------------- licensePart ------------------ */

#licensePart, #securityPart, #ContentPolicy, #termsPart{
    height: auto;
    width: 100%;

    h3{
        font-size: 3vh;
        color: var(--heading-color);
    }

    p{
        color: var(--heading-description-color);
        font-size: 2vh;
        line-height: 3vh;
    }
}







/* ------------------------- securityPart ------------------ */

#securityPart{
    margin: 10vh 0vh;

    ul{
        margin: 0vh;
        li{
            margin: 2vh 1vh;
            font-size: 2vh;
            color: var(--heading-description-color);
            list-style-type: circle;
        }
    }
}



/* ------------------------- termsPart ------------------ */
    
#termsPart{
    margin: 10vh 0px;
}




@media screen and (max-width:980px) {
    #PrivacyPolicyPage{
        height: auto;
        margin: 90px auto 0px auto;
        padding: 80px 0px 65px 0px;
        h1{
            font-size: 30px;
        }
    }
    #PrivacyPolicyPage>p{
        font-size: 12px;
        width: 70%;
        line-height:20px;
    }
    #PrivacyPolicyPage>p:nth-child(3){
        margin: 90px 0px 65px 0px;
    }
    
    
    
    
    
    /* ------------------------- licensePart ------------------ */
    
    #licensePart, #securityPart, #ContentPolicy, #termsPart{
        
        h3{
            font-size: 17px;
        }
        
        p{
            font-size: 12px;
            line-height: 20px;
        }
    }
    
    
    /* ------------------------- securityPart ------------------ */
    
    #securityPart{
        margin: 65px 0vh;
        
        ul{
            margin: 0px;
            li{
                margin: 12px 6px;
                font-size: 12px;
            }
        }
    }
    
    
    
    /* ------------------------- termsPart ------------------ */
    
    #termsPart{
        margin: 70px 0px;
    }
    
}
@media screen and (max-width:930px) {
    #PrivacyPolicyPage{
        width: 80%;
    }
    #PrivacyPolicyPage>p{
        
        width: 100%;
    }
    #PrivacyPolicyPage{
        margin: 90px auto 0px auto;
        padding: 30px 0px;
    }
}