#BPP1Hero{
    height: auto;
    width: 100%;
    padding: 7vh 0px 0px 0px;
    /* background-color: turquoise; */
    margin-top: 7vh ;
}
#BPP1Hero>div:nth-child(1){
    width:70%;
    height: auto;
    border-bottom: 2px solid gray;
    padding-bottom:12vh;
    transition: all 0.3s;

}
#headingPart{
    height: auto;
    width: 80%;
    margin: auto;
    text-align: center;

    div{
        margin: auto;
        text-align: center;
    }
}
#RecentBlog{
    height: auto;
    justify-content: space-between;
    margin-top: 3vh; 
    height: 50vh;
}
#RecentBlogFeaturedImage{
    border-radius: 4vh;
    width: 50%;
    height: 100%;

    
    img{
        border-radius: 4vh;
        width: 100%;
        height: 100%;
    }
}

#RecentBlogMetaData{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 45%;
    height: 85%;
    text-align: start;
}
#RBMDTop{
    height: 15%;
    width: 100%;
    /* background-color: red; */
    justify-content: flex-start;
    gap: 0.7vw;

    p{
        font-size: 2vh;
        font-weight: 600;
    }
    div{
        height: 40%;
        width: 0.2vh;
        background-color: gray;
    }
    span{
        font-size: 1.6vh;
        font-weight: 500;
    }
}

#RBMDMiddle{
    height: 60%;
    width: 100%;
    /* background-color: red; */

    h2{
        margin: 1vh 0px 1.7vh 0px;
        font-size: 3.5vh;
    }

    p{
        font-size: 2vh;
        margin: 0px;
    }
}

#RBMDBottom{
    height: 15%;
    width: 100%;
    /* background-color: red; */
    justify-content: flex-start;
    gap: 0.7vw;

    img{
        width: 5vh;
        height: 5vh;
        border-radius: 100%;
        /* background-color: red; */
    }

    h5{
        font-size: 2vh;
    }
}



@media screen and (max-width:1024px) {
    #BPP1Hero{
        padding: 60px 0px 0px 0px;
        /* background-color: turquoise; */
        margin-top: 60px;
    }
    #BPP1Hero>div:nth-child(1){
        padding-bottom:64px;
    }
    #RecentBlog{
        justify-content: space-between;
        margin-top: 35px; 
        height: 300px;
    }
    #RecentBlogFeaturedImage{
        border-radius: 20px;
        width: 55%;
        img{
            border-radius: 20px;
        }
    }
    
    #RecentBlogMetaData{
        gap: 8px;
        width: 40%;
        height: 90%;
    }
    #RBMDTop{
        height: 10%;
        width: 100%;
        justify-content: flex-start;
        gap: 8px;
        
        p{
            font-size: 14px;
        }
        div{
            height: 50%;
            width: 2px;
        }
        span{
            font-size: 12px;
        }
    }
    
    #RBMDMiddle{
        height: 65%;
        width: 100%;
        
        h2{
            margin: 8px 0px 12px 0px;
            font-size: 22px;
        }
        
        p{
            font-size: 13px;
        }
    }
    
    #RBMDBottom{
        gap: 10px;
        
        img{
            width: 30px;
            height: 30px;
        }
        
        h5{
            font-size: 14px;
        }
    }
    
}
@media screen and (max-width:980px) {
    #BPP1Hero>div:nth-child(1){
        padding-bottom:50px;
    }
    #headingPart{
        height: auto;
        width: 80%;
        margin: auto;
        text-align: center;
    
        div{
            margin: auto;
            text-align: center;
        }
        h1{
            margin: 15px 0px 0px 0px;
        }
    }
    #RecentBlog{
        height: auto;
        justify-content: space-between;
        margin-top: 10px;
    }
}
@media screen and (max-width:930px){
    #BPP1Hero>div:nth-child(1){
        width: 80%;        
    }
}
@media screen and (max-width:800px){
    
    #BPP1Hero>div:nth-child(1){
        padding-bottom:72px;
        padding-top: 30px;
    }
    #RecentBlog{
        margin-top: 25px; 
        height: 250px;
    }
    #RecentBlogMetaData{
        gap: 5px;
        height: 95%;
    }
    #RBMDTop{
        /* background-color: red; */
        
        p{
            font-size: 12px;
        }
        div{
            width: 1.5px;
            height: 55%;
        }
        span{
            font-size: 10px;
        }
    }
    
    #RBMDMiddle{
        /* background-color: red; */
        height: 70%;
        
        h2{
            margin: 8px 0px 10px 0px;
            font-size: 15px;
        }
        
        p{
            font-size: 10px;
            line-height: 15px;
        }
    }
    
    #RBMDBottom{
        /* background-color: red; */
        height: 12%;
        
        img{
            width: 25px;
            height: 25px;
        }
        
        h5{
            font-size: 12px;
        }
    }
}
@media screen and (max-width:600px){
    #BPP1Hero{
        margin-top: 40px;
    }
    #BPP1Hero>div:nth-child(1){
        padding-bottom:20px;
        padding-top: 0px;
        
    }
    #headingPart{
        h1{
            margin-top: 10px;
        }
    }
    #RecentBlog{
        margin-top: 0px; 
        height: 400px;
        flex-direction: column;
        /* background-color: red; */
    }
    #RecentBlogFeaturedImage{
        width: 100%;
        height: 60%;
    }
    #RecentBlogMetaData{
        gap: 5px;
        height: 37%;
        width: 100%;
    }
    
    #RBMDMiddle{
        height: 60%;

    }
    
    #RBMDBottom{
        height: 20%;
    }
}
@media screen and (max-width:510px){
    #BPP1Hero{
        margin-top: 10px;
    }
    #BPP1Hero>div:nth-child(1){
        padding-bottom:30px;        
    }
    #RecentBlog{
        margin-top: 10px; 
    }
    #RecentBlogFeaturedImage{
        width: 100%;
        height: 60%;
    }
    #RecentBlogMetaData{
        gap: 5px;
        height: 37%;
        width: 100%;
    }
    
    #RBMDMiddle{
        height: 70%;
    }
    
    #RBMDBottom{
        height: 20%;
    }
}
@media screen and (max-width:400px){
    #BPP1Hero{
        margin-top: 10px;
        /* background-color: aquamarine; */
    }
    #BPP1Hero>div:nth-child(1){
        padding-bottom:50px;        
    }
    #RecentBlog{
        margin-top: 10px; 
        /* background-color: teal; */
        height: 300px;
    }
    #RecentBlogFeaturedImage{
        width: 100%;
        height: 60%;
    }
    #RecentBlogMetaData{
        /* background-color: red; */
        width: 99%;
    }
    
    #RBMDTop{
        /* background-color: pink; */
        height: 15%;

        p{
            font-size: 10px;
        }
        span{
            font-size: 9px;
        }
    }
    #RBMDMiddle{
        height: 50%;
        /* background-color: pink; */
        
        h2{
            font-size: 14px;
            margin: 5px 0px 10px 0px;
        }
        p{
            display: none;
        }
    }
    
    #RBMDBottom{
        /* background-color: pink; */
        height: 25%;
    }
}