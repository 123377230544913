@import url("/src/CSS//Root.css");



nav{
    height: auto;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.7s;
    z-index: 5;
}
nav>div:nth-child(1){
    height: 9vh;
    width:70vw;
    /* background-color: red; */
    justify-content: space-between;
    transition: all 0.3s;
}

#leftPart{
    height: 100%;
    width: 20%;
    /* background-color: yellowgreen; */
    justify-content: start;
    grid-area: leftPart;

    img{
        transition: all 0.7s;
        width: 8vw;
    }

}
#middlePart{
    height:100%;
    width: 50%;
    /* background-color: yellowgreen; */
    justify-content: center;
    gap: 5vh;
    grid-area: middlePart;
}
#rightPart{
    height: 100%;
    width: 15%;
    /* background-color: yellowgreen; */
    justify-content: end;
    grid-area: rightPart;
}
.menu{
    color:var(--txt-white-color);
    text-decoration: none;
    font-size: 2vh;
    font-weight: 500;
    cursor: pointer;
}

.MenuIcon{
    display: none;
}




@media screen and (max-width:1100px) {
    #leftPart{
        height:100%;
        width: 20%;   
        img{
            width: 100px;
        }
    }
    #middlePart{
        height:100%;
        width: 60%;
    }   
    #rightPart{
        height:100%;
        width: 20%;
    }   
}


@media screen and (max-width:980px){
    nav{
        width: 100%;
    }
    nav>div:nth-child(1){
        height: 60px;
        width:70%;
    }
    
    #leftPart{
    
        img{
            width: 80px;
        }
    
    }
    #middlePart{
        gap: 30px;
    }
    .menu{
        font-size: 14px;
    }
    
}
@media screen and (max-width:930px){
    nav>div:nth-child(1){
        height: 9vh;
        width:80%;   
    }   
}
@media screen and (max-width:800px){
    nav>div:nth-child(1){
        height: 80px;
        width:80vw;  
        padding-bottom: 10px;
        /* background-color: red; */
        display: grid;
        grid-template-areas: 
        "leftPart leftPart rightPart rightPart"
        "middlePart middlePart middlePart middlePart"
        ;
    } 
    #middlePart, #rightPart, #leftPart{
        width: 100%;
    }
    #middlePart{
        gap: 40px;
    }
    
}
@media screen and (max-width:510px){
    nav>div:nth-child(1){
        height: 50px;
        padding: 4px 0px 0px 0px;
    }
    #middlePart{
        display: none;
    }
    #rightPart{
        button{
            display: none;
        }
    }
    .MenuIcon{
        display: flex;
        font-size: 30px;
    }
}
