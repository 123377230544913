#CAP7{
    height: auto;
    width: 100%;
    margin: 5vh auto;
    padding: 5vh 0px;
    background-color: var(--alt-bg-blue-color);
    color: var(--txt-white-color);

    h2{
        margin: 0;
        font-size: 3.5vh;
    }
    p{
        margin: 1.5vh auto 5vh auto;
        font-size: 2vh;
    }
}



@media screen and (max-width:980px) {
    #CAP7{
        margin: 35px auto;
        padding: 35px 0px;
    
        h2{
            margin: 0;
            font-size: 20px;
        }
        p{
            margin: 10px auto 35px auto;
            font-size: 14px;
        }
    }
    
}

@media screen and (max-width:930px) {
    #CAP7{
        h2{
            margin: 0px auto;
            width: 80%;
        }
        p{
            width: 80%;
        }
    }
    
}