#HPP7WhyWe{
    height: auto;
    width: 100%;
    padding: 5vh 0vh;
    /* background-color: lightcyan; */
    display: none;
}
#HPP7WhyWe>div:nth-child(1){
    height: auto;
    width: 70%;
    /* background-color: red; */
    justify-content: space-between;
}

#HPP7WhyWeLeft{
    height: 65vh;
    width:35%;
    /* background-color: seagreen; */

    h1{
        color: var(--txt-blue-color);
    }

    p{
        text-align: start;
        color: var(--heading-description-color);
        margin: -2vh 0px 0px 0px;
        font-size: 2vh;
        width: 90%;
    }
}

#HPP7WhyWeRight{
    width: 64%;
    height: 65vh;
    background-color: rgb(239, 239, 239);
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 0.1vh;
}
.WhyWeCard{
    background-color: rgb(255, 255, 255);
    padding: 3vh 3vh 3vh 1vh;
    text-align: start;
    cursor: pointer;
    transition: all 0.3s;

    p{
        width: 100%;
        text-align: start;
        margin: 1vh 0vh;
        font-size: 2vh;
        line-height: 2.3vh;
        

        span{
            margin:0vh 1vh;
            transition: all 0.3s;
        }
    }
}
.WhyWeCard:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 1vh 1vh 2vh;
    transform: scale(1.001);
    p{
        span{
            margin: 0vh 1.5vh;
            color: rgb(0, 183, 255);
        }
    }
}
.WhyWeCardEven{
    padding: 3vh 0vh 3vh 3vh;
}
.WhyWeCardEven:hover{
    box-shadow: rgba(0, 0, 0, 0.15) -1vh 1vh 2vh;
}
.WhyWeCard5:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 1vh -1vh 2vh;
}
.WhyWeCard6:hover{
    box-shadow: rgba(0, 0, 0, 0.15) -1vh -1vh 2vh;
}
.WhyWeCard>div:nth-child(1){
    height: 7vh;
    /* background-color: rgb(111, 165, 67); */
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1vw;

    h5{
        margin: 0.5vh 0px 0px 0px ;
        font-size: 2.5vh;
        font-weight: 500;
        letter-spacing: 0.1vh;
        text-align: start;
        width: 80%;
    }
}
.Icon{
    height: 5.5vh;
    width: 5.5vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    border-radius: 1vh;
    color: var(--txt-blue-color);
    font-weight: 600;
    font-size: 3vh;
}


@media screen and (max-width:1025px) {
    #HPP7WhyWeRight{
        width: 60%;
        gap: 0.1vh;
        background-color: rgb(237, 237, 237);
    }
    .WhyWeCard>div:nth-child(1){
        align-items:center;
        gap: 10px;
    
        h5{
            margin: 0vh 0px 0px 0px ;
            font-size: 13px;
            letter-spacing: 1px;
        }
    }
    .WhyWeCard{        
        padding: 10px 10px 10px 0px;
    
        p{
            margin: 10px 0vh;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0.3px;
            span{
                margin:0vh 5px;
            }
        }
    }
        
    .WhyWeCardEven{
        padding: 10px 0vh 10px  10px;
    }
}
@media screen and (max-width:930px) {
    #HPP7WhyWe>div:nth-child(1){
        width: 80%;   
    }
    #HPP7WhyWeLeft{
        width: 100%;
        height: auto;
        /* background-color: red; */
        padding-bottom: 10px;
        margin-bottom: 30px;
    }
    #HPP7WhyWeRight{
        width: 100%;
    }
}
@media screen and (max-width:770px) {
    #HPP7WhyWe{
        display: flex;
    }
    #HPP7WhyWe>div:nth-child(1){
        flex-direction: column;
    }
    #HPP7WhyWeRight{
        gap: 1px 0.8px;
    }
}
@media screen and (max-width:400px) {
    #HPP7WhyWeLeft{
        p{
            width: 100%;
        }
    }
    #HPP7WhyWeRight{
        grid-template-columns: repeat(1,1fr);
        height: auto;
        background-color:transparent;
        gap: 20px;
    }
    .WhyWeCardEven, .WhyWeCard{
        padding: 10px;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .WhyWeCardEven:hover, .WhyWeCard:hover, .WhyWeCard5:hover, .WhyWeCard6:hover{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transform: none;
    }
}