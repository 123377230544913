:root {
    --bg-color: white;
    --alt-bg-color:#F7F8F9;
    --alt-bg-blue-color:#043474;
    --alt-bg-lightGreen-color:rgba(34, 197, 94, 0.05);
    --alt-bg-blue-image:linear-gradient(90deg, rgba(16,54,129,1) 0%, rgba(8,141,210,1) 100%);
    --txt-color: rgb(0, 0, 0);
    --txt-white-color:white;
    --txt-blue-color:#043474;
    --butn-bg-color:linear-gradient(90deg, rgba(16,54,129,1) 0%, rgba(8,141,210,1) 100%);
    --hero-p-color:#8896AB;
    --icon-color:#556987;
    --icon-bg-color:#0474BC;
    --heading-badge-bg-color:#DFE9F8;
    --heading-color:#043474;
    --heading-description-color:#556987;
    --bagdeALT-color:#043474;
    --badgeALT-bg-color:rgba(255, 255, 255, 0.5);
    --scrollar-shade-color:#112a4e;
    --timeline-icon-bg-color:#043474;
    --phone-icon-color:#B2B6B2;
    --spp-servicecard-color:rgba(4, 116, 188, 0.1);
}

.flex{
    display: flex;
    justify-content: center;
    align-items: center;
}




/* ------------- Basic Button Style --------------- */
.basicButton{
    padding: 1.4vh 1.4vw;
    border: 0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
    background-position: center;
    transition: all 0.2s;
    font-size: 2vh;
}

.basicButton:hover{
    transform: scale(1.05);
}
.basicButton:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: all 0s;
  }
  



.splide__pagination, .splide__pagination--ttb{
  li{
    button{
      width: 1.5vh;
      height: 1.5vh;
      margin: 0.5vh;
      /* background-color: var(--hero-p-color); */
    }
  }
}



/* ._cUP1np9gMvFQrcPftuf, ._Y8HCTxgNkwxXcG_DCXx{
    max-width: 100%;
  max-height: 100%;
}

._3efP_GeH5kyBAzqnLzL.cllbjvXCdYDt9A3te4cz{
    display: flex;
    flex: 1 1 auto;
    width: 1000px;
    min-height: 550px;
    transition: all .22s ease-out;
} */





  .slideC {
    position: relative;
    perspective: 65vw;
    transform-style: preserve-3d;
    width: 100%;
    height: 52vh;
    display: flex;
    align-items: center;
    margin: 8vh auto 0px auto;
    /* background-color: red; */
  }
  
  .slide {
    width: 40vw;
    height: 45vh;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
      visibility 500ms ease 0s ;
    position: absolute;
    top: 0;
    left: 20vw;
    /* right: 20vw; */
    border-radius: 2vh;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sliderContent {
    display: flex;
    flex-direction: column;
    color: white;
    /* padding: 30px; */
    align-items: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  .sliderContent h2 {
    margin: 2vh 0;
    text-align: center;
    color: var(--heading-color);
  }
  .sliderContent p {
    margin: 0;
    /* margin-bottom: 16px; */
    color: rgba(85, 105, 135, 1);
    font-size: 10px;
  }
  
  /* normal css  */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  
  .btns {
    /* padding-top: 100px; */
        /* margin-top: 100px; */
        position: absolute;
        left: 25vw;
        right: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2vh;
    }
  
  .btns .btn {
    cursor: pointer;
  }
  .btn{
    border-radius: 100%;
    height: 2.5vh;
    width: 2.5vh;
  }
  

@media screen and (max-width:1246px) {
    .slideC {
        perspective: 800px;
        height: 400px;
      }
    .slide {
        width: 500px;
        height: 300px;
        left: 20%;
        right: 20%;
    }
    .btns {
        margin-top:-20px;
    }
}
  
@media screen and (max-width:1050px) {
    .slideC {
        perspective: 600px;
        height: 300px;
    }
    .slide {
        width: 390px;
        height: 200px;
        left: 25%;
        right: 25%;
    }
    .btns {
        margin-top:-20px;
    }
    .sliderContent h2 {
        margin: 16px 0;
        text-align: center;
        color: var(--heading-color);
        font-size: 20px;
      }
      .sliderContent p {
        margin: 0;
        /* margin-bottom: 16px; */
        color: rgba(85, 105, 135, 1);
        font-size: 12px;
      }
}



/* ---------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------- */
@media screen and (max-width:980px) {
  .basicButton{
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
}

@media screen and (max-width:935px) {
  /* ------------------------------------------------------------------------------------------------------------- */
  /* ------------------------------------------------------------------------------------------------------------- */
  /* ------------------------------------------------------------------------------------------------------------- */
    .slideC {
        perspective: 0px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slide {
        width: 700px;
        height: 300px;
        left: 5%;
        
    }
    .sliderContent h2 {
        margin: 16px 0;
        text-align: center;
        color: var(--heading-color);
        font-size: 25px;
    }
    .sliderContent p {
        margin: 0;
        /* margin-bottom: 16px; */
        color: rgba(85, 105, 135, 1);
        font-size: 14px;
    }
}
@media screen and (max-width:835px) {

    .slide {
        width: 500px;
        height: 300px;
        left: 15%;
        right: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:770px) {
  

  
  .slide {
    width: 80%;
    height: 45vh;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
      visibility 500ms ease 0s ;
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    border-radius: 2vh;
    background-color: white;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sliderContent {
    display: flex;
    flex-direction: column;
    color: white;
    /* padding: 30px; */
    align-items: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  .sliderContent h2 {
    margin: 2vh 0;
    text-align: center;
    color: var(--heading-color);
  }
  .sliderContent p {
    margin: 0;
    /* margin-bottom: 16px; */
    color: rgba(85, 105, 135, 1);
  }
  
  /* normal css  */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
}