#HPP7WhyWe{
    height: auto;
    width: 70%;
    margin:100px auto;
    padding: 5vh 0vh 10vh 0vh;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media//Pattern.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.3s;
    /* display: none; */
    border-radius: 1vh;
}
#HPP7WhyWe>div:nth-child(1){
    height: 100%;
    width: 90%;
    /* background-color: red; */

    h1{
        text-align: center;
        width: 50%;
        margin:3vh auto;
    }
    
}
#HPP7WhyWe>div:nth-child(1)>div{
    div{
        margin: auto;
    }
}
#WhyWeCardList{
    height: auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    /* background-color: rgb(247, 247, 247); */
    margin: 5vh auto 0vh auto;
}

.WhyWeCard{
    padding: 3vh 5vh;
    /* background-color: greenyellow; */
    color: var(--txt-white-color);
    width:auto;
    height: auto;
    position: relative;

    h4{
        font-size: 3vh;
        letter-spacing: 0.1vh;
        margin: 3.5vh auto 2vh auto;
    }
    p{
        font-size: 2vh;
        letter-spacing: 0.1vh;
        margin: 0px auto;
        width: 90%;
    }

}
.Badge{
    padding: 1vh 3vh;
    background-color: var(--badgeALT-bg-color);
    border-radius: 2vh;
    font-size: 3vh;
    font-weight: 600;
    color: var(--txt-blue-color);
    width: fit-content; 
}
#WhyWeCardList>div:nth-child(1){
    border-right: 0.1px solid rgba(236, 236, 236, 0.129);
    border-bottom: 0.1px solid rgba(236, 236, 236, 0.129);
}
#WhyWeCardList>div:nth-child(2){
    /* border-left: 0.1px solid rgba(236, 236, 236, 0.129);
    border-bottom: 0.1px solid rgba(236, 236, 236, 0.129); */
}
#WhyWeCardList>div:nth-child(3){
    /* border-right: 0.1px solid rgba(236, 236, 236, 0.129);
    border-top: 0.1px solid rgba(236, 236, 236, 0.129);
    border-bottom: 0.1px solid rgba(236, 236, 236, 0.129); */
}
#WhyWeCardList>div:nth-child(4){
    border-left: 0.1px solid rgba(236, 236, 236, 0.129);
    border-top: 0.1px solid rgba(236, 236, 236, 0.129);
    border-bottom: 0.1px solid rgba(236, 236, 236, 0.129);
}
#WhyWeCardList>div:nth-child(5){
    border-right: 0.1px solid rgba(236, 236, 236, 0.129);
    border-top: 0.1px solid rgba(236, 236, 236, 0.129);
}
#WhyWeCardList>div:nth-child(6){
    /* border-left: 0.1px solid rgba(236, 236, 236, 0.129);
    border-top: 0.1px solid rgba(236, 236, 236, 0.129); */
}
.IMG1{
    position: absolute;
    /* margin: 16.5% 47.4%; */
    right: -3.5vh;
    bottom: -3.5vh;
    width: 7vh;
}
.IMG2{
    position: absolute;
    left:-3.5vh;
    top: -3vh;
    width: 7vh;
}




/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {
    #HPP7WhyWe{
        margin:0px auto 50px auto;
        padding: 40px 0vh 80px 0vh;
        display: flex;
        border-radius: 8px;

    }
    #HPP7WhyWe>div:nth-child(1){
        h1{
            margin:20px auto 0px auto;
            width: 70%;
            font-size: 20px;
            color: white;
            line-height: 23px;
        }
    }
    #WhyWeCardList{
        margin: 0px auto;
    }
    
    .WhyWeCard{
        padding:20px 30px;
    
        h4{
            font-size: 20px;
            letter-spacing: 1px;
            margin: 20px auto 10px auto;
        }
        p{
            font-size: 12px;
            letter-spacing: 1px;
        }
    
    }
    .Badge{
        padding: 10px 25px;
        border-radius: 15px;
        font-size: 20px;
        font-weight: 600;
    }
    .IMG1{
        position: absolute;
        /* margin: 16.5% 47.4%; */
        right: -30px;
        bottom: -35px;
        width: 60px;
    }
    .IMG2{
        position: absolute;
        left:-30px;
        top: -35px;
        width: 60px;
    }
    
    
}



@media screen and (max-width:930px) {
    #HPP7WhyWe{
        width: 80%;
    }
}
@media screen and (max-width:770px) {
    #HPP7WhyWe{
        display: none;
    }
}