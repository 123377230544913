.RecentBlogCardBP{
    height: auto;
    width: auto;
    /* background-color: teal; */
    text-align: start;
    transition: all 0.3s;

}
.FeaturedIMG{
    height: 25vh;
    width: 100%;
    background-color: yellow;
    border-radius: 1vh;
    transition: all 0.3s;

    img{
        height: 100%;
        width: 100%;
        border-radius: 1vh;
    }
}
.BlogMetaData{
    height: auto;
    width: 100%;
    /* background-color: lightpink; */
    margin: 1vh 0px;


    p{
        margin: 0px;
        font-size: 1.8vh;
        font-weight: 600;
        text-transform: uppercase;
        
    }
    
}




/* ------------------------ 930px ----------------------- */

@media screen and (max-width:980px) {
    .FeaturedIMG{
        height: 120px;
        border-radius:8px;
    
        img{
            border-radius: 8px;
        }
    }
    .BlogMetaData{
        margin: 7px 0px;
    
    
        p{
            font-size: 11px;            
        }
        
    }
}


/* ------------------------ 800px ----------------------- */

@media screen and (max-width:800px) {
    .FeaturedIMG{
        height: 100px;
    }
    .BlogMetaData{
        p{
            font-size: 10px;            
        }
        
    }
}


/* ------------------------ 450px ----------------------- */

@media screen and (max-width:450px) {
    .FeaturedIMG{
        height: 80px;
    }
    .BlogMetaData{
        p{
            font-size: 8px;            
        }
    }
}
@media screen and (max-width:400px) {
    .FeaturedIMG{
        height: 150px;
    }
    .BlogMetaData{
        p{
            font-size: 12px;            
        }
    }
}