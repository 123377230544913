
/*  ---------- Home Page Part 10   Blogs     ---------- */

#HPP10Blogs{
    height: 80vh;
    width: 100%;
    padding: 1vh 0px;
    /* background-color: rgba(34, 197, 94, 0.05); */
}
#HPP10Blogs>div:nth-child(1){
    height: 100%;
    width: 70vw;
    /* background-color: cadetblue; */
}
#HPP10BlogsTop{
    height: 18vh;
    width: 100%;
    /* background-color: seagreen; */
    justify-content: space-between;
}
#HPP10BlogsTopLeft{
    width: 40vw;
    height: 100%;
    /* background-color: red; */
}
#HPP10BlogsTopRight{
    height: 100%;
    width: 30vw;
    /* background-color: powderblue; */
}

#HPP10BlogsMiddle{
    height:50vh;
    width: 100%;
    /* background-color: lightblue; */
}
#HPP10BlogsBottom{
    height: 12vh;
    width: 100%;
    /* background-color: olive; */
    align-items: end;
}

.perPage4{
    display: none;
}
.perPage3{
    display: flex;
}
.perPage2{
    display: none;
}
.perPage1{
    display: none;
}

@media screen and (max-width:1246px) {
    .perPage4{
        display: none;
    }
    .perPage3{
        display: block;
    }
}
@media screen and (max-width:1000px) {
    .perPage3{
        display: none;
    }
    .perPage2{
        display: flex;
    }
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {

    #HPP10Blogs{
        height: auto;
        padding: 8px 0px;
        /* background-color: rgba(0, 255, 94, 0.913); */
    }
    #HPP10Blogs>div:nth-child(1){
        height: 100%;
        width: 70%;
        /* background-color: cadetblue; */
    }
    #HPP10BlogsTop{
        height: fit-content;
        width: 100%;
        /* background-color: seagreen; */
        justify-content: space-between;
    }
    #HPP10BlogsTopLeft{
        width: 40%;
    }
    #HPP10BlogsTopRight{
        width: 30%;
    }
    
    #HPP10BlogsMiddle{
        height:400px;
        margin-top: -10px;
    }
    #HPP10BlogsBottom{
        height: 50px;
    }
    

}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */


@media screen and (max-width:935px) {
    #HPP10Blogs>div:nth-child(1){
        width: 80%;
    }
}
@media screen and (max-width:600px) {
    .perPage2{
        display: none;
    }
    .perPage1{
        display: block;
    }
}
