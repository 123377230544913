#HWP1Hero{
    height: auto;
    width: 100%;
    margin:10vh auto 0px auto;
    padding: 5vh 0px;
    justify-content: flex-end;
    gap: 4vh;
    text-align: start;
}

#Heroleft{
    height: auto;
    width: 28%;
    padding: 5vh 0px;
    margin: -15vh 0px 0px 0px;
    position: relative;

    h1{
        margin: 0;
        font-size: 5vh;
        color: var(--heading-color);
    }
    p{
        margin: 3vh 0px 5vh 0px;
        font-size: 2vh;
        color: var(--heading-description-color);
        line-height: 2.8vh;
        width: 80%;
    }
    button{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
    }
    img{
        position: absolute;
        width: 60%;
        left: -5vh;
        bottom: -8.5vh;
    }
}



#HeroRight{
    width: 55%;
    height: 80vh;
    /* background-color: red; */
    position: relative;
}

#HeroRight>div:nth-child(1){
    height: 18vh;
    width: 18vh;
    background-color: rgba(218, 233, 255, 1);
    position: absolute;
    top: 0;
    left: 2.5vh;
    border-radius: 100vh;
}
#HeroRight>div:nth-child(2){
    width: 90%;
    height: 90%;
    border-bottom-left-radius: 30vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: url("../../Media/how-we-work-Hero.png") no-repeat center;
    background-size: cover;
}
#HeroRight>div:nth-child(3){
    height: 22vh;
    width: 22vh;
    background-color: rgba(255, 245, 219, 1);
    position: absolute;
    bottom: 0;
    right: 16vh;
    border-radius: 0vh 100vh 100vh 0vh;
}



@media screen and (max-width:1024px) {
    #HWP1Hero{
        gap: 10px
    }
    
    #Heroleft{
        width: 29%;
        
        h1{
            font-size: 4vh;
        }
        p{
            width: 95%;
        }
    }
}
@media screen and (max-width:980px) {
    #HWP1Hero{
        margin:65px auto 0px auto;
        padding: 30px 0px;
    }
    
    #Heroleft{
        padding: 30px 0px;
        margin: -100px 0px 0px 0px;
    
        h1{
            margin: 0;
            font-size: 25px;
        }
        p{
            margin: 18px 0px 30px 0px;
            font-size: 12px;
            line-height: 18px;
        }
        img{
            width: 60%;
            left: -30px;
            bottom: -40px;
        }
    }
    
    #HeroRight{
        height:530px;
    }
    
    #HeroRight>div:nth-child(1){
        height: 120px;
        width: 120px;
        top: 0px;
        left:-3px;
        border-radius: 100px;
    }
    #HeroRight>div:nth-child(2){
        border-bottom-left-radius: 200px;
    }
    #HeroRight>div:nth-child(3){
        height: 150px;
        width: 150px;
        right: 100px;
        border-radius: 0vh 100px 100px 0vh;
    }
}
@media screen and (max-width:930px) {
    #HeroRight{
        width: 60%;
    }
}


@media screen and (max-width:800px) {
    #HWP1Hero{
        margin:80px auto 0px auto;
        padding: 70px 0px 30px 0px;
    }
    #HeroRight{
        height:370px;
    }
    #HeroRight>div:nth-child(2){
        border-bottom-left-radius: 120px;
    }
    #HeroRight>div:nth-child(1){
        height: 80px;
        width: 80px;
        left:10px;
    }
    #HeroRight>div:nth-child(3){
        height: 100px;
        width: 100px;
        right: 60px;
    }
    #Heroleft{
        padding: 30px 0px;
        margin: -100px 0px 0px 0px;
        
        h1{
            font-size: 19px;
        }
        p{
            font-size: 10px;
            line-height: 15px;
        }
        img{
            left: -20px;
            bottom: -25px;
        }
    }
}
@media screen and (max-width:600px) {
    #HWP1Hero{
        margin:120px auto 0px auto;
        padding: 0px 0px;
        justify-content: center;
        background: url("../../Media/how-we-work-Hero.png") no-repeat center;
        background-size: cover;
        /* border-bottom-right-radius: 200px; */
    }
    #HeroRight{
        display: none;
    }
    #Heroleft{
        /* border-bottom-right-radius: 200px; */
        width: 100%;
        height: 100%;
        padding: 100px 0px 150px 0px;
        margin: 0px;
        background-color: rgba(0, 0, 0, 0.8);
        
        h1{
            margin: 0;
            font-size: 25px;
            width: 80%;
            margin: auto;
            color: var(--txt-white-color);
        }
        p{
            margin: 20px auto;
            font-size: 12px;
            line-height: 18px;
            width: 80%;
            color: var(--txt-white-color);
        }
        button{
            left: 10%;
            top: 20px;
            position: relative;
        }
        img{
            width: 60%;
            left: -30px;
            bottom: -40px;
            display: none;
        }
    }
}
@media screen and (max-width:515px) {
    #HWP1Hero{
        margin:80px auto 0px auto;
    }
}
@media screen and (max-width:400px) {
    #Heroleft{
        padding: 80px 0px 100px 0px;
    }
}