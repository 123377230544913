.SearviceCard{
    padding: 2vh 1vw;
    height: 18vh;
    width: 19.9vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 2vh;
    background-color: white;
    text-align: start;
    cursor: pointer;
    transition: all 0.3s;

    h2{
        font-size: 2vh;
    }

    p{
        height:40%;
        padding: 1vh 0px;
        /* background-color: yellow; */
        margin:2vh 0px;
        font-size: 1.5vh;
        line-height: 2vh;
        letter-spacing: 0.1vh;
    }


    span{
        font-size: 1.6vh;
        /* background-color: aquamarine; */
    }

    
}

.SearviceCard:hover{
    transform: scale(1.07);
    border-top: 0.4vh solid white;
}

.SearviceCard>div:nth-child(1){
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 5.5vh;
    /* background-color: red; */
    gap: 1vh;

    h2{
        margin: 0px;
    }

   
}

.icon{
    width: fit-content;
    padding: 1.5vh;
    margin: 0px;
    border-radius: 1.2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 183, 255);
    font-size: 2.5vh;
}



@media screen and (max-width:1100px) {
    .SearviceCard{
        height: 18vh;
        width: 25vw;
    }
}
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
@media screen and (max-width: 980px) {
    .SearviceCard{
        padding: 15px;
        height: auto;
        width: auto;
        border-radius: 20px;
    
        h2{
            font-size: 18px;
        }
    
        p{
            padding: 8px 0px;
            /* background-color: yellow; */
            margin:15px 0px;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0px;
        }
    
    
        span{
            font-size: 15px;
            /* background-color: aquamarine; */
        }
    
        
    }
    
    .SearviceCard:hover{
        border-top: 4px solid white;
    }
    
    .SearviceCard>div:nth-child(1){
        height: auto;
        /* background-color: red; */
        gap: 10px;
    
       
    }
    
    .icon{
        padding: 10px;
        margin: 0px;
        border-radius: 10px;
        font-size: 18px;
    }
    
    
}
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */


@media screen and (max-width:935px) {
    .SearviceCard{
        height: auto;
        width: auto;
    
        h2{
            font-size: 16px;
        }
    
        p{
            /* background-color: yellow; */
            font-size: 13px;
            line-height: 15px;
        }
 
    
        
    }
    
    .SearviceCard:hover{
        border-top: 4px solid white;
    }
    
    .icon{
        font-size: 15px;
    }
}

@media screen and (max-width:400px) {
    .SearviceCard>div:nth-child(1){
        align-items: flex-start;
        /* height: 40%; */

        h2{
            line-height: 15px;
            font-size: 15px;
            margin-top: 7px;
        }
    }   
    .SearviceCard{
        padding: 15px 10px;
        width: 90%;
    }
}