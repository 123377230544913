#Footer{
    height: auto;
    width: 100%;
    background-color: var(--alt-bg-blue-color);
    padding: 5vh 0px 10vh 0px;
}
#Footer>div:nth-child(1){
    height: auto;
    width: 70vw;
    transition: all 0.3s;
    display: grid;
    grid-template-areas: 
    "CompanyProfile services expertise technologies  company"
    "CompanyProfile services expertise technologies  company"
    "CompanyProfile services expertise dataAI  dataAI"
    "CompanyProfile careers cloudDevops dataAI  dataAI"
    ;
    gap: 0vh 9vh;

}
#Footer>div:nth-child(1)>div{
    height: 100%;
    text-align: start;
    padding-top: 5vh;
    /* width: 19%; */
    /* background-color: cornflowerblue; */
    color: var(--txt-white-color);

    p{
        font-size: 1.5vh;
        line-height: 2.2vh;
        transition: all 0.3s;
    }

    h4{
        font-size: 2vh;
        letter-spacing: 0.1vh;
    }
    
}

#CompanyProfile{
    /* width: 25%; */
    /* background-color: cornflowerblue;    */
    grid-area: CompanyProfile; 

    img{
        width: 8vw;
        margin-left: -1vh;
    }
}
#CompanyProfile>div:nth-child(2){
    height: auto;
    width: 100%;
    /* background-color: hotpink; */
    padding: 2vh 0px 0px 0px;
    
    h4{
        margin: 0px;
    }
    label{
        margin: -1vh 0px 3vh 0px;
        font-size: 2vh;
        justify-content: start;
        align-items: center;
        gap: 1vh;
    }
}
#CompanyProfile>div:nth-child(3){
    height: auto;
    width: 100%;
    /* background-color: teal; */
    margin-top: 2vh;
    justify-content: flex-start;
    gap: 2vh;
}
.NavLinkIcon{
    color: var(--txt-white-color);
    text-decoration: none;
    height: 4vh;
    width: 4vh;
    background-color: var(--icon-bg-color);
    border-radius: 100%;
    font-size:2vh;
}
.NavLinkIcon:hover{
    background-color: white;
    color: var(--txt-blue-color);
    transition: all 0.5s;
}
.NavLink{
    font-size: 1.6vh;
    color: var(--txt-white-color);
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}
.NavLink:hover{
    color: skyblue;
}

#Services{
    height:auto;
    /* width: 20%; */
    /* background-color: lightblue; */
    padding-top: 2vh;
    grid-area: services;

    div{
        height: auto;
        width: 100%;
        /* background-color: red; */
        margin-top: 2vh;
        display: grid;
        gap: 1.8vh;
    }
}
#Expertise{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: expertise;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-top: 2vh;
        display: grid;
        gap: 1.8vh;
    }
}
#Technologies{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: technologies;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-top: 2vh;
        display: grid;
        gap: 1.8vh;
    }
}
#Careers{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: careers;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-bottom: 0vh;
        display: flex;
        flex-direction: column;
        
        gap: 1.8vh;
    }
}
#Company{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: company;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-bottom: 0vh;
        display: flex;
        flex-direction: column;
        
        gap: 1.8vh;
    }
}
#cloudDevops{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: cloudDevops;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-bottom: 0vh;
        display: flex;
        flex-direction: column;
        
        gap: 1.8vh;
    }
}
#dataAI{
    height: auto;
    /* width: 15%; */
    /* background-color: lightblue; */
    grid-area: dataAI;
    div{
        height:auto;
        width: 100%;
        /* background-color: red; */
        margin-bottom: 0vh;
        display: flex;
        flex-direction: column;
        
        gap: 1.8vh;
    }
}







@media screen and (max-width:1246px) {
    #CompanyProfile>div:nth-child(3){
        gap: 10px;
    }
    .NavLink{
        font-size: 13px;
        color: var(--txt-white-color);
        text-decoration: none;
        width: fit-content;
    }
    .NavLinkIcon{
        color: var(--txt-white-color);
        text-decoration: none;
        height: 35px;
        width: 35px;
        background-color: var(--icon-bg-color);
        border-radius: 100%;
        font-size: 18px;
    }
    #CompanyProfile{
        img{
            width: 100px;
        }
    } 
    #Footer>div:nth-child(1){
        gap: 0px 4vh;
    }
}


@media screen and (max-width:1100px) {
    #Footer>div:nth-child(1){
        grid-template-areas: 
        "CompanyProfile services expertise technologies "
        "CompanyProfile services dataAI company"
        "CompanyProfile services dataAI   company"
        "careers cloudDevops dataAI   company"
        ;    
    }
}


/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */

@media screen and (max-width:980px) {
    #Footer{
        padding: 30px 0px 50px 0px;
        bottom: 0;
    }
    #Footer>div:nth-child(1){
        width: 70%;
    }
    #Footer>div:nth-child(1)>div{
        padding-top: 30px;
    
        p{
            font-size: 12px;
            line-height: 15px;
        }
    
        h4{
            font-size: 17px;
            letter-spacing: 1px;
        }
        
    }
    
    #CompanyProfile{
        img{
            width: 80px;
            margin-left: -7px;
        }
    }
    #CompanyProfile>div:nth-child(2){
        padding: 15px 0px 0px 0px;
        
        h4{
            margin: 0px;
        }
        label{
            margin: -7px 0px 20px 0px;
            font-size: 15px;
            gap: 7px;
        }
    }
    #CompanyProfile>div:nth-child(3){
        margin-top: 14px;
        gap: 10px;
    }
    .NavLinkIcon{
        height: 30px;
        width: 30px;
        font-size:13px;
    } 
    .NavLink{
        font-size: 11px;
    }
    .NavLink:hover{
        margin-left: 7px;
    }
    
    #Services{
        padding-top: 14px;
    
        div{
            margin-top: 13px;
            gap: 10px;
        }
    }
    #Expertise{
        div{
            margin-top: 13px;
            gap: 10px;
        }
    }
    #Technologies{
        div{
            margin-top: 13px;
            gap: 10px;
        }
    }
    #Careers{
        div{
            margin-top: 13px;
            gap: 10px;
        }
    }

    
#Company{
    div{
        margin-top: 13px;
        gap: 10px;
    }
}
#cloudDevops{
    div{
        margin-top: 13px;
        gap: 10px;
    }
}
#dataAI{
    div{
        margin-top: 13px;
        gap: 10px;
    }
}

    
    

}

/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */


@media screen and (max-width:930px) {
    #Footer>div:nth-child(1){
        width: 80%;
        
    }
}
@media screen and (max-width:800px) {
    #CompanyProfile{
        width: auto;
    }
    #Services{
        width: auto;
    }
    #Expertise{
        width: auto;
    }
    #Technologies{
        width: auto;
    }
    #Careers{
        width: auto;
    }
    #Footer>div:nth-child(1){
        display: grid;
        grid-template-areas: 
        "CompanyProfile services expertise"
        "CompanyProfile services dataAI"
        "technologies company careers"
        "technologies company cloudDevops"
        ;   
        gap:0px 20px;
    }
}
@media screen and (max-width:650px) {
    #Footer>div:nth-child(1){
        display: grid;
        grid-template-areas: 
        "CompanyProfile services services"
        "expertise technologies company"
        "careers cloudDevops dataAI"
        ;   
        gap: 0px 20px;
    }
}
@media screen and (max-width:550px) {
    #Footer>div:nth-child(1){
        grid-template-areas: 
        "CompanyProfile services"
        "expertise technologies"
        "careers cloudDevops "
        "company dataAI "
        ;   
        gap: 0px 20px;
    }
}
@media screen and (max-width:450px) {
    #Footer>div:nth-child(1){
        gap: 0px 20px;
    }
    
    
}

@media screen and (max-width:350px) {
    #Footer>div:nth-child(1){
        display: flex;
        flex-direction: column;
        gap: 0px 0px;
    }
    #Footer>div:nth-child(1){
        grid-template-areas: 
        "CompanyProfile CompanyProfile"
        "CompanyProfile CompanyProfile"
        "services services"
        "services services"
        "expertise expertise"
        "expertise expertise"
        "technologies technologies"
        "technologies technologies"
        "careers careers"
        "careers careers"
        ;
    }
    #Careers{
        display: block;
    }
    
}