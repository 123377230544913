#CPP2Help{
    height: auto;
    width: 70%;
    margin: auto;
    padding: 15vh 0px;
    /* background-color: cadetblue; */
    justify-content: space-between;
    transition: all 0.3s;
}
#CPP2HelpLeft{
    height: auto;
    width: 45%;
    /* background-color: yellowgreen; */
    transition: all 0.3s;

    img{
        width: 100%;
        height: auto;
    }
}
#CPP2HelpRight{
    transition: all 0.3s;
    height: auto;
    width: 50%;
    /* background-color: yellowgreen; */
}
#CPP2HelpRight>div:nth-child(1){
    transition: all 0.3s;
    height: auto;
    width: 90%;
    /* background-color: red; */
    text-align: start;
    button{
        margin: 0px;
    }
}
#CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
    transition: all 0.3s;
    padding: 0px;
    div{
        padding: 0px;
        background-color: transparent;
        color: var(--heading-description-color);
    }
    h1{
        width: 60%;
    }
    p{
        width: 65%;
        line-height: 2.5vh;
        margin-top: 3vh;
    }

    
}

#contactList{
    transition: all 0.3s;
    height: auto;
    width: auto;
    /* background-color:greenyellow; */
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin: 5vh 0px 8vh 0px;
    gap: 3vh;
}
.contactCard{
    transition: all 0.3s;
    transition: all 0.3s;
    height: auto;
    width: auto;;
    text-align: start;
    justify-content: flex-start;
    gap: 2vh;
}
.contactCard>div:nth-child(1){
    transition: all 0.3s;
    height: 5vh;
    width: 5vh;
    color: var(--icon-bg-color);
    border: 2px solid var(--icon-bg-color);
    font-size: 2.5vh;
}
.contactCard>div:nth-child(2){
    transition: all 0.3s;
    h4{
        margin: 0px;
        transition: all 0.3s;
        color: var(--heading-color);
        font-size: 2vh;
    }
    p{
        margin: 0px;
        transition: all 0.3s;
        color: var(--heading-description-color);
        margin: 0.5vh 0px;
        font-size: 1.6vh;
    }

    
}






@media screen and (max-width:1440px) {
    #CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
        h1{
            width:100%;
        }
        p{
            width: 100%;
        }
    }
}




@media screen and (max-width:1024px) {
    #CPP2HelpLeft{
        height: auto;
        width: 50%;
        /* background-color: yellowgreen; */
        
        img{
            width: 100%;
            height: auto;
        }
    }
    #CPP2HelpRight{
        height: auto;
        width: 45%;
    }
    #CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
        p{
            margin-top: 1vh;
        }
    }
    #contactList{
        margin: 3vh 0px 5vh 0px;
        gap: 2vh;
    }
}





@media screen and (max-width:980px) {
    #CPP2Help{
        padding: 100px 0px;
    }
    #CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
        h1{
            font-size: 23px;
        }
        p{
            line-height: 18px;
            margin-top: 12px;
            font-size: 14px;
        }
    }
    
    #contactList{
        margin: 25px 0px 30px 0px;
        gap: 15px;
    }
    .contactCard{
        gap: 15px;
    }
    .contactCard>div:nth-child(1){
        height: 35px;
        width: 35px;
        font-size: 16px;
    }
    .contactCard>div:nth-child(2){
        h4{
            font-size: 16px;
        }
        p{
            margin: 3px 0px;
            font-size: 10px;
        } 
    }
    
    
}




@media screen and (max-width:930px) {
    #CPP2Help{
        width: 80%;
    }
}





@media screen and (max-width:768px) {
    
    #CPP2HelpLeft{
        display: none;
    }
    #CPP2HelpRight{
        width: 100%;
    }
    #CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
        
        h1{
            font-size: 25px;
            margin-top: 10px;
        }
        p{
            line-height: 22px;
            margin-top: 12px;
            font-size: 16px;
        }
    }
    .contactCard{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    #contactList{
        margin: 35px 0px 45px 0px;
        gap: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .contactCard>div:nth-child(1){
        height: 45px;
        width: 45px;
        font-size: 20px;
    }
    .contactCard>div:nth-child(2){
        
        h4{
            font-size: 20px;
        }
        p{
            font-size: 14px;
        } 
    }
    #CPP2HelpRight>div:nth-child(1){
        width: 100%;
    }
}
@media screen and (max-width:668px) {
    #CPP2HelpRight>div:nth-child(1)>div:nth-child(1){
        
        h1{
            font-size: 18px;
            margin-top: 10px;
        }
        p{
            line-height: 16px;
            margin-top: 12px;
            font-size: 12px;
        }
    }
    #contactList{
        margin: 25px 0px 30px 0px;
        gap: 10px;
    }
    .contactCard>div:nth-child(1){
        height: 30px;
        width: 30px;
        font-size: 15px;
    }
    .contactCard>div:nth-child(2){
        
        h4{
            font-size: 15px;
        }
        p{
            font-size: 10px;
        } 
    }
}


@media screen and (max-width:500px) {
    #CPP2Help{
        padding: 70px 0px;
    }
    #contactList{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 40px 0px;
    }
}
@media screen and (max-width:400px) {
    #contactList{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px 0px;
    }
    .contactCard{
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
}