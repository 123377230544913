#BPP3Help{
    height: auto;
    width: 70%;
    margin:5vh auto;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media/Pattern.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10vh 0px;
    border-radius: 1vh;
    color:var(--txt-white-color);
    text-align: start;
    transition: all 0.3s;
}
#BPP3Help>div{
    height: auto;
    width: 80%;
    margin: auto;
    justify-content: space-between;

    h1{
        width: 50%;
        font-size: 4vh;
    }
}

#Butns{
    width: 40%;
    gap: 3vh;
}








@media screen and (max-width:1200px) {
    #BPP3Help{
        margin:40px auto;
        background-repeat: no-repeat;
        padding: 70px 0px;
    }
    #BPP3Help>div{
    
        h1{
            width: 50%;
            font-size: 25px;
        }
    }
    
    #Butns{
        width: 45%;
        gap: 20px;
    }
}

@media screen and (max-width:1024px) {
    #BPP3Help{
        margin:40px auto;
        background-repeat: no-repeat;
        padding: 50px 0px;
    }
    #BPP3Help>div{
    
        h1{
            width: 50%;
            font-size: 20px;
        }
    }
    
    #Butns{
        width: 50%;
        gap: 20px;
    }
}

@media screen and (max-width:930px) {
    #BPP3Help{
        height: auto;
        width: 80%;
    }
    #BPP3Help>div{
    
        h1{
            width: 40%;
        }
    }
    
    #Butns{
        width: 50%;
        gap: 20px;

        button{
            font-size: 12px;
        }
    }
}


@media screen and (max-width:600px) {
    #BPP3Help{
        height: auto;
        width: 80%;
    }
    #BPP3Help>div{
    
        h1{
            width: 40%;
            font-size: 14px;
        }
    }
    
    #Butns{
        width: 50%;
        gap: 20px;

        button{
            font-size:8px;
        }
    }
}


@media screen and (max-width:426px) {
    #BPP3Help{
        height: auto;
        width: 80%;
        padding: 30px 0px;
        margin:0px auto;
    }
    #BPP3Help>div{
        display: block;
        width: 80%;
    
        h1{
            width: 100%;
            font-size: 14px;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    #Butns{
        width: 100%;
        gap: 20px;

        button{
            font-size:10px;
        }
    }
}