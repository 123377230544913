#HPP8WebStories{
    height: auto;
    width: 100%;
    padding: 10vh 0vw;
    background-color: rgba(34, 197, 94, 0.05);
}
#HPP8WebStories>div:nth-child(1){
    width: 70vw;
    height: auto;
    margin: auto;
    /* background-color: red; */
}
#storiesContent{
    height: 70vh;
    width: 100%;
    /* background-color: hotpink; */
    display: grid;
    grid-template-areas: 
    "Stories1 Stories1 Stories1 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories1 Stories1 Stories1 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories1 Stories1 Stories1 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories1 Stories1 Stories1 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories4 Stories4 Stories4 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories4 Stories4 Stories4 Stories2 Stories2 Stories3 Stories3 Stories3"
    "Stories4 Stories4 Stories4 Stories2 Stories2 Stories3 Stories3 Stories3"
    ;
    gap: 3vh;
}
#storiesContent>div{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1vh;
}
#Stories1{
    grid-area: Stories1;
    /* background-color: gray; */
    justify-content: space-between;
    position: relative;
    width: 25vw;

    div{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 1vh;
    }

}
#Stories1>div:nth-child(1){
    height:54%;
    width: 35%;
    /* background-color: yellow; */
    background-image: url("../../Media/Stories1.png");
    margin-top: 7vh;
}
#Stories1>div:nth-child(2){
    height:90%;
    width:58%;
    /* background-color: yellow; */
    background-image: url("../../Media/Stories2.png");
}
#Stories1>img:nth-child(3){
    position: absolute;
    top: -2.6vh;
    right: -6.6vh;
    height: 12vh;
    width: 12vh;
}

#Stories2{
    grid-area: Stories2;
    /* background-color: green; */
    align-items: flex-end;
    width: 20vw;
    margin: 0px 2vh;
   
}
#Stories2>div:nth-child(1){
    height: 50vh;
    width: 19vw;
    background-image: url("../../Media/Stories3.png");
    margin-bottom: 5vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1vh;
}

#Stories3{
    grid-area: Stories3;
    /* background-color: yellowgreen; */
    width: 20vw;
    /* height: 70vh; */
    justify-content: flex-end;
    align-items: flex-end;
    
}
#Stories3>div:nth-child(1){
    background-image: url("../../Media/iPhoneStories.png");
    height: 83vh;
    width:19.5vw;
    /* background-size: contain; */
    margin-top: -13vh;
    /* background-color: antiquewhite; */
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1vh;
}
#ActiveStorie{
    height: 90%;
    width: 90.6%;
    /* background-color: rgba(255, 0, 0, 0.443); */
    margin-bottom:1.9vh;
    margin-left:-0.1vh;
    border-bottom-left-radius: 4.4vh;
    border-bottom-right-radius: 4.4vh;
    /* border: 1px solid white; */
}
#ActiveStorie>video{
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 4.4vh;
    border-bottom-right-radius: 4.4vh;
    /* border: 1px solid  red; */
    object-fit: cover;
}



#Stories4{
    grid-area: Stories4;
    /* background-color: tomato; */
    width: 25vw;
    gap: 2vh;
    justify-content: flex-start;
    align-items: flex-end;

    button{
        font-size: 4vh;
        background-color: transparent;
        border: 0px;
        color: var(--txt-blue-color);
        cursor: pointer;
    }
}