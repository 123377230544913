.Heading{
    width: 100vw;
    height: auto;
    padding:5vh 0px;
    /* background-color: teal; */
    text-align: start;
    margin: auto;
    transition: all 0.3s;
}

.badge, .badgeALT{
    transition: all 0.3s;
    padding: 0.5vh 0.5vw;
    background-color: var(--heading-badge-bg-color);
    width: fit-content;
    border-radius: 2vh;
    text-transform: uppercase;
    font-size: 1.2vh;
    font-weight: 600;
    color: var(--heading-color);
}
.badgeALT{
    background-color: var(--badgeALT-bg-color);
    color: var(--bagdeALT-color);
}

.heading, .headingALT{
    transition: all 0.3s;
    margin: 2vh 0px;
    color: var(--heading-color);
    font-size: 4vh;
}
.headingALT{
    color: var(--txt-white-color);
}

.description, .descriptionALT, .descriptionClients{
    margin: 0;
    width: 40vw;
    transition: all 0.3s;
    color: var(--heading-description-color);
    font-size: 2vh;
}
.descriptionALT{
    width: 50vw;
}
.descriptionShort{
    width: 18vw;
}



/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
@media screen and (max-width: 980px) {

    .badge, .badgeALT{
        padding: 8px 8px;
        border-radius: 20px;
        font-size: 10px;
    }
        
    .heading, .headingALT{
        margin:25px 0px 10px 0px;
        color: var(--heading-color);
        font-size: 25px;
    }

    .description, .descriptionALT, .descriptionClients{
        font-size: 14px;
        height: auto;
    }
    .descriptionALT{
        width: 100%;
    }
    .descriptionShort{
        width: 100%;
    }
    
}
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */


@media screen and (max-width:935px) {
    .description, .descriptionALT, .descriptionClients{
        width: 100%;
    }
}
