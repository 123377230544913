#APP4Mission{
    height: auto;
    width: 100%;
    background-image: url("../../Media/PatternLight.png");
    margin: auto;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8vh 0vh 10vh 0vh;
}
#APP4Mission>div:nth-child(1){
    width: 70%;
    height: auto;
    margin: auto;
    text-align: start;
    position: relative;

    p{
        width: 40%;
    }

    img{
        width: 15vh;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}


@media screen and (max-width:1441px) {
    #APP4Mission{
        padding: 70px 0px 80px 0px;
    }
    #APP4Mission>div:nth-child(1){
        img{
            width: 120px;
        }
    }
}
@media screen and (max-width:930px){
    #APP4Mission>div:nth-child(1){
        width: 80%;
        p{
            width: 50%;
        }
    }
}
@media screen and (max-width:770px){
    #APP4Mission{
        padding: 50px 0px 60px 0px;
    }
    #APP4Mission>div:nth-child(1){
        p{
            width: 60%;
        }
        img{
            width: 80px;
        }
    }
}
@media screen and (max-width:400px){
    #APP4Mission>div:nth-child(1){
        p{
            width: 100%;
        }
        img{
            display: none;
        }
    }
}