

#HPP4Expertise{
    padding: 0vh 0vh 15vh 0vh;
    height: auto;
    margin: auto;
    width: 100%;
    background-color: var(--alt-bg-color);
    background-image: url("../../Media/PatternLight.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
#HPP4Expertise>div:nth-child(1){
    height: auto;
    width: 70vw;
    /* background-color: teal; */
}

#expBottom{
    height: auto;
    width: 100%;
    justify-content: space-between;
    /* background-color:indianred; */
    margin-top: 4vh;
    align-items: start;
}
#expoBottomLeft{
    height: 100%;
    width: 43%;
    /* background-color: lightblue; */
}
#expertiseCardList{
    height: 80%;
    width: 100%;
    /* background-color: red; */
}
.expertiseCard{
    height:15vh;
    width: 28vw;
    background-color: whitesmoke;
    margin: 0px 0px 2vh 0px;
    justify-content: start;
    align-items: start;
    gap: 2vh;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2vh;
    border-radius: 1.7vh;
}
.expertiseCard>div:nth-child(1){
    height: min-content;
    width: min-content;
    padding: 2vh;
    font-size: 2.7vh;
    border-radius: 1vh;
}
.expertiseCard>div:nth-child(2){
    width: auto;
    height: auto;
    text-align: start;
    
    h3{
        margin: 0px auto 1vh auto;
        font-size: 2.5vh;
    }
    p{
        margin: 0;
        font-size: 1.8vh;
        width: 80%;
    }
}
#ExploreButton{
    height: 10vh;
    width: 100%;
    /* background-color:yellow; */
    align-items: flex-end;
}

#expoBottomRight{
    height:70vh;
    width: 35vw;
    /* background-color: seagreen; */
    border-radius: 2.5vh;
    background-image:url("../../Media//CoreExpertise.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -2vh;
    position: relative;

    div{
        height: auto;
        width: auto;
        position: absolute;
        img{
            width: 6vw;
        }
    }

}
#expoBottomRight>div:nth-child(2){
    top: -3vh;
    left: -1vw;
    width: 4vw;

}
#expoBottomRight>div:nth-child(1){
    bottom: -4vh;
    right: -1vw;
    width: 4vw;
}






/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
@media screen and (max-width:980px) {
    
    
    
    #HPP4Expertise{
        padding: 0vh 0vh 100px 0vh;
    }
    #HPP4Expertise>div:nth-child(1){
        width: 70%;
    }
    #expBottom{
        margin-top: 0px;
    }
    .expertiseCard{
        height:auto;
        width: auto;
        margin: 0px 0px 15px 0px;
        gap: 15px;
        padding: 15px;
        border-radius: 14px;
    }
    .expertiseCard>div:nth-child(1){
        padding: 15px;
        font-size: 20px;
        border-radius: 10px;
    }
    .expertiseCard>div:nth-child(2){
        
        h3{
            margin: 0px auto 8px auto;
            font-size: 20px;
        }
        p{
            margin: 0;
            font-size: 14px;
            width: 100%;
            line-height: 22px;
        }
    }
    #ExploreButton{
        height: 70px;
    }
    
    #expoBottomRight{
        height:600px;
        width: 50%;
        border-radius: 20px;
        margin-top: 20px;

        div{
            img{
                width: 60px;
            }
        }

    }
    #expoBottomRight>div:nth-child(2){
        top: -30px;
        left: -20px;
        width: auto;
    }
    #expoBottomRight>div:nth-child(1){
        bottom: -20px;
        right: -20px;
        width: auto;
    }

    
}
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */


@media screen and (max-width:935px) {
    #HPP4Expertise>div:nth-child(1){
        height: auto;
        width: 80%;
        /* background-color: teal; */
    }
    
    #expoBottomLeft{
        height: 100%;
        width: 45%;
        /* background-color: lightblue; */
    }
    
}
@media screen and (max-width:730px) {

    #expoBottomRight{
        display: none;
    }

    #expoBottomLeft{
        height: 100%;
        width: 100%;
        /* background-color: lightblue; */
    }
}
@media screen and (max-width:430px) {
    .expertiseCard{
        height:auto;
        width: 93%;

        p{
            line-height: 15px;
            width: 100%;
        }
    }
}