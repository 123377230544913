#APP2About{
    height: 120vh;
    width: 100%;
    /* margin-bottom: 10vh; */
    /* background-color: rgb(192, 177, 177); */
}

#PhotoGrid{
    height: 60%;
    width: 70vw;
    /* background-color: yellow; */
    margin: auto;
    display: grid;
    margin-top: -15vh;
    gap: 6vh;
    grid-template-areas:
    "div1 div3 div4"
    "div2 div3 div5"
    ;
}
#PhotoGrid>div{
    /* background-color: red; */
    border-radius: 3vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.div1{
    grid-area: div1;
    background-image: url("../../Media/APP2About-div1.png");
}
.div2{
    grid-area: div2;
    background-image: url("../../Media/APP2About-div2.png");
}
.div3{
    grid-area: div3;
    background-image: url("../../Media/APP2About-div3.png");
}
.div4{
    grid-area: div4;
    background-image: url("../../Media/APP2About-div4.png");
}
.div5{
    grid-area: div5;
    background-image: url("../../Media/APP2About-div5.png");
}


#AboutContent{
    height: 35%;
    width: 70vw;
    /* background-color: yellowgreen; */
    margin:6vh auto;
    text-align: start;



    p{
        margin: 0px;
        width: 65%;
        color: var(--heading-description-color);
        line-height: 2.5vh;
        font-size: 2vh;
    }
}


@media screen and (max-width:1441px) {
    #APP2About{
        height: 950px;
        width: 100%;
        /* background-color: red; */
    }
    #PhotoGrid{
        height: 60%;
        width: 70%;
        margin-top: -100px;
        gap: 25px;
    }
    #PhotoGrid>div{
        border-radius: 20px;
    }
    #AboutContent{
        height: auto;
        width: 70%;
        margin:50px auto 30px auto;

        p{
            margin: 0px;
            width: 65%;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
@media screen and (max-width:1025px) {
    #APP2About{
        height: 750px;
    }
    #PhotoGrid{
        height: 55%;
    }
    #AboutContent{
        p{
            width: 80%
        }
    }
}
@media screen and (max-width:980px) {
    #APP2About{
        height: auto;
    }
    #PhotoGrid{
        height: 350px;
    }
    #AboutContent{
        margin: 20px auto 50px auto ;

        p{
            width: 80%;
            margin: 0;
        }
    }
    #AboutContent>div:nth-child(1){
        padding-bottom: 18px;
    }
}
@media screen and (max-width:930px) {
    #APP2About{
        height: 750px;
    }
    #PhotoGrid{
        height: 50%;
        width: 80%;
    }
    #AboutContent{
        height: auto;
        width: 80%;
        margin:50px auto 30px auto;

        p{
            width: 100%
        }
    }
}
@media screen and (max-width:600px) {
    #APP2About{
        height: auto;
        padding-bottom: 30px;
    }
    
    #PhotoGrid{
        height: 200px;
        margin-top: -60px;
        gap: 10px;
        div{
            border-radius: 10px;
        }
    }
    #AboutContent{
        height: auto;
    }
}
@media screen and (max-width:400px) {
    #APP2About{
        height: auto;
        padding-bottom: 30px;
    }
    
    #PhotoGrid{
        height: 150px;
        margin-top: -60px;
    }
    #AboutContent{
        height: auto;
        p{
            width: 95%;
            font-size: 12px;
            line-height: 14px;
        }
    }
}