#CPP4Booking{
    height: 85vh;
    width: 70%;
    margin: 10vh auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 2vh;
    justify-content: space-between;
}
#CPP4BookingLeft{
    height: 100%;
    width: 40%;
    border-top-left-radius: 1.9vh;
    border-bottom-left-radius: 1.9vh;
    background-color:whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


#CPP4BookingRight{
    height: 100%;
    width: 57%;
    border-top-right-radius: 1.9vh;
    border-bottom-right-radius: 1.9vh;
    /* background-color: red; */
}

#Tag{
    height: 18vh;
    width: 80%;
    padding-right: 5vh;
    margin: 0px 0px 7vh auto;
    background-color: var(--alt-bg-blue-color);
    border-top-left-radius: 1vh;
    border-bottom-left-radius: 1vh;
    text-align: start;
    justify-content: flex-end;
    display: none;
}
#Tag>div{
    width: 80%;
    height: auto;
    color: var(--txt-white-color);
    h3{
        margin: 0px;
    }
    p{
        margin: 1.5vh 0px;
        font-size: 1.8vh;
        line-height: 2.2vh;
        width: 80%;
    }
    span{
        margin: 0px;
        font-size: 1.5vh;
        justify-content: flex-start;
        gap: 0.5vh;
        letter-spacing: 0.1vh;
    }
}
#Icon{
    font-size: 2vh;
}
#Calendely{
    height: 64.7%;
    width: 100%;
    background-color: white;
    border-bottom-left-radius: 1.9vh;
}

#CalendelySCH{
    height: 85vh;
    border-bottom-right-radius: 1.9vh;
    border-top-right-radius: 1.9vh;
    margin: 0px;
    width: 100%;
}


#LeftTop{
    height:auto;
    width: 70%;
    /* background-color: red; */
    border-bottom: 1px solid rgb(214, 214, 214);
    padding: 5vh 1vh;
    justify-content: flex-start;
    gap: 0px 6vh;
    
}
#LeftTop>div:nth-child(1){
    img{
        border-radius: 100%;
        width:10vh;
        height:10vh;
    }
    h4{
        margin: 1vh;
        color: var(--heading-description-color);
        font-weight: 500;
        font-size: 2.5vh;
    }
}
#LeftTop>div:nth-child(2){
    text-align: start;
    h3{
        margin: 0px;
        font-size: 2.4vh;
    }
    p{
        margin: 0px;
        color: var(--heading-description-color);
        font-size: 1.8vh;
    }
    span{
        margin: 3vh auto 1vh auto;
        justify-content: flex-start;
        gap: 1vh;
    }
}
#LeftBottom{
    height:auto;
    width: 70%;
    /* background-color: red; */
    padding: 4vh 1vh;
    text-align: start;

    ul{
        margin: 4vh 0px;

        li{
            list-style: "✓";
            gap: 1vh;
            padding: 0.5vh 2vh;
            margin-left: -4vh;
            font-size: 2vh;
        }
        li::marker {
            color:var(--icon-bg-color);
            font-weight: 800;
        }
    }
}
#LeftBottom>p:nth-child(1){
    font-size: 2vh;
    margin: 0px;
}
#LeftBottom>p:nth-child(3){
    font-size: 1.8vh;
    margin: 0px;
    color: var(--heading-description-color);
}


@media screen and (max-width:1246px) {
    #LeftTop{
        gap: 0px 5vh;   
    }
    #LeftTop>div:nth-child(1){
        h4{
            font-size: 2vh;
        }
    }
    #LeftTop>div:nth-child(2){
        text-align: start;
        h3{
            margin: 0px;
            font-size: 2vh;
        }
        p{
            margin: 0px;
            color: var(--heading-description-color);
            font-size: 1.5vh;
        }
        span{
            margin: 3vh auto 1vh auto;
            justify-content: flex-start;
            gap: 1vh;
            font-size: 1.8vh;
        }
    }
}
@media screen and (max-width:1024px) {
    #CPP4BookingLeft{
        height: 100%;
        width: 45%;
    }
    
    
    #CPP4BookingRight{
        width: 50%;
    }
}
@media screen and (max-width:980px) {
    #CPP4Booking{
        height: 550px;
        margin: 70px auto;
        border-radius: 12.5px;
        
    }
    #CPP4BookingLeft{
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    
    
    #CPP4BookingRight{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        /* background-color: red; */
    }
    
    
    #Calendely{
        border-bottom-left-radius: 1.9vh;
    }
    
    #CalendelySCH{
        height: 550px;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
    }
    
    
    #LeftTop{
        /* background-color: red; */
        padding: 30px 6px;
        gap: 0px 40px;
        
    }
    #LeftTop>div:nth-child(1){
        img{
            width:80px;
            height:80px;
        }
        h4{
            margin: 6px 0px;
            font-size: 16px;
        }
    }
    #LeftTop>div:nth-child(2){
        h3{
            font-size: 18px;
        }
        p{
            font-size: 12px;
        }
        span{
            margin: 18px auto 6px auto;
            font-size: 14px;
            gap: 10px;
        }
    }
    #LeftBottom{
        padding: 25px 6px;
        
        ul{
            margin: 25px 0px;
            
            li{
                list-style: "✓";
                gap: 6px;
                padding: 2px 10px;
                margin-left: -25px;
                font-size: 11px;
            }
        }
    }
    #LeftBottom>p:nth-child(1){
        font-size: 12px;
        margin: 0px;
    }
    #LeftBottom>p:nth-child(3){
        font-size: 12px;
    }
    
}
@media screen and (max-width:930px) {
    #CPP4Booking{
        width: 80%;
    }
}
@media screen and (max-width:770px) {
    #CPP4Booking{
        flex-direction: column;
        height: auto;
    }
    #CPP4BookingLeft{
        width: 100%;
        padding: 20px 0px 50px 0px;
    }
    #CPP4BookingRight{
        width: 100%;
        border-radius: 12px;
    }
    #CalendelySCH{
        height: 865px;
        border-radius: 12px;
    }
}
@media screen and (max-width:400px) {
    #LeftTop{
        flex-direction: column;
        gap: 30px;
    }
    #LeftTop>div:nth-child(2){
        text-align: center;
        span{
            justify-content: center;
        }
    }
    #CalendelySCH{
        height: 890px;
    }
}