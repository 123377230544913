#HWP3Preparation{
    height: auto;
    width: 100%;
    padding: 10vh 0px;
    background-color: var(--alt-bg-color);
    text-align: center;
}
#HWP3Preparation>div:nth-child(1){
    width:70%;
    height: auto;
    margin: auto;
}
#HWP3Preparation>div:nth-child(1)>div:nth-child(1){
    text-align: center;
    div{
        margin: auto;
    }
    p{
        text-align: center;
        margin: auto;
        width: 60%;
        line-height: 3vh;
    }
}


#gridView{
    height: auto;
    width: 100%;
    margin:5vh auto 0px auto;
    /* padding: 5vh 0px; */

    background-image: url("../../Media/dotsMapBG.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
    background-color: transparent;
    width: 100%;
}

.col {
	flex: 1 0 200px;
	margin: 0 1vh;
	padding:0px 2vh 10vh 2vh;
	box-sizing: border-box;
	background-color: transparent;
	text-align: center;
    color: var(--heading-color);
}
.rowEx>div:nth-child(1){
    justify-content: flex-end;
}
.rowEx>div:nth-child(2){
    justify-content: center;
}
.rowEx>div:nth-child(3){
    justify-content: flex-start;
}
.gridCard{
    height: 20vh;
    width: 25vh;
    background-color: white;
    border-radius: 4vh;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: rgba(99, 102, 241, 0.17) 0px 1vh 5vh 0px;
}
.gridCard>div:nth-child(1){
    height:5vh;
    width:5vh;
    background-color: white;
    border-radius: 10vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0.5vh 0.5vh;
    position: absolute;
    top: -1.5vh;
    right: -1.5vh;
    font-size: 2.5vh;
}
.gridCard>div:nth-child(2){
    width: 80%;
    height: 50%;
    /* background-color: red; */
    padding: 1vh 0px;
    font-size: 2vh;
    font-weight: 550;
    line-height: 2.7vh;
}
.gridCard>div:nth-child(3){
    height: 30%;
    width: 100%;
    border-top: 0.2vh solid rgb(203, 203, 203);
}

#prepBottom{
    height: auto;
    width: 85%;
    margin:7vh auto 0px auto;
    padding: 2vh 7vh;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1vh 5vh 0px;
    border-radius: 10vh;
    text-align: start;
    font-size:1.8vh;
    line-height: 2.5vh;
    font-weight: 550;
    color: var(--heading-color);
}






@media screen and (max-width:1217px) {
    .rowEx>div:nth-child(1), .rowEx>div:nth-child(2), .rowEx>div:nth-child(3){
        justify-content: center;
    }
}


@media screen and (max-width:980px) {
    #HWP3Preparation{
        padding: 65px 0px;
    }
    #HWP3Preparation>div:nth-child(1){
        width:80%;
    }
    #HWP3Preparation>div:nth-child(1)>div:nth-child(1){
        p{
            width: 90%;
            line-height: 20px;
            font-size: 12px;
        }
    }
    
    
    #gridView{
        margin:40px auto 0px auto;
    }
    
    .col {
        flex: 1 0 200px;
        margin: 0 10px;
        padding:0px 12px 65px 12px;
    }
    .gridCard{
        height: 120px;
        width: 150px;
        background-color: white;
        border-radius: 25px;
        box-shadow: rgba(99, 102, 241, 0.17) 0px 7px 29px 0px;
    }
    .gridCard>div:nth-child(1){
        height:35px;
        width:35px;
        border-radius: 100px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        top: -10px;
        right: -10px;
        font-size: 17px;
    }
    .gridCard>div:nth-child(2){
        width: 80%;
        height: 50%;
        /* background-color: red; */
        padding: 6px 0px;
        font-size: 12px;
        line-height: 15px;
    }
    .gridCard>div:nth-child(3){
        border-top: 1px solid rgb(203, 203, 203);
    }
    #prepBottom{
        margin:40px auto 0px auto;
        padding: 12px 50px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 10px 29px 0px;
        border-radius: 100px;
        font-size:13px;
        line-height: 18px;
    }
    
    
    
}

@media screen and (max-width:930px) {
    #HWP3Preparation>div:nth-child(1){
        width: 90%;
    }
    .col{
        padding:0px 0px 65px 0px;
    }
    #prepBottom{
        width: 75%;
    }

}
@media screen and (max-width:550px) {
    #gridView{
        background: none;
    }
    .gridCard{
        width: 70%;
        height: 150px;
    }
    .gridCard>div:nth-child(2){
        font-size: 16px;
        line-height: 23px;
    }
    .col {
        padding:0px 0px 50px 0px;
    }
    #HWP3Preparation>div:nth-child(1)>div:nth-child(1){
        p{
            width:100%;
        }
    }
    #prepBottom{
        padding: 30px 50px;        
        width: 70%;
    }
}
@media screen and (max-width:450px) {
    .gridCard{
        width: 90%;
    }
    #prepBottom{
        display: none;
    }

}