#CAP2Heading{
    height: auto;
    width: 100%;
    padding: 10vh 0px;
    margin: auto;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media/Pattern.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;

    h1{
        width:36%;
        color: var(--txt-white-color);
        margin: auto;
        font-size: 6vh;
        line-height: 8vh;
    }
}





/* --------------------- Media Query --------------------- */




@media screen and (max-width:1024px) {
    #CAP2Heading{
        h1{
            width:48%;
        }
    }
}

@media screen and (max-width:980px) {
    #CAP2Heading{
        padding: 70px 0px;
        
        h1{
            width:50%;
            font-size: 40px;
            line-height: 50px;
        }
    }    
}


@media screen and (max-width:830px) {
    #CAP2Heading{
        h1{
            width:70%;
        }
    }
}
@media screen and (max-width:600px) {
    #CAP2Heading{
        h1{
            width:80%;
            font-size: 30px;
            line-height: 40px;
        }
    }
}
@media screen and (max-width:400px) {
    #CAP2Heading{
        padding: 40px 0px;
        h1{
            width:80%;
            font-size: 22px;
            line-height: 30px;
        }
    }
}