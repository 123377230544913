#APP6Contact{
    height: auto;
    width: 100%;
    padding: 15vh 0px;
    background-color: rgba(22, 28, 45, 1);
    background-image: url("../../Media//aboutBottomContactBGIMG.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--txt-white-color);
    text-align: start;
}
#APP6Contact>div{
    justify-content: space-between;
    width: 70%;
    height: auto;
    margin: auto;
    transition: all 0.3s;
}

#APP6Left{
    height: auto;
    width: 30%;
    transition: all 0.3s;

    p{
        font-size: 3vh;
    }
}

#Icon{
    height: 6vh;
    width: 6vh;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--icon-bg-color);
    border-radius: 100vh;
    font-size: 3vh;
    transition: all 0.3s;
}


#APP6ContactForm{
    height: auto;
    width: 27%;
    padding:  4vh;
    background-color: white;
    border-radius: 1vh;
    transition: all 0.3s;
}
#APP6ContactForm>div:nth-child(4){
    margin-top: 3vh;
}

.FormItems{
    color: black;
    
    p{
        margin: 0px 0px 0px 0.4vh;
        font-size: 1.8vh;

        span{
            color: red;
        }
    }
    input{
        margin: 0.3vh 0px 3vh 0px;
        padding: 1vh;
        border: 1px solid rgb(234, 234, 234);
        border-radius: 1vh;
        width: 94%;
        outline: 0px;
    }
    
    select{
        margin: 0.5vh 0px 3vh 0px;
        padding: 1.5vh 1vh;
        border: 1px solid rgb(234, 234, 234);
        border-radius: 1vh;
        width: 99%;
        outline: 0px;
        cursor: pointer;
    }
}

#FormSubmit{
    padding: 1.2vh 0px;
    margin:0.5vh auto 0px auto;
    width: 100%;
    text-transform: uppercase;
    border:0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    letter-spacing: 0.1vh;
    cursor: pointer;
    font-size: 2vh;
    transition: letter-spacing 0.1s, all 0.3s;
}
#FormSubmit:hover{
    letter-spacing: 0.3vh;
}







@media screen and (max-width:1024px) {
    #APP6Left{
        width: 35%;
    }
    #APP6ContactForm{
        width: 35%;
    }
}
@media screen and (max-width:980px) {
    #APP6Contact{
        padding: 100px 0px;
    }
    #APP6Left{
        p{
            font-size: 20px;
        }
    }
    
    #Icon{
        height: 40px;
        width: 40px;
        border-radius: 100px;
        font-size: 20px;
    }
    
    
    #APP6ContactForm{
        width:40%;
        padding:  25px;
        border-radius: 8px;
    }
    #APP6ContactForm>div:nth-child(4){
        margin-top: 18px;
    }
    
    .FormItems{        
        p{
            margin: 0px 0px 0px 3px;
            font-size: 12px;
        }
        input{
            margin: 4px 0px 18px 0px;
            padding: 7px;
            border-radius: 7px;
        }
        
        select{
            margin: 4px 0px 18px 0px;
            padding: 9px 7px;
            border-radius: 7px;
        }
    }
    
    #FormSubmit{
        padding: 8px 0px;
        margin:4px auto 0px auto;
        border-radius: 5px;
        letter-spacing: 1px;
        font-size: 12px;
    }
    #FormSubmit:hover{
        letter-spacing: 2px;
    }
}
@media screen and (max-width:930px) {
    
    #APP6Contact>div{
        width: 80%;
    }
}
@media screen and (max-width:700px) {
    #APP6Left{
        width: 40%;
    }
    #APP6ContactForm{
        width: 45%;
    }
}
@media screen and (max-width:550px){
    #APP6Contact{
        padding: 70px 0px;
    }
    #APP6Contact>div{
        flex-direction: column;
        gap: 30px 0px;
    }
    #APP6Left{
        width:80%;
    }
    #APP6ContactForm{
        width:80%;
    }
}
@media screen and (max-width:550px){
    #APP6Left{
        width:100%;
    }
    #APP6ContactForm{
        width:85%;
    }
}
@media screen and (max-width:450px){
    #FormSubmit{
        font-size: 10px;
    }
}