.BlogCard{
    height: 43vh;
    width: 20vw;
    background-color:white;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    border-radius: 2.5vh;
    padding: 1.5vh;
    cursor: pointer;
}

.BlogCard>div:nth-child(1){
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 23vh;
    width: 100%;
    border-radius: 2vh;
    
}
.BlogCard>div:nth-child(2){
    height: 18vh;
    width: 19vw;
    /* background-color: yellow; */
    padding:2vh 0.5vw 0px 0.5vw;
    text-align: start;

    h6{
        margin: 0px;
        text-transform: uppercase;
        letter-spacing: 0.3vh;
        color: #61b8f1;
        font-size: 1.3vh;
    }

    h3{
        margin: 1vh 0px 0vh 0px;
        height: auto;
        /* background-color: red; */
        font-size: 2vh;
        letter-spacing: 0.1vh;
        line-height: 2.5vh;
    }

    p{
        font-size: 1.8vh;
        line-height: 2vh;
        height: auto;
        margin: 1.2vh 0px ;
    }

    span{
        font-size: 13px;
        /* font-weight: 600; */
        margin:0vh;
        font-size: 1.5vh;
    }
   
}

.BlogCard>div:nth-child(2)>label{
    font-size: 9px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 12px;
    width: 100%;
    /* background-color: aqua; */
    margin-top: 2px;
    bottom: 0;
    color: #61b8f1;

    p{
        margin: 0px 0px 0px 0px;
    }
    div{
        height: 100%;
        width: 0.5px;
        background-color:#065593;
        margin: 0px 5px;
    }
}
/* 
.newClass{
    height: 20vh;
    background-color: red;
    width: 100%;
} */

@media screen and (max-width:1000px) {
    .BlogCard{
        height: 43vh;
        width: 30vw;
        background-color:white;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
        border-radius: 2.5vh;
        padding: 1.5vh;
    }
    .BlogCard>div:nth-child(2){
        width: 95%;
        /* background-color: red; */
    }
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {
    .BlogCard{
        height: auto;
        width: 90%;
        border-radius: 20px;
        padding: 10px;
    }
    
    .BlogCard>div:nth-child(1){
        height: 150px;
        width: 100%;
        border-radius: 20px;
        
    }
    .BlogCard>div:nth-child(2){
        height: 130px;
        width: 90%;
        padding:10px 5px;
    
        h6{
            letter-spacing:3px;
            font-size: 8px;
        }
    
        h3{
            margin: 8px 0px 0px 0px;
            font-size: 15px;
            letter-spacing: 1px;
            line-height: 20px;
        }
    
        p{
            font-size: 12px;
            line-height: 15px;
            height: auto;
            margin: 10px 0px ;
        }
    
        span{
            font-size: 10px;
            /* font-weight: 600; */
            margin:0px;
        }
       
    }
    
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
