#HWP4Contact{
    height: auto;
    width: 70%;
    margin: auto;
    padding: 10vh 0px;

    h1{
        font-size: 4vh;
        color: var(--heading-color);
    }
}

#prepForm{
    width: 100%;
    height: auto;
    margin: 10vh auto 5vh auto;
}
#prepForm>div{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3vh;
}
#prepForm>div:nth-child(1)>div, #prepForm>div:nth-child(2)>div{
    padding: 1vh 0px;
    text-align: start;
    width: 48%;
    /* background-color: red; */

    p{
        margin: 0;
        font-size: 1.8vh;
        color: var(--heading-color);
        padding-left: 0.3vh;
        span{
            color: red;
        }
    }
}


.div4{
    p{
        padding-bottom: 1vh;
    }
}


#prepForm>div:nth-child(1)>div>input, #prepForm>div:nth-child(2)>div>input{
    margin-top: 1vh;
    width: 90%;
    padding: 1.5vh 3%;
    border: 0px;
    border-radius: 1vh;
    outline: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
#prepForm>div:nth-child(2)>div{
    width: 100%;
    margin-top: 4vh;
    textarea{
        margin-top: 1vh;
        width: 92%;
        padding: 2vh 3vh;
        border: 0px;
        border-radius: 1vh;
        outline: 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        color: var(--heading-description-color);
        font-size: 1.8vh;
        resize: none;
        font-family: "poppins";
    }
}
#SubmitInput{
    padding: 1.2vh 10vh;
    margin:7vh auto 0px auto;
    width: auto;
    text-transform: uppercase;
    border:0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    letter-spacing: 0.2vh;
    cursor: pointer;
    font-size: 2vh;
    transition: letter-spacing 0.1s, all 0.3s;
}
#SubmitInput:hover{
    transform: scale(1.1);
    letter-spacing: 0.6vh;
}



@media screen and (max-width:980px) {
    #HWP4Contact{
        width: 70%;
        padding: 65px 0px;
    
        h1{
            font-size: 25px;
        }
    }
    
    #prepForm{
        margin: 65px auto 30px auto;
    }
    #prepForm>div{
        gap: 20px;
    }
    #prepForm>div:nth-child(1)>div, #prepForm>div:nth-child(2)>div{
        padding: 6px 0px;
        
        p{
            font-size: 12px;
            padding-left: 2px;
        }
    }
    #prepForm>div:nth-child(1)>div>input, #prepForm>div:nth-child(2)>div>input{
        margin-top: 7px;
        padding: 8px 3%;
        border-radius: 6px;
    }
    #prepForm>div:nth-child(2)>div{
        margin-top: 25px;
        textarea{
            margin-top: 6px;
            padding: 12px 16px;
            border-radius: 8px;
            font-size: 12px;
        }
    }
    #SubmitInput{
        padding: 10px 70px;
        margin:40px auto 0px auto;
        border-radius: 7px;
        letter-spacing: 1px;
        font-size: 13px;
    }
    #SubmitInput:hover{
        letter-spacing: 3px;
    }

}

@media screen and (max-width:930px) {
    #HWP4Contact{
        width: 80%;
    }
}

@media screen and (max-width:700px) {
    #prepForm>div:nth-child(1)>div, #prepForm>div:nth-child(2)>div{
        width: 100%;
        input{
            width: 94%;
        }
    }

}
@media screen and (max-width:320px) {
    #prepForm>div:nth-child(2)>div{
        width: 100%;
        margin-top: 25px;
        textarea{
            width: 83%;
        }
    }
}