.BlogCardBP{
    height: auto;
    width: auto;
    /* background-color: lightslategray; */
    text-align: start;
    transition: all 0.3s;
}
.BlogCardBP>p{
    font-size: 1.8vh;
    margin:2vh 0px 1vh 0px;
    cursor: pointer;
    width: fit-content;
    span{
        margin-left: 0.5vh;
    }
}
.BlogCardBP>p:hover{
    span{
        margin-left: 1.5vh;
        transition: all 0.3s ease-in-out; 
    }
}
.BlogCardBPTop{
    height: 35vh;
    transition: all 0.3s;
    width: 100%;
    border-radius: 2vh;
    img{
        width: 100%;
        height: 100%;
        border-radius: 2vh;
        background-color: var(--alt-bg-blue-color);
    }
}
.BlogCardBPBottom{
    margin-top: 2vh;
    height:auto;
    width: 100%;
    /* background-color: lightseagreen; */
}
.BlogCardBPBottom>h2{
    margin: 0px;
    font-size: 3.5vh;
}
.BlogCardBPBottom>p{
    margin-top: 1vh;
    font-size: 2vh;
    word-spacing: 0.1vh;
    line-height: 2.5vh;
}












/* ------------------------ 980px ----------------------- */

@media screen and (max-width:980px) {
    .BlogCardBP>p{
        font-size: 12px;
        margin:12px 0px 7px 0px;
        span{
            margin-left: 5px;
        }
    }
    .BlogCardBP>p:hover{
        span{
            margin-left: 7px;
        }
    }
    .BlogCardBPTop{
        height: 200px;
        border-radius: 10px;
        img{
            border-radius: 10px;
        }
    }
    .BlogCardBPBottom{
        margin-top: 12px;
    }
    .BlogCardBPBottom>h2{
        font-size: 20px;
    }
    .BlogCardBPBottom>p{
        margin-top: 7px;
        font-size: 12px;
        word-spacing: 1px;
        line-height: 14px;
    }
    
    
    
    
}







/* ------------------------ 800px ----------------------- */

@media screen and (max-width:800px){
    .BlogCardBPTop{
        height: 150px;
    }
    .BlogCardBPBottom>h2{
        font-size: 16px;
    }
    .BlogCardBPBottom>p{
        font-size: 10px;
    }
}



/* ------------------------ 600px ----------------------- */

@media screen and (max-width:600px){
    .BlogCardBPTop{
        height: 250px;
    }
}



/* ------------------------ 450px ----------------------- */

@media screen and (max-width:450px){
    .BlogCardBPTop{
        height: 160px;
    }
    .BlogCardBPBottom>h2{
        font-size: 14px;
    }
    .BlogCardBPBottom>p{
        font-size: 10px;
        line-height: 14px;
    }
    .BlogCardBP>p{
        font-size: 10px;
        span{
            font-size: 10px;
        }
    }
}