#SPP3Expertise{
    height: auto;
    padding: 10vh 0px;
    width: 100%;
    background-color: var(--alt-bg-color);
}
#SPP3Expertise>div:nth-child(1){
    background-color: var(--alt-bg-blue-color);
    height: auto;
    width: 66vw;
    border-radius: 1vh;
    padding: 5vh 3vh;
    background-image: url("../../Media//PatternLong.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.3s;

    h1{
        color: white;
    }
}

#ExpertiseList{
    height: auto;
    width: 100%;
    margin: 3vh 0px;
    display: grid;
    gap: 50px;
}

.ExpertiseCardLeft, .ExpertiseCardRight{
    height: 40vh;
    width: 100%;
    /* background-color: red; */
    justify-content: flex-start;
    color: var(--txt-white-color);

    img{
        margin: 0px -8vh 5vh 0px;
        width: 20vh;
    }

    div{
        justify-content: flex-start;
        width: 80%;
        height: 80%;
        text-align: start;
        h2{
            margin: 0;
            font-weight: 600;
            font-size: 4vh;
        }
        p{
            font-size: 2vh;
            margin:2vh 0px 0px 0px;
            line-height: 2.5vh;
        }
    }
}


.ExpertiseCardLeft{
    justify-content: flex-start;
    div{
        justify-content: flex-start;
    }
}


.ExpertiseCardRight{
    justify-content: flex-end;
    div{
        justify-content: flex-end;
    }
}





@media screen and (max-width:1025px) {
    .ExpertiseCardLeft, .ExpertiseCardRight{
        
        
        div{
            p{
                font-size:13px;
                margin:2vh 0px 0px 0px;
            }
        }
    }
}



@media screen and (max-width:980px) {
    #SPP3Expertise{
        padding: 65px 0px;
    }
    #SPP3Expertise>div:nth-child(1){
        width: 66%;
        border-radius: 8px;
        padding: 35px 17px;
    }
    
    #ExpertiseList{
        margin: 17px 0px;
        gap: 50px;
    }
    
    .ExpertiseCardLeft, .ExpertiseCardRight{
        height: 250px;
        width: 100%;
        
        img{
            margin: 0px -50px 35px 0px;
            width: 120px;
        }
    
        div{
            h2{
                font-size: 25px;
            }
            p{
                font-size:12px;
                margin:12px 0px 0px 0px;
                line-height: 15px;
            }
        }
    }
    
}

@media screen and (max-width:930px) {
    #SPP3Expertise>div:nth-child(1){
        width: 76%;
    }
}

@media screen and (max-width:770px) {
    #SPP3Expertise>div:nth-child(1){
        width: 75%;
    }
    #ExpertiseList{
        margin: 0px 0px;
        display: grid;
        gap: 0px;
    }
    
    .ExpertiseCardLeft, .ExpertiseCardRight{
        height: 250px;
        width: 100%;
        div{
            justify-content: flex-start;
            width: 100%;

            h2{
                font-size: 20px;
            }
            p{
                font-size:12px;
                margin:2vh 0px 0px 0px;
                line-height: 15px;
            }
        }
    }
}
@media screen and (max-width:470px) {
    .ExpertiseCardLeft, .ExpertiseCardRight{
        height: 250px;
        width: 100%;
        div{
            justify-content: flex-start;
            width: 100%;

            h2{
                font-size: 18px;
            }
            p{
                font-size:10px;
                margin:2vh 0px 0px 0px;
                line-height: 15px;
            }
        }
   
    }
}

@media screen and (max-width:410px) {
    .ExpertiseCardLeft, .ExpertiseCardRight{

    
        img{
            margin: 0px -100px -20px 0px;
            width: 200px;
        }
        div{
            margin: 0px;
        }
    }
}