
/*  ---------- Home Page Part 10   Blogs     ---------- */

#HPP11Contact{
    height: auto;
    width: 100%;
    padding: 15vh 0px 15vh 0px;
}
#HPP11ContactTop{
    height: auto;
    width: 60.5vw;
    margin: auto;
    background-color: var(--alt-bg-blue-color);
    border-radius: 1vh;
    padding: 3vh 10vh 5vh 10vh;
    background-image: url("../../Media/PatternArrow.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-start;
}
#HPP11ContactTop>div:nth-child(1){
    width: 40vw;
    text-align: start;
    color: var(--txt-white-color);
    /* background-color: red; */
    padding-bottom: 2vh;
    height: auto;

    p{
        margin:-2vh 0px 0vh 0px;
        font-size:2vh;
        width: 80%;
    }
}

#HPP11Contact>div:nth-child(2){
    width: 70vw;
    background-color: rgba(34, 197, 94, 0.05);
    margin: auto;
    height: auto;
    padding-bottom: 7vh;
    
}
#HPP11ContactBottom{
    height: auto;
    width: 65vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: rgb(255, 255, 255);
    margin:0vh auto 0px auto;
    border-radius: 2vh;
    justify-content: space-between;
    align-items: flex-start;
}
#HPP11ContactBottomLeft{
    height: auto;
    width: 49%;
    /* background-color: cadetblue; */
    margin: 3vh 0 2vh 2vh;
    
}
#HPP11ContactBottomLeft>div:nth-child(1){
    height: 25vh;
    width: 100%;
    /* background-color: rgb(255, 219, 219); */
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5vh;
}
.FormItem{
    /* background-color: greenyellow; */
    text-align: start;

    h5{
        margin: 0px 0px 0.8vh 0.5vh;
        color: var(--txt-blue-color);
        font-size: 1.8vh;

        span{
            color: red;
            font-size: 1.8vh;
        }
    }

    p{
        font-size: 1.5vh;
        margin: 0.5vh 0.5vw;
        color: red;
        display: none;
    }

}
.FormItem>div{
    height: 3vh;
    width: 13.5vw;
    padding: 1.2vh;
    border-radius: 2vh;
    border-top-left-radius: 0px;
    justify-content: space-between;
    font-size: 2.7vh;
    /* background-color: rgb(245, 40, 40); */
    color: var(----icon-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    gap: 1vh;


    input{
        height: 3vh;
        width: 13vw;
        border: 0px;
        outline: 0px;
        font-size: 1.8vh;
        caret-color: var(--alt-bg-blue-color);
    }
}

#HPP11ContactBottomRight{
    height: 85.5vh;
    width: 49%;
    /* background-color: cadetblue; */
    margin: 0px;
    border-left: 2px solid whitesmoke;
    border-top-right-radius: 2vh;
    border-bottom-right-radius: 2vh;
}


#HPP11ContactBottomLeft>div:nth-child(2){
    height: 35vh;
    width: 100%;
    /* background-color: orangered; */
    gap: 2vh;
    margin-top: 2vh;
    text-align: start;
    color: var(--icon-color);

    textarea{
        border: 0px;
        outline: 0px;
        border-radius: 2vh;
        font-family: "poppins";
        padding: 1.5vh;
        max-width: 29vw;
        min-width: 29vw;
        max-height: 18vh;
        min-height: 18vh;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        resize: none;
        /* background-color: red; */
        font-size: 2vh;
    }

    p{
        margin: 1.5vh 0.5vh;
        font-size:1.5vh;
    }
}


.attachFile{
    height: 11vh;
    width: 30vw;
    /* background-color: red; */
    margin-top: 3vh;
    display: table;
    cursor: pointer;


    div{
        height: 100%;
        width: 100%;
        gap: 1vh;
        border: 0.3vh dashed var(--icon-color);
        color: var(--icon-color);
        font-size: 2vh;
    }
    input[type="file"] {
        display: none;
        cursor: pointer;
    }
}


.div4{
    div{
        padding: 0;
        width: auto;
        margin: 0;
        height: auto;
        width: auto;
        box-shadow: none;
    }

    h5{
        padding-bottom: 0.3vh;
    }
}






#HPP11ContactBottomLeft>div:nth-child(3){
    height: 11.5vh;
    width: 100%;
    /* background-color: yellowgreen; */
    gap: 2vh;
    color: var(--icon-color);
    padding-top: 8vh;
    text-align: start;

    input{
        height: 1.6vh;
        width: 1.6vh;
    }
    
    label{
        font-size: 1.8vh;
        justify-content: flex-start;
        gap: 1vh;
        margin-bottom: 1vh;

        input{
            cursor: pointer;
        }
    }
}

#CalendelySCH{
    height: 86.5vh;
    width: 100%;
    border-bottom-right-radius: 2vh;
}





@media screen and (max-width:1246px) {
    #HPP11ContactBottom{
        width: 100%;
    }
    

}

@media screen and (max-width:1100px) {
    #HPP11ContactTop{
        padding: 30px 30px 70px 30px;
    }
    #HPP11ContactTop>div:nth-child(1){
        p{
            font-size: 14px;
        }
    }
    #HPP11ContactTop{
        height: 200px;
        width: 66vw;
    }


}


/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media screen and (max-width:980px) {
    
    
    #HPP11Contact{
        height: auto;
        width: 100%;
        padding:100px 0px 100px 0px;
    }
    #HPP11ContactTop{
        width: 55%;
        border-radius: 8px;
        padding: 20px 70px 30px 70px;
    }
    #HPP11ContactTop>div:nth-child(1){
        width: 60%;
        text-align: start;
        color: var(--txt-white-color);
        /* background-color: red; */
        padding-bottom: 2vh;
        height: auto;

        div{
            padding: 2px 4px;
            margin-top: 5px;
        }

        h1{
            color: white;
            font-size: 20px;
            margin: 20px 0px;
        }

        p{
            margin:0px;
            font-size:14px;
            width: 100%;
        }
    }

    #HPP11Contact>div:nth-child(2){
        width: 75%;
        padding-bottom: 50px;
        
    }
    #HPP11ContactBottom{
        height: auto;
        width: 90%;
        border-radius: 15px;
    }
    #HPP11ContactBottomLeft{
        margin: 20px 10px 10px 10px;
        
    }
    #HPP11ContactBottomLeft>div:nth-child(1){
        height: 200px;
        gap: 10px;
    }
    .FormItem{

        h5{
            margin: 0px 0px 5px 3px;
            color: var(--txt-blue-color);
            font-size: 10px;

            span{
                color: red;
                font-size: 10px;
            }
        }

        p{
            font-size: 10px;
            margin: 3px 3px;
            color: red;
        }

    }
    .FormItem>div{
        height:25px;
        width: 87%;
        padding: 8px;
        border-radius: 12px;
        font-size: 10px;
        gap: 10px;


        input{
            height: 100%;
            width: 80%;
            border: 0px;
            outline: 0px;
            font-size: 12px;
        }
    }

    #HPP11ContactBottomRight{
        height: 400px;
        width: 48%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }


    #HPP11ContactBottomLeft>div:nth-child(2){
        height: 230px;
        gap: 10px;
        margin-top: 10px;

        textarea{
            border-radius: 10px;
            padding: 10px;
            max-width: 280px;
            min-width: 280px;
            max-height: 130px;
            min-height: 130px;
            font-size: 14px;
        }

        p{
            margin: 10px 5px;
            font-size:10px;
        }
    }
    .attachFile{
        height: 70px;
        width: 290px;
        /* background-color: red; */
        margin-top: 20px;
        div{
            gap: 7px;
            border: 2px dashed var(--icon-color);
            font-size: 10px;
        }
    }

    #HPP11ContactBottomLeft>div:nth-child(3){
        height: 90px;
        width: 100%;
        gap: 10px;
        padding-top: 60px;

        input{
            height: 10px;
            width: 10px;
        }
        
        label{
            font-size: 10px;
            gap: 2px;
            margin-bottom: 7px;
        }
    }
    
    #CalendelySCH{
        height: 620px;
        width: 100%;
        border-bottom-right-radius: 2vh;
    }



}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */



@media screen and (max-width:935px) {
    #HPP11ContactTop{
        height: 300px;
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }
    #HPP11ContactTop>div:nth-child(1){
        /* background-color: red; */
        width: 100%;
        margin-left: 20px;
    }
    
    #HPP11ContactBottom{
        width:100%;
        margin: auto;
        display: block;
    }
    #HPP11ContactBottomLeft{
        width: 95%;
        padding-top: 20px;
        border-bottom: 1px solid gray;


    }
    #HPP11ContactBottomRight{
        width: 100%;
        margin-top: 0vh;
        background-color: white;
        height: auto;
    }

    
    .FormItem>div{
        font-size: 15px;
    }
    #HPP11ContactBottomLeft>div:nth-child(2){
        width: 90%;
    
        textarea{
            width: 98%;
            max-width: 100%;
            max-width: 100%;
            max-height:70px;
            max-height:70px;
        }
    }
    
.attachFile{
    width: 100%;

    div{
        height: 100%;
        width: 100%;
    }
}
    
}




@media screen and (max-width:600px) {
    .FormItem>div{
        width: 70%;
    }
    
    #HPP11ContactBottomLeft>div:nth-child(2){
        margin-top: -10px;
        
    }
    #HPP11ContactBottomLeft>div:nth-child(2){
        width: 90%;
        
        textarea{
            width: 95%;
            max-width: auto;
            min-width: auto;
        }
    }
}


@media screen and (max-width:350px) {
    .FormItem>div{
        width: 65%;
    }
    #HPP11ContactBottomLeft>div:nth-child(2){
        width: 85%;
    }
}