#SPP6Contact{
    height: auto;
    width: 70%;
    margin: 10vh auto;
    padding: 5vh 0px;
    background-color: var(--alt-bg-lightGreen-color);
    position: relative;
    border-radius: 2vh;

    h1{
        color: var(--heading-color);
        margin: 0px auto;
        font-size: 4vh;
    }
}
#SPP6Contact>div{
    position: absolute;
    background-color: rgba(4, 116, 188, 0.15);
    border-radius: 100vh;
}
#L1{
    height: 20vh;
    width: 20vh;
    top: 5vh;
    left: 5vh;
}
#L2{
    height: 20vh;
    width: 20vh;
    bottom: 5vh;
    right: 5vh;
}
#S1{
    height: 5vh;
    width: 5vh;
    top: 23vh;
    left: 23vh;
}
#S2{
    height: 5vh;
    width: 5vh;
    bottom: 23vh;
    right: 23vh;
}
#contactForm{
    margin: 5vh auto;
    width: 40%;
    height: auto;

    
}
#contactForm>div{
    height: 3vh;
    width: 95%;
    font-size: 3vh;
    padding: 1vh 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0px;
    color: var(--heading-description-color);
    background-color: white;
    gap: 1vh;
    border-radius: 1vh;
    /* background-color: red; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    input{
        width: 100%;
        height: 80%;
        border: 0px;
        outline: 0px;
        color: var(--heading-description-color);
        font-size: 1.8vh;
    }
    
}
#contactForm>div:nth-child(5){
    height: auto;
    padding: 1vh 2vh;
    align-items: flex-start;
    textarea{
        width: 95%;
        height: 20vh;
        border: 0px;
        outline: 0px;
        color: var(--heading-description-color);
        font-size: 1.8vh;
        resize: none;
        font-family: "poppins";
    }
}

#SubmitInput{
    padding: 1.2vh 0px;
    margin:0.5vh auto 0px auto;
    width: 100%;
    text-transform: uppercase;
    border:0px;
    border-radius: 0.8vh;
    background-image: var(--butn-bg-color);
    color: var(--txt-white-color);
    letter-spacing: 0.2vh;
    cursor: pointer;
    font-size: 2vh;
    transition: letter-spacing 0.1s, all 0.3s;
}
#SubmitInput:hover{
    letter-spacing: 0.6vh;
}


@media screen and (max-width:1024px) {
    #L1{
        height: 15vh;
        width: 15vh;
        top: 5vh;
        left: 5vh;
    }
    #L2{
        height: 15vh;
        width: 15vh;
        bottom: 5vh;
        right: 5vh;
    }
    #S1{
        height: 3vh;
        width: 3vh;
        top: 19vh;
        left: 19vh;
    }
    #S2{
        height: 3vh;
        width: 3vh;
        bottom: 19vh;
        right: 19vh;
    }
}





@media screen and (max-width:980px) {
    #SPP6Contact{
        margin: 70px auto;
        padding: 35px 0px;
    
        h1{
            font-size: 25px;
        }
    }
    #SPP6Contact>div{
        border-radius: 100px;
    }
    
    #L1{
        height: 100px;
        width: 100px;
        top: 35px;
        left: 35px;
    }
    #L2{
        height: 100px;
        width: 100px;
        bottom: 35px;
        right: 35px;
    }
    #S1{
        height:25px;
        width:25px;
        top: 125px;
        left: 125px;
    }
    #S2{
        height:25px;
        width:25px;
        bottom: 125px;
        right: 125px;
    }
    
    #contactForm{
        margin: 35px auto;
    }
    #contactForm>div{
        height: 17px;
        font-size: 17px;
        padding: 7px 12px;
        margin: 12px 0px;
        gap: 6px;
        border-radius:6px;
        
        input{
            font-size: 12px;
        }
        
    }
    #contactForm>div:nth-child(4){
        padding: 6px 12px;
        textarea{
            height: 120px;
            font-size: 11px;
        }
    }
    
    #SubmitInput{
        padding: 8px 0px;
        margin:5px auto 0px auto;
        border-radius: 6px;
        letter-spacing: 1px;
        font-size: 12px;
    }
    #SubmitInput:hover{
        letter-spacing: 3px;
    }
    
}




@media screen and (max-width:930px) {
    #SPP6Contact{
        width: 80%;
    }
}
@media screen and (max-width:700px) {
    #SPP6Contact>div{
        display: none;
    }
    #contactForm{
        margin: 5vh auto;
        width: 60%;
    }
}
@media screen and (max-width:500px) {
    
    #contactForm{
        width: 80%;
    }
    #SPP6Contact{
        margin: 70px auto;
        padding: 35px 0px 20px 0px;
    
        h1{
            font-size: 20px;
        }
    }
}



@media screen and (max-width:350px){
    #contactForm{
        div{
            width: 90%;
        }
    }
    #SubmitInput{
        font-size: 10px;
    }
}