#APP1Hero{
    height: 70vh;
    width: 100%;
    background-color: var(--alt-bg-blue-color);
    background-image: url("../../Media//Pattern.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
#APP1Hero>div:nth-child(1){
    height: 45%;
    width: 60vw;
    /* background-color: red; */
    padding: 5vh 0px;

    h6{
        letter-spacing: 0.3vh;
        color: var(--hero-p-color);
        margin: 0px;
        font-size: 1.7vh;
    }
    h1{
        margin: 1vh 0px 2vh 0px;
        font-size: 6vh;
        color: white;
    }
    p{
        font-size: 1.5vh;
        margin: 0px;
        color: var(--hero-p-color);
        text-transform: uppercase;
        line-height: 2.5vh;
        letter-spacing: 0.2vh;
    }

}

@media screen and (max-width:1441px) {
    #APP1Hero{
        height: 500px;
    }
    #APP1Hero>div:nth-child(1){
        width: 60%;
        /* background-color: red; */
        padding: 10px 0px;
    
        h6{
            letter-spacing: 2px;
            font-size: 14px;
        }
        h1{
            margin: 10px 0px 15px 0px;
            font-size: 40px;
        }
        p{
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    
    }
}

@media screen and (max-width:1246px) {
    #APP1Hero>div:nth-child(1){
        width: 65%;
        
    }
}
@media screen and (max-width:1025px) {
    #APP1Hero{
        height: 400px;
    }
    #APP1Hero>div:nth-child(1){
        width: 60%;
        /* background-color: red; */
        padding: 8px 0px;
        
        h6{
            font-size: 12px;
        }
        h1{
            font-size: 30px;
        }
        p{
            font-size: 10px;
        }
        
    }
}
@media screen and (max-width:980px) {
    #APP1Hero{
        height: auto;
    }
    #APP1Hero>div:nth-child(1){
        width: 60%;
        /* background-color: red; */
        padding: 170px 0px;
        
        h6{
            font-size: 12px;
        }
        h1{
            font-size: 30px;
        }
        p{
            font-size: 10px;
            line-height: 20px;
            letter-spacing: 1px;
        }
        
    }
}
@media screen and (max-width:930px) {
    #APP1Hero{
        height: 400px;
    }
    #APP1Hero>div:nth-child(1){
        width: 70%;
    }
}
@media screen and (max-width:800px) {
    #APP1Hero{
        height: 450px;
    }
    #APP1Hero>div:nth-child(1){
        width: 70%;
        height: auto;
        
        h6{
            font-size: 10px;
        }
        h1{
            font-size: 25px;
        }
        p{
            font-size: 9px;
        }
    }
}

@media screen and (max-width:600px) {
    #APP1Hero{
        height: auto;
        padding: 0px;
    }
    #APP1Hero>div:nth-child(1){
        width: 80%;
        padding: 130px 0px;
        
        h6{
            font-size: 8px;
        }
        h1{
            font-size: 20px;
        }
        p{
            font-size: 7px;
            line-height: 17px;
        }
    }
}
@media screen and (max-width:510px) {
    #APP1Hero{
        height: auto;
        padding: 0px;
    }
    #APP1Hero>div:nth-child(1){
        padding: 120px 0px 100px 0px;
    }
}