#ServiceCardServicePage{
    height: 30vh;
    width: 95%;
    margin: auto;
    background-color: var(--spp-servicecard-color);
    border-radius: 30vh;
    padding: 3vh;
    justify-content: space-between;
}
.MiddlePartRight, .MiddlePartLeft{
    width: 50%;
    p{
        margin: 0px;
        text-align: start;
        font-size: 2vh;
    }
}
.MiddlePartRight{
    margin-right: 5vw;
    grid-area: MiddleRight;

}
.MiddlePartLeft{
    margin-left:5vw;
    grid-area: MiddleLeft;
}

.LeftPart, .RightPart{
    background-color: white;
    height: 100%;
    width: 20vw;
    border-right: 0.4vh solid #C4D4FF;
    border-left: 0.4vh solid #C4D4FF;

    h2{
        margin: 0px;
        text-align: start;
        width: min-content;
        font-size: 3.5vh;
    }

    
}

.LeftPart{
    border-radius: 30vh 0px 0px 30vh;
    grid-area: LeftPart;
}
.RightPart{
    border-radius: 0px 30vh 30vh 0px;
    grid-area: RightPart;
}



@media screen and (max-width:980px) {
    #ServiceCardServicePage{
        height: 175px;
        border-radius: 120px;
        padding: 17px;
    }
    .MiddlePartRight, .MiddlePartLeft{
        p{
            font-size: 12px;
        }
    }
    .MiddlePartRight{
        margin-right: 10%;
    
    }
    .MiddlePartLeft{
        margin-left:10%;
    }
    
    .LeftPart, .RightPart{
        width: 30%;
        border-right: 2px solid #C4D4FF;
        border-left: 2px solid #C4D4FF;
    
        h2{
            font-size: 20px;
        }
    
        
    }
    
    .LeftPart{
        border-radius: 180px 0px 0px 180px;
    }
    .RightPart{
        border-radius: 0px 180px 180px 0px;
    }
}



@media screen and (max-width:930px) {
    #ServiceCardServicePage{
        height: 30vh;
        width: 95%;
    }
    .LeftPart, .RightPart{
        h2{
            margin: 0px;
            font-size: 20px;
        }
    }
    .MiddlePartRight{
        margin-right: 80px;
    }
    .MiddlePartLeft{
        margin-left:80px;
    }
}

@media screen and (max-width:770px) {
    
    .LeftPart, .RightPart{
        width: 160px;
        h2{
            margin: 0px;
            font-size:16px;
        }
    }
    .MiddlePartRight, .MiddlePartLeft{
        p{
            margin: 0px;
            text-align: start;
            font-size: 12px;
            line-height: 15px;
        }
    }
    .MiddlePartRight{
        margin-right: 35px;
    }
    .MiddlePartLeft{
        margin-left:35px;
    }
    
}
@media screen and (max-width:600px) {
    #ServiceCardServicePage{
        margin:0px auto;
        display: grid;
        height: auto;
        width:90%;
        border-radius: 20px;
        grid-template-areas: 
        "LeftPart LeftPart"
        "RightPart RightPart"
        "MiddleLeft MiddleLeft"
        "MiddleRight MiddleRight"
        ;
    }
    .MiddlePartRight{
        margin-right: 0px;
        width: auto;
    }
    .MiddlePartLeft{
        margin-left:0px;
        width: auto;
    }
        
    .LeftPart, .RightPart{
        background-color: transparent;
        height: auto;
        width: 100%;
        border: 0px;
        text-align: start;
        margin: 0px 0px 10px 0px;

        h2{
            margin: 0px;
            text-align: start;
            width: 100%;
            font-size: 18px;
        }
    }
    .MiddlePartRight, .MiddlePartLeft{
        p{
            margin: 0px;
            text-align: start;
            font-size: 12px;
            line-height: 15px;
        }
    }
}