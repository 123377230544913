#CAP5Openings{
    margin-top: 10vh;
    height: auto;
    width:100%;
    background-color: var(--alt-bg-color);
    padding: 10vh 0px;

    h2{
        margin: auto;
        color: var(--heading-color);
        font-size: 3.5vh;
    }
}

#openingsList{
    height: auto;
    width: 70%;
    margin:7vh auto 0px auto;
    padding: 4vh 0px;
    gap: 2vh;
    flex-direction: column;
}
.openingCard{
    text-align: start;
    padding: 3vh 5vh;
    width: 90%;
    background-color: white;
    margin: auto;
    border-radius: 1vh;
    justify-content: space-between;
    cursor: pointer;

    button{
        font-size: 2.5vh;
        background-color: transparent;
        border: 0px;
        cursor: pointer;
        color: var(--heading-description-color);
        transition: all 0.3s;
        margin-right: 2vh;
    }
}
.openingCard:hover{
    button{
        color: var(--icon-bg-color);
        margin-right: 0vh;
    }
}
.openingCard>div{
    h4{
        margin: 0;
        font-size: 2.5vh;
        color: var(--heading-color);
    }
    p{
        margin: 1vh auto 0px auto;
        font-size: 1.6vh;
        color: var(--heading-description-color);
    }
}






@media screen and (max-width:980px) {
    #CAP5Openings{
        margin-top: 0px;
        padding: 65px 0px;
    
        h2{
            font-size: 20px;
        }
    }
    
    #openingsList{
        margin:40px auto 0px auto;
        padding: 25px 0px;
        gap: 12px;
    }
    .openingCard{
        padding: 20px 30px;
        width: 80%;
        border-radius: 7px;
    
        button{
            font-size: 14px;
            margin-right: 10px;
        }
    }
    .openingCard:hover{
        button{
            margin-right: 0px;
        }
    }
    .openingCard>div{
        h4{
            margin: 0;
            font-size: 13px;
            color: var(--heading-color);
        }
        p{
            margin: 7px auto 0px auto;
            font-size: 9px;
        }
    }
    
    
    
}

@media screen and (max-width:930px) {
    #openingsList{
        width: 80%;
    }
}